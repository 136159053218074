import { useTypedSelector } from "hooks";
import { selectIsPaymentMethodsLoading, selectPaymentMethodsData } from "features/paymentMethods/selectors";
import useActions from "features/paymentMethods/useActions";
import useSessionEffect from "hooks/useSessionEffect";
import usePaymentMethodFilter from "./usePaymentMethodFilter";

const paymentMethodsEffectSymbol = Symbol('paymentMethodsEffectSymbol');

export default function usePaymentMethods() {
    const { filter, ...rest } = usePaymentMethodFilter();

    const { getAllPaymentMethods } = useActions();
    const isPaymentMethodsLoading = useTypedSelector(selectIsPaymentMethodsLoading);
    const paymentMethods = useTypedSelector(selectPaymentMethodsData);

    useSessionEffect(() => {
        getAllPaymentMethods();
    }, paymentMethodsEffectSymbol);

    const getPaymentMethods = () => filter(paymentMethods);

    return {
        ...rest,
        getPaymentMethods,
        isPaymentMethodsLoading
    };
};
