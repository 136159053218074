import { createContext, type PropsWithChildren } from "react";
import type { MerchantsMethodsPivot } from "features/pivots/types";

export const MerchantPaymentMethodContext = createContext<MerchantsMethodsPivot>({} as MerchantsMethodsPivot);

type Props = {
    readonly merchantPaymentMethodPivot: MerchantsMethodsPivot;
};

const MerchantPaymentMethodProvider = ({
    children,
    merchantPaymentMethodPivot
}: PropsWithChildren<Props>) => (
    <MerchantPaymentMethodContext.Provider
        value={merchantPaymentMethodPivot}
    >
        {children}
    </MerchantPaymentMethodContext.Provider>
);

export default MerchantPaymentMethodProvider;
