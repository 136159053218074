import { memo } from "react";
import Box from "@mui/material/Box";
import type { SpecInParamsRowActionsProps } from "./types";
import DeleteSpecInParamsRow from "./DeleteSpecInParamsRow";

const SpecInParamsRowActions = (props: SpecInParamsRowActionsProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
        }}
    >
        <DeleteSpecInParamsRow
            {...props}
        />
    </Box>
);

export default memo(SpecInParamsRowActions);
