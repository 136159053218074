import { memo } from "react";
import Accordion from "ui/atoms/Accordion";
import PaymentMethodSummary from "../PaymentMethodSummary";
import PaymentMethodDetail from "../PaymentMethodDetail";
import type { PaymentMethodProviderProps } from "../PaymentMethodProvider";
import PaymentMethodAccordionAction from "./PaymentMethodAccordionAction";
import usePaymentMethodUnsavedChanges from "./usePaymentMethodUnsavedChanges";

const PaymentMethodAccordion = (props: PaymentMethodProviderProps) => (
    <Accordion
        {...props}
        {...usePaymentMethodUnsavedChanges(props)}
        AccordionSummaryProps={{
            children: (
                <PaymentMethodSummary />
            ),
            'aria-controls': `${props.paymentMethod!.name} content`,
            id: `${props.paymentMethod!.name} summary`
        }}
        AccordionActionsProps={{
            children: (
                <PaymentMethodAccordionAction />
            )
        }}
    >
        <PaymentMethodDetail />
    </Accordion>
);

export default memo(PaymentMethodAccordion);
