import { memo, type PropsWithChildren } from "react";
import { capitalize } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { CustomAvatar } from "lib/custom-avatar";
import { Collection } from "ui/molecules/Resource";
import MerchantPaymentMethodPSP, { PaymentMethodIsAssignable } from "./MerchantPaymentMethodPSP";

type Props = {
    readonly name: MerchantsMethodsPivot['paymentMethodTag'];
    readonly merchantsMethods: Array<MerchantsMethodsPivot>;
};

const MerchantPaymentMethod = ({
    name,
    merchantsMethods,
    children
}: PropsWithChildren<Props>) => {
    const renderChildren = () => {
        const merchantsMethodsPivot = merchantsMethods.at(0);

        if (!merchantsMethodsPivot) {
            return null;
        }

        return (
            <>
                <PaymentMethodIsAssignable
                    {...merchantsMethodsPivot}
                />
                {merchantsMethodsPivot.paymentMethodIsAssignable && children}
            </>
        );
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, min-content)',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                <CustomAvatar
                    name={name}
                    alt={name}
                />
                <Typography
                    variant='h5'
                >
                    {capitalize(name)}
                </Typography>
                {renderChildren()}
            </Box>
            <Collection
                collection={merchantsMethods}
                renderResource={(merchantMethod) => (
                    <MerchantPaymentMethodPSP
                        {...merchantMethod}
                        key={merchantMethod.coreId}
                    />
                )}
            />
        </Box>
    );
};

export default memo(MerchantPaymentMethod);
