import useForm from "hooks/useForm";
import {
    FormField,
    type EditMerchantDomainEntityForm,
    type CreateMerchantBusinessForm,
    type CreateMerchantDomainForm
} from "./types";
import type {
    MerchantBusiness,
    MerchantDomain
} from "features/merchants/types";
import {
    useMerchantBusinessValidationSchema,
    useMerchantDomainValidationSchema
} from './useValidationSchema';

const getMerchantBusinessInitialValues = <T = MerchantBusiness>(merchantBusiness: Partial<T> = {}) => ({
    [FormField.BusinessName]: '',
    ...merchantBusiness
});

const getMerchantDomainInitialValues = <T = MerchantDomain>(merchantDomain: Partial<T> = {}) => ({
    [FormField.SourceUrl]: '',
    ...merchantDomain
});

const formSettings = {
    validateOnChange: true
};

export const useCreateMerchantBusinessForm = (merchantBusinessFormProps: CreateMerchantBusinessForm) =>
    useForm<Pick<MerchantBusiness, 'businessName'>>({
        ...formSettings,
        ...merchantBusinessFormProps,
        validationSchema: useMerchantBusinessValidationSchema(),
        initialValues: getMerchantBusinessInitialValues<
            Pick<MerchantBusiness, 'businessName'>
        >()
    });

export const useEditMerchantBusinessForm = (merchantBusinessFormProps: EditMerchantDomainEntityForm<MerchantBusiness>) =>
    useForm<Partial<MerchantBusiness>>({
        ...formSettings,
        ...merchantBusinessFormProps,
        validationSchema: useMerchantBusinessValidationSchema(),
        initialValues: getMerchantBusinessInitialValues<
            Partial<MerchantBusiness>
        >(merchantBusinessFormProps.initialValues)
    });



export const useCreateMerchantDomainForm = (merchantDomainFormProps: CreateMerchantDomainForm) =>
    useForm<Pick<MerchantDomain, 'sourceUrl'>>({
        ...formSettings,
        ...merchantDomainFormProps,
        validationSchema: useMerchantDomainValidationSchema(),
        initialValues: getMerchantDomainInitialValues<
            Pick<MerchantDomain, 'sourceUrl'>
        >()
    });

export const useEditMerchantDomainForm = (merchantDomainFormProps: EditMerchantDomainEntityForm<MerchantDomain>) =>
    useForm<Partial<MerchantDomain>>({
        ...formSettings,
        ...merchantDomainFormProps,
        validationSchema: useMerchantDomainValidationSchema(),
        initialValues: getMerchantDomainInitialValues<
            Partial<MerchantDomain>
        >(merchantDomainFormProps.initialValues)
    });
