import { memo } from "react";
import type { PaymentMethod } from "features/paymentMethods/types";
import Label from "./Label";

const PaymentMethodTag = ({ tag }: PaymentMethod) => (
    <Label
        title='Tag'
    >
        {tag}
    </Label>
);

export default memo(PaymentMethodTag);
