import { memo } from "react";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import Accordion from "ui/atoms/Accordion";
import { useAccordionContext } from "ui/widgets/Accordion";
import MerchantPaymentMethodPSPSummary from "./MerchantPaymentMethodPSPSummary";
import MerchantPaymentMethodPSPDetail from "./MerchantPaymentMethodPSPDetail";
import { Card } from "@mui/material";

const MerchantPaymentMethodPSP = (merchantMethod: MerchantsMethodsPivot) => {
    const { expanded, handleChange } = useAccordionContext();

    return (
        <Card
        sx={{
            padding: '10px',
            marginTop: '15px',
            marginBottom: '15px',
        }}>
        <Accordion
            expanded={Object.is(expanded, merchantMethod.coreId)}
            onChange={handleChange(merchantMethod.coreId)}
            AccordionSummaryProps={{
                children: (
                    <MerchantPaymentMethodPSPSummary
                        {...merchantMethod}
                    />
                ),
                'aria-controls': `${merchantMethod.paymentMethodPSP} content`,
                id: `${merchantMethod.paymentMethodPSP} summary`
            }}
        >
            <MerchantPaymentMethodPSPDetail
                {...merchantMethod}
            />
        </Accordion>
        </Card>
    );
};

export default memo(MerchantPaymentMethodPSP);
