import type { DomainAction, PaginateResourceResponse, ResourceResponse, SimplePaginator } from "types";
import type { MerchantsMethodsPivot, MerchantsMethodsPivotHistory } from "features/pivots/types";
import { ApiState } from "infrastructure/api";
import { IDomainRepository } from "hooks";

export const enum MerchantPaymentMethodsActionType {
    FetchMerchantsMethods = 'MerchantPaymentMethodsActionType:FetchMerchantsMethods',
    CreateMerchantMethod = 'MerchantPaymentMethodsActionType:CreateMerchantMethod',
    UpdateMerchantsMethod = 'MerchantPaymentMethodsActionType:UpdateMerchantsMethod',
    FetchMerchantsMethodsHistory = 'MerchantPaymentMethodsActionType:FetchMerchantsMethodsHistory',
    Reset = 'MerchantPaymentMethodsActionType:Reset'
};

export type MerchantPaymentMethodsPivot = {
    readonly apiState: ApiState;
    data: PaginateResourceResponse<MerchantsMethodsPivot>['data'] | null;
};

export type MerchantPaymentMethodsPivotHistory = {
    readonly apiState: ApiState;
    readonly data: ResourceResponse<SimplePaginator<MerchantsMethodsPivotHistory>>['data'] | null;
};

export type MerchantPaymentMethodsRepositoryState = {
    readonly merchantsMethods: MerchantPaymentMethodsPivot;
    readonly merchantsMethodsHistory: MerchantPaymentMethodsPivotHistory;
};

export type CreateMerchantMethodPivot = Pick<
    MerchantsMethodsPivot,
    | 'merchantId'
    | 'methodId'
    | 'MOR'
    | 'enabled'
>;

export type UpdateMerchantsMethodsPivot = Pick<
    MerchantsMethodsPivot,
    | 'coreId'
> & Partial<Omit<MerchantsMethodsPivot, 'coreId'>>;

export type UpdateMethodLimits = Pick<
    MerchantsMethodsPivot,
    | 'coreId'
    | 'minTicketSize'
    | 'maxTicketSize'
    | 'maxNumberOfTransaction24'
    | 'maxNumberOfTransactionWeek'
    | 'maxNumberOfTransactionMonth'
    | 'maxTotalAmountWeek'
    | 'maxNumberOfTransactionProfile24'
    | 'adjustMaxNumberOfTransactionProfile24ByConsumerRisk'
    | 'maxTotalAmountProfile24'
    | 'adjustMaxTotalAmountProfile24ByConsumerRisk'
    | 'maxTotalAmountMonth'
    | 'adjustMaxTotalAmountMonthByConsumerRisk'
    | 'velocity'
    | 'adjustVelocityByConsumerRisk'
    | 'maxRiskLevel'
    | 'requireConsumerData'
    | 'limitsEnabled'
>;

export type ToggleEnableMerchantsMethodsPivot = Pick<
    MerchantsMethodsPivot,
    | 'coreId'
    | 'enabled'
    | 'MOR'
>;

export type FetchMerchantsMethodsHistory = Pick<MerchantsMethodsPivot, 'methodId'> & {
    readonly page?: number;
};

export type FetchMerchantsMethodsActionPayload = {
    readonly apiState: MerchantPaymentMethodsPivot['apiState'];
    readonly data?: MerchantPaymentMethodsPivot['data'];
};

export type CreateMerchantMethodActionPayload = MerchantsMethodsPivot;

export type UpdateMerchantsMethodActionPayload = MerchantsMethodsPivot;

export type FetchMerchantsMethodsHistoryActionPayload = {
    readonly apiState: MerchantPaymentMethodsPivotHistory['apiState'];
    readonly data?: MerchantPaymentMethodsPivotHistory['data'];
};

export type MerchantPaymentMethodsRepositoryAction =
    | DomainAction<
        MerchantPaymentMethodsActionType.FetchMerchantsMethods,
        FetchMerchantsMethodsActionPayload
    >
    | DomainAction<
        MerchantPaymentMethodsActionType.CreateMerchantMethod,
        CreateMerchantMethodActionPayload
    >
    | DomainAction<
        MerchantPaymentMethodsActionType.UpdateMerchantsMethod,
        UpdateMerchantsMethodActionPayload
    >
    | DomainAction<
        MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
        FetchMerchantsMethodsHistoryActionPayload
    >
    | DomainAction<
        MerchantPaymentMethodsActionType.Reset,
        undefined
    >;

export interface MerchantPaymentMethodsRepository extends IDomainRepository {
    readonly state: MerchantPaymentMethodsRepositoryState;

    readonly isMerchantMethodsLoading: boolean;

    readonly isMerchantMethodsHistoryLoading: boolean;

    readonly isMerchantMethodsError: boolean;

    readonly isMerchantMethodsHistoryError: boolean;

    readonly isMerchantMethodsEmpty: boolean;

    readonly isMerchantMethodsHistoryEmpty: boolean;

    readonly merchantsMethods: Array<MerchantsMethodsPivot>;

    readonly merchantsMethodsHistory: Array<MerchantsMethodsPivotHistory>;

    readonly merchantsMethodsHistoryPaginator: SimplePaginator<MerchantsMethodsPivotHistory> | null;

    fetchMerchantsMethods(): Promise<void>;

    toggleEnableMerchantsMethodsPivot(merchantMethod: ToggleEnableMerchantsMethodsPivot): Promise<ResourceResponse<MerchantsMethodsPivot>>;

    updateMethodLimits(methodLimits: Partial<UpdateMethodLimits>): Promise<ResourceResponse<MerchantsMethodsPivot>>;

    fetchMerchantsMethodsHistory({ methodId }: FetchMerchantsMethodsHistory): Promise<void>;

    createMerchantMethodPivot(merchantMethod: CreateMerchantMethodPivot): Promise<ResourceResponse<MerchantsMethodsPivot>>;
};
