import type {
    DeepWriteable,
    PaginateResourceResponse,
    ResourceResponse,
    SimplePaginator
} from "types";
import type {
    MerchantsMethodsPivot,
    MerchantsMethodsPivotHistory
} from "features/pivots/types";
import { DomainRepository, type Dispatcher } from "hooks/useDomainState";
import {
    MerchantPaymentMethodsActionType,
    type MerchantPaymentMethodsRepositoryState,
    type MerchantPaymentMethodsRepository,
    type CreateMerchantMethodPivot,
    type UpdateMerchantsMethodsPivot,
    type UpdateMethodLimits,
    type FetchMerchantsMethodsHistory,
    type ToggleEnableMerchantsMethodsPivot
} from "./types";
import { ApiState } from "infrastructure/api";
import { convertToBooleanSwitch } from "util/transformers";

export class Repository extends DomainRepository<
    MerchantPaymentMethodsRepositoryState,
    MerchantPaymentMethodsActionType
> implements MerchantPaymentMethodsRepository {
    public constructor(
        public readonly state: MerchantPaymentMethodsRepositoryState,
        protected readonly dispatch: Dispatcher<MerchantPaymentMethodsActionType>,
        private readonly getMerchantsMethodsPivotRequest: () => Promise<PaginateResourceResponse<MerchantsMethodsPivot>>,
        private readonly getMerchantsMethodsPivotHistoryRequest: ({ methodId, page }: FetchMerchantsMethodsHistory) =>
            Promise<ResourceResponse<SimplePaginator<MerchantsMethodsPivotHistory>>>,
        private readonly createMerchantMethodPivotRequest: (
            merchantsMethodsPivot: CreateMerchantMethodPivot
        ) => Promise<ResourceResponse<MerchantsMethodsPivot>>,
        private readonly updateMerchantsMethodsPivotRequest: (
            merchantsMethodsPivot: UpdateMerchantsMethodsPivot
        ) => Promise<ResourceResponse<MerchantsMethodsPivot>>
    ) {
        super(state, dispatch);
    }

    public get isMerchantMethodsLoading() {
        return [
            ApiState.Idle,
            ApiState.Pending
        ].includes(this.state.merchantsMethods.apiState);
    }

    public get isMerchantMethodsHistoryLoading() {
        return [
            ApiState.Idle,
            ApiState.Pending
        ].includes(this.state.merchantsMethodsHistory.apiState);
    }

    public get isMerchantMethodsError() {
        return Object.is(
            this.state.merchantsMethods.apiState,
            ApiState.Failed
        );
    }

    public get isMerchantMethodsHistoryError() {
        return Object.is(
            this.state.merchantsMethodsHistory.apiState,
            ApiState.Failed
        );
    }

    public get isMerchantMethodsEmpty() {
        return Object.is(
            this.state.merchantsMethods.apiState,
            ApiState.Succeeded
        ) && !this.state.merchantsMethods.data?.total;
    }

    public get isMerchantMethodsHistoryEmpty() {
        return Object.is(
            this.state.merchantsMethodsHistory.apiState,
            ApiState.Succeeded
        ) && !this.state.merchantsMethodsHistory.data?.data.length;
    }

    public get merchantsMethods() {
        return this.state.merchantsMethods.data?.data ?? [];
    }

    public get merchantsMethodsHistoryPaginator() {
        return this.state.merchantsMethodsHistory.data
    }

    public get merchantsMethodsHistory() {
        return this.state.merchantsMethodsHistory.data?.data ?? [];
    }

    public async fetchMerchantsMethods() {
        if (this.shouldSendRequest(this.state.merchantsMethods.apiState)) {
            return;
        }

        this.dispatch({
            type: MerchantPaymentMethodsActionType.FetchMerchantsMethods,
            payload: {
                apiState: ApiState.Pending
            }
        });

        try {
            const response = await this.getMerchantsMethodsPivotRequest();

            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethods,
                payload: {
                    apiState: ApiState.Succeeded,
                    data: this.throwOnFailedResponse(response).data
                }
            });
        } catch {
            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethods,
                payload: {
                    apiState: ApiState.Failed
                }
            });
        }
    }

    public toggleEnableMerchantsMethodsPivot({
        coreId,
        enabled,
        MOR
    }: ToggleEnableMerchantsMethodsPivot): Promise<ResourceResponse<MerchantsMethodsPivot>> {
        const merchantMethod: DeepWriteable<
            & Pick<ToggleEnableMerchantsMethodsPivot, 'coreId'>
            & Omit<Partial<ToggleEnableMerchantsMethodsPivot>, 'coreId'>
        > = {
            coreId,
            enabled: convertToBooleanSwitch(enabled)
        };

        if (enabled) {
            merchantMethod.MOR = (
                Object.is(MOR, String(null))
                    ? null
                    : MOR
            );
        }

        return this.updateMerchantsMethodsPivot(merchantMethod);
    }

    public updateMethodLimits({
        adjustMaxNumberOfTransactionProfile24ByConsumerRisk,
        adjustMaxTotalAmountProfile24ByConsumerRisk,
        adjustMaxTotalAmountMonthByConsumerRisk,
        adjustVelocityByConsumerRisk,
        requireConsumerData,
        limitsEnabled,
        ...merchantMethod
    }: UpdateMethodLimits): Promise<ResourceResponse<MerchantsMethodsPivot>> {
        return this.updateMerchantsMethodsPivot({
            ...merchantMethod,
            adjustMaxNumberOfTransactionProfile24ByConsumerRisk: convertToBooleanSwitch(
                adjustMaxNumberOfTransactionProfile24ByConsumerRisk
            ),
            adjustMaxTotalAmountProfile24ByConsumerRisk: convertToBooleanSwitch(
                adjustMaxTotalAmountProfile24ByConsumerRisk
            ),
            adjustMaxTotalAmountMonthByConsumerRisk: convertToBooleanSwitch(
                adjustMaxTotalAmountMonthByConsumerRisk
            ),
            adjustVelocityByConsumerRisk: convertToBooleanSwitch(
                adjustVelocityByConsumerRisk
            ),
            requireConsumerData: convertToBooleanSwitch(requireConsumerData),
            limitsEnabled: convertToBooleanSwitch(limitsEnabled)
        });
    }

    public async fetchMerchantsMethodsHistory({ methodId, page = 1 }: FetchMerchantsMethodsHistory) {
        this.dispatch({
            type: MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
            payload: {
                apiState: ApiState.Pending
            }
        });

        try {
            const response = await this.getMerchantsMethodsPivotHistoryRequest({ methodId, page });

            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
                payload: {
                    apiState: ApiState.Succeeded,
                    data: this.throwOnFailedResponse(response).data
                }
            });
        } catch {
            this.dispatch({
                type: MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory,
                payload: {
                    apiState: ApiState.Failed
                }
            });
        }
    }

    public async createMerchantMethodPivot(merchantMethod: CreateMerchantMethodPivot): Promise<ResourceResponse<MerchantsMethodsPivot>> {
        const response = await this.createMerchantMethodPivotRequest(merchantMethod);

        this.dispatch({
            type: MerchantPaymentMethodsActionType.CreateMerchantMethod,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }

    public reset(): void {
        this.dispatch({
            type: MerchantPaymentMethodsActionType.Reset,
            payload: undefined
        });
    }

    private async updateMerchantsMethodsPivot(merchantMethod: UpdateMerchantsMethodsPivot) {
        const response = await this.updateMerchantsMethodsPivotRequest(merchantMethod);

        this.dispatch({
            type: MerchantPaymentMethodsActionType.UpdateMerchantsMethod,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }
}
