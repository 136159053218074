import Box from "@mui/material/Box";
import { withGridCellParams } from "ui/widgets/Table/renderers";

const Boldable = withGridCellParams<string>(({ value }) => (
    <Box
        sx={{
            fontWeight: 'bold'
        }}
    >
        {value || '-'}
    </Box>
));

export default Boldable;
