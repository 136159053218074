import type { FunctionComponent } from 'react';
import type { StatusProps } from 'ui/molecules/Status';

const withDetailsValue = (Component?: FunctionComponent<StatusProps<string>>) =>
    (fieldValue: unknown) => {
        const fieldValueValidator = () => fieldValue != null;

        if (!fieldValueValidator()) {
            return null;
        }

        if (Component) {
            return (
                <Component
                    label={String(fieldValue)}
                />
            );
        }

        return String(fieldValue) || '-'.repeat(17);
    };

export default withDetailsValue;
