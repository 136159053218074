import { memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { SortOrder } from "consts/request-query";
import { Filters } from "consts/paymentMethods";
import { FilterVariant } from "consts/filters";
import { compare } from "util/support";
import { Option } from "util/option";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { Collection } from "ui/molecules/Resource";
import { NoDataOverlay } from "ui/molecules/TableGrids";
import { FilterRenderTypes } from "ui/widgets/Filters";
import { AccordionProvider } from "ui/widgets/Accordion";
import MerchantPaymentMethod from "./MerchantPaymentMethod";
import useMerchantPaymentMethods from "./useMerchantPaymentMethods";
import AddMerchantPaymentMethod from "./AddMerchantPaymentMethod";

const MerchantPaymentMethodsWithFilters = ({ children }: PropsWithChildren) => {
    const { getMerchantPaymentMethodsEntries } = useMerchantPaymentMethods();

    const getMerchantPaymentMethods = ({
        useTags = [],
        useMethods = []
    }: {
        readonly useTags?: Array<
            (merchantPaymentMethods: Array<[string, MerchantsMethodsPivot[]]>) => Array<[string, MerchantsMethodsPivot[]]>
        >;
        readonly useMethods?: Array<
            (merchantPaymentMethods: Array<MerchantsMethodsPivot>) => Array<MerchantsMethodsPivot>
        >;
    } = {}) => useTags.reduce(
        (merchantPaymentMethodsEntries, middleware) =>
            middleware(merchantPaymentMethodsEntries)
                .map(([key, methods]) => [
                    key,
                    useMethods.reduce((methods, middleware) => middleware(methods), methods)
                ] as [string, Array<MerchantsMethodsPivot>]),
        getMerchantPaymentMethodsEntries()
    );

    const sortTags = (entries: Array<[string, MerchantsMethodsPivot[]]>) =>
        entries.sort(([a], [b]) => compare(a, b, SortOrder.Asc));

    const sortMethods = (methods: Array<MerchantsMethodsPivot>) =>
        methods.sort((methodA, methodB) => {
            const compareByEnabled = compare(methodA.enabled, methodB.enabled, SortOrder.Desc);
            const compareByRiskLevel = compare(methodA.maxRiskLevel, methodB.maxRiskLevel, SortOrder.Desc);

            if (compareByEnabled) {
                return compareByEnabled;
            }

            if (compareByRiskLevel) {
                return compareByRiskLevel;
            }

            return compare(methodA.createdAt, methodB.createdAt, SortOrder.Asc);
        });

    const merchantPaymentMethods = getMerchantPaymentMethods({
        useTags: [sortTags],
        useMethods: [sortMethods]
    });

    return (
        <Box
            sx={{
                display: 'grid',
                gap: 2,
            }}
        >
            {children}
            <AccordionProvider>
                <Box
                    sx={{
                        height: 'calc(100vh - 310px)',
                        overflow: 'auto'
                    }}
                >
                <Collection
                    isEmpty={!merchantPaymentMethods.length}
                    emptySlot={<NoDataOverlay />}
                    collection={merchantPaymentMethods}
                    renderResource={([key, merchantsMethods]) => (
                        <MerchantPaymentMethod
                            key={key}
                            name={key}
                            merchantsMethods={merchantsMethods}
                        >
                            <AddMerchantPaymentMethod
                                sx={{
                                    whiteSpace: 'nowrap'
                                }}
                                variant='text'
                                startIcon={<AddIcon />}
                                filtersConfig={[
                                    {
                                        filterId: Filters.tag,
                                        filterVariant: FilterVariant.SingleOption,
                                        renderAs: FilterRenderTypes.Dropdown,
                                        inputs: [
                                            {
                                                label: 'Payment Method Tag',
                                                multiple: false,
                                                value: Option.make(key),
                                                disabled: true,
                                                InputProps: {
                                                    placeholder: 'Select tag'
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        filterId: Filters.PSP,
                                        filterVariant: FilterVariant.SingleOption,
                                        renderAs: FilterRenderTypes.Dropdown,
                                        inputs: [
                                            {
                                                label: 'Payment Method PSP',
                                                multiple: false,
                                                InputProps: {
                                                    placeholder: 'Select PSP'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </MerchantPaymentMethod>
                    )}
                />
                </Box>
                
            </AccordionProvider>
        </Box>
    );
};

export default memo(MerchantPaymentMethodsWithFilters);
