import { memo } from "react";
import Box from "@mui/material/Box";
import AddPaymentMethod from "./AddPaymentMethod";
import SearchPaymentMethod from "./SearchPaymentMethod";

const PaymentMethodsToolbar = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 2
        }}
    >
        <SearchPaymentMethod />
        <AddPaymentMethod />
    </Box>
);

export default memo(PaymentMethodsToolbar);
