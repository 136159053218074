import { FilterValueObject } from "../types";

export default class TextFieldValue extends FilterValueObject<string> {
    // public toSerialized(): string[] {
    //     return [
    //         [this.value.trim(), this.meta?.modifier]
    //             .filter(Boolean)
    //             .join(':')
    //     ];
    // }
    public toArray(): string[] {
        return [this.value];
    }

    public serialize(value: string): string {
        return [value.trim(), this.meta?.modifier]
            .filter(Boolean)
            .join(':');
    }

    public isEmpty(value: string): boolean {
        return !value.trim().length;
    }

    public isValueEmpty(): boolean {
        return !this.value.trim().length;
    }
}
