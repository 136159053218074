import { memo, type FunctionComponent } from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import WidgetsIcon from "@mui/icons-material/Widgets";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GroupIcon from "@mui/icons-material/Group";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import LogoutIcon from "@mui/icons-material/Logout";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import OutboxIcon from '@mui/icons-material/Outbox';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PaymentsIcon from '@mui/icons-material/Payments';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import BackspaceIcon from '@mui/icons-material/Backspace';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import SaveIcon from '@mui/icons-material/Save';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import AutoModeOutlinedIcon from '@mui/icons-material/AutoModeOutlined';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import ForwardIcon from '@mui/icons-material/Forward';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import SyncIcon from '@mui/icons-material/Sync';

import type { IconProps } from '@mui/material';

const IconRepository = new Map<string, FunctionComponent>()
    .set('CalendarTodayOutlined', CalendarTodayOutlinedIcon)
    .set('Widgets', WidgetsIcon)
    .set('AccountBalance', AccountBalanceIcon)
    .set('Group', GroupIcon)
    .set('CompareArrows', CompareArrowsIcon)
    .set('Logout', LogoutIcon)
    .set('NotInterested', NotInterestedIcon)
    .set('HourglassEmpty', HourglassEmptyIcon)
    .set('KeyboardDoubleArrowDown', KeyboardDoubleArrowDownIcon)
    .set('Outbox', OutboxIcon)
    .set('CallMissed', CallMissedIcon)
    .set('LocalAtm', LocalAtmIcon)
    .set('SmsFailed', SmsFailedIcon)
    .set('ThumbDownOffAlt', ThumbDownOffAltIcon)
    .set('CheckCircleOutline', CheckCircleOutlineIcon)
    .set('CurrencyExchange', CurrencyExchangeIcon)
    .set('Visibility', VisibilityIcon)
    .set('Storefront', StorefrontIcon)
    .set('AdminPanelSettings', AdminPanelSettingsIcon)
    .set('Check', CheckIcon)
    .set('Close', CloseIcon)
    .set('PaymentsIcon',PaymentsIcon)
    .set('CloseFullscreenIcon', CloseFullscreenIcon)
    .set('ThumbUp', ThumbUpIcon)
    .set('Backspace', BackspaceIcon)
    .set('Info', InfoIcon)
    .set('MoreVert', MoreVertIcon)
    .set('Download', DownloadIcon)
    .set('DeleteForever', DeleteForeverIcon)
    .set('AddToPhotos', AddToPhotosIcon)
    .set('Save', SaveIcon)
    .set('PendingActions', PendingActionsIcon)
    .set('FolderOffIcon', FolderOffIcon )
    .set('ForwardOutlined', ForwardOutlinedIcon)
    .set('AirlineStopsOutlined', AirlineStopsOutlinedIcon)
    .set('AutoModeOutlined', AutoModeOutlinedIcon)
    .set('AddTaskOutlined', AddTaskOutlinedIcon)
    .set('Forward', ForwardIcon)
    .set('AssuredWorkloadOutlined', AssuredWorkloadOutlinedIcon)
    .set('BeenhereOutlined', BeenhereOutlinedIcon)
    .set('BookmarkAddedOutlined', BookmarkAddedOutlinedIcon)
    .set('AccountBalanceWallet', AccountBalanceWalletIcon)
    .set('ArrowForwardIos', ArrowForwardIosIcon)
    .set('SwapVerticalCircle', SwapVerticalCircleIcon)
    .set('SwapHorizontalCircle', SwapHorizontalCircleIcon)
    .set('Sync', SyncIcon);

export type Props = {
    readonly name: string;
} & IconProps;

const Icon = ({ name, ...restIconProps }: Props) => {
    if (IconRepository.has(name)) {
        const IconComponent = IconRepository.get(name)!;
        return <IconComponent {...restIconProps} />;
    }
    return null;
};

export default memo(Icon);
