import { memo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import type { MerchantsMethodsPivot } from "features/pivots/types";

type Props = Pick<MerchantsMethodsPivot, 'paymentMethodIsAssignable'>;

const PaymentMethodIsAssignable = ({ paymentMethodIsAssignable }: Props) => {
    const [icon, title] = paymentMethodIsAssignable
        ? [
            <CheckCircleIcon color='success' />,
            'Method is available for merchant - processing is possible.'
        ]
        : [
            <CancelIcon color='error' />,
            `This payment method is marked as not available for
            merchants at the moment. In order for a merchant to be able to
            process via it, please make sure the “Method can be available for merchant”
            checkbox from Payment Methods Setup is ticked.”`
        ];

    return (
        <Tooltip
            arrow
            title={title}
        >
            {icon}
        </Tooltip>
    );
};

export default memo(PaymentMethodIsAssignable);
