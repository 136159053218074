import { memo, type FC } from "react";
import Box from "@mui/material/Box";
import { NoDataOverlay } from "ui/molecules/TableGrids";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { FeatureFiltersProvider, FilterRenderTypes, type FeatureFiltersProviderProps } from "ui/widgets/Filters";
import { Filters } from "consts/merchants";
import { FilterVariant } from "consts/filters";
import MerchantPaymentMethodsWithFilters from "./MerchantPaymentMethodsWithFilters";
import useMerchantPaymentMethods from "./useMerchantPaymentMethods";
import AddMerchantPaymentMethod from "./AddMerchantPaymentMethod";
import MerchantPaymentMethodsFiltersPanel from "./MerchantPaymentMethodsFiltersPanel";

const MerchantPaymentMethods: FC<Partial<FeatureFiltersProviderProps>> = ({ filtersConfig }) => {
    const { repository } = useMerchantPaymentMethods();

    return (
        <TabLayout
            isLoading={repository.isMerchantMethodsLoading}
            isEmpty={repository.isMerchantMethodsEmpty}
            isError={repository.isMerchantMethodsError}
            emptySlot={(
                <NoDataOverlay
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 300px)'
                    }}
                >
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2
                        }}
                    >
                        Looks like there are no payment methods for this merchant.
                        <AddMerchantPaymentMethod />
                    </Box>
                </NoDataOverlay>
            )}
        >
            <FeatureFiltersProvider
                filtersConfig={filtersConfig!}
            >
                <MerchantPaymentMethodsWithFilters>
                    <MerchantPaymentMethodsFiltersPanel>
                        <AddMerchantPaymentMethod />
                    </MerchantPaymentMethodsFiltersPanel>
                </MerchantPaymentMethodsWithFilters>
            </FeatureFiltersProvider>
        </TabLayout>
    );
};

MerchantPaymentMethods.defaultProps = {
    filtersConfig: [
        {
            filterId: Filters.paymentMethodTag,
            filterVariant: FilterVariant.SingleOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Payment Method Tag',
                    multiple: false,
                    InputProps: {
                        placeholder: 'Select tag'
                    }
                }
            ]
        },
        {
            filterId: Filters.paymentMethodPSP,
            filterVariant: FilterVariant.SingleOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Payment Method PSP',
                    multiple: false,
                    InputProps: {
                        placeholder: 'Select PSP'
                    }
                }
            ]
        },
        {
            filterId: Filters.MOR,
            filterVariant: FilterVariant.SingleOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Merchant Of Records',
                    multiple: false,
                    InputProps: {
                        placeholder: 'Select MORs'
                    }
                }
            ]
        }
    ]
};

export default memo(MerchantPaymentMethods);
