import { useMemo } from 'react';
import { number, object, string } from 'yup';
import { Filters } from "consts/merchantPayouts";

export const useValidationSchema = () =>
    useMemo(() =>
        object().shape({
            [Filters.merchantId]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .required()
                .label('Merchant'),
            [Filters.taxName]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .required()
                .label('Tax Name'),
            [Filters.tag]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .notRequired()
                .label('Tag'),
            [Filters.refColFromMinSum]: number()
                .nullable()
                .required()
                .min(0)
                .max(Number.MAX_SAFE_INTEGER)
                .label('Ref Column From Min Sum'),
            [Filters.currentIncMultiplierForEachRefColumn]: number()
                .nullable()
                .required()
                .min(0)
                .max(Number.MAX_SAFE_INTEGER)
                .label('Multiplier'),
            [Filters.refColFrom]: object({
                id: string(),
                name: string()
            })
                .nullable()
                .required()
                .label('Ref Column From')
        })
        , []);
