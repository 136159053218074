import type { ComponentType, ForwardedRef, ReactNode } from "react";
import type { useFormik } from "formik";
import type { TextFieldProps } from "@mui/material/TextField";
import type { LoadingButtonProps } from "@mui/lab/LoadingButton";
import type { BootstrapableForm, Form, ID, Option } from "types";
import type { User } from "features/users/types";
import type { WithTwoFactor } from "features/auth/types";
import type { UseFormArg } from "hooks";
import type { AutocompleteProps } from "ui/widgets/Filters";

export const FormField = {
    CoreId: 'coreId',
    FirstName: 'firstName',
    LastName: 'lastName',
    Email: 'email',
    Password: 'passwd',
    PasswordConfirmation: 'passwordConfirmation',
    RoleId: 'roleId',
    Role: 'role',
    Merchants: 'merchants',
    Domains: 'domains',
    TwoFactorEnabled: 'twoFactorEnabled'
} as const;

export type UserFormWithRelations = WithTwoFactor<
    Pick<
        User,
        | 'firstName'
        | 'lastName'
        | 'email'
        | 'passwd'
        | 'roleId'
    >
> & {
    readonly role: Option | null;
    readonly merchants: Option[];
    readonly domains: Option[];
    readonly coreId?: ID;
    readonly passwordConfirmation?: string;
};

export type UserFormBootstrapData = {
    merchants: Option[];
    domains: Option[];
    roles: Option[];
};

export type ActionComponentProps = {
    readonly userFormikForm: UserFormikForm;
    readonly userForm: UserFormProps;
};

export type UserFormSlots = {
    readonly AutocompleteProps?: AutocompleteProps;
    readonly TextFieldProps?: TextFieldProps;
    readonly rolesSlot?: ReactNode;
    readonly merchantsSlot?: ReactNode;
    readonly domainsSlot?: ReactNode;
    readonly actionSlot?: ReactNode;
    readonly ActionComponent?: ComponentType<ActionComponentProps>;
    readonly getActionButtonProps?: (formData: UserFormikForm) => LoadingButtonProps;
};

export type UserFormikForm = ReturnType<typeof useFormik<Partial<UserFormWithRelations>>>;

export type UserFormResolvers = Pick<
    UseFormArg<Form<UserFormWithRelations>['initialValues']>,
    | 'onResolve'
    | 'onReject'
>;

export type UserFormRefs = {
    readonly formRef?: ForwardedRef<UserFormikForm>;
};

export type UserFormProps =
    & Form<UserFormWithRelations>
    & {
        readonly isLoading: boolean;
        readonly actionLabel: string;
        readonly bootstrapData: UserFormBootstrapData;
        readonly isBootstrapDataLoading: boolean;
        readonly user: User;
    }
    & UserFormSlots
    & UserFormResolvers
    & UserFormRefs;

export type UserFormType =
    & UseFormArg<UserFormProps['initialValues']>
    & BootstrapableForm<
        UserFormProps['initialValues'],
        UserFormBootstrapData
    >;

export type ValidatorParams =
    & ReturnType<typeof useFormik>
    & Pick<
        UserFormProps,
        | 'bootstrapData'
        | 'isBootstrapDataLoading'
    >;
