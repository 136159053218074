import type { SettlementStatus as SettlementStatusType } from "types";
import { SettlementStatus as Status } from "ui/molecules/Status";
import withGridCellParams from "./withGridCellParams";

const SettlementStatus = withGridCellParams<SettlementStatusType>(({ value }) => (
    <Status
        label={value}
    />
));

export default SettlementStatus;
