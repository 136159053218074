import type { Status as StatusType } from "types";
import { TransactionStatus as Status } from "ui/molecules/Status";
import withGridCellParams from "./withGridCellParams";

const TransactionStatus = withGridCellParams<StatusType>(({ value }) => (
    <Status
        label={value}
    />
));

export default TransactionStatus;
