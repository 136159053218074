import type { FormikConfig, FormikState, FormikValues } from "formik";
import type useForm from "hooks/useForm";
import type { ReactNode } from "react";
import type { Scalar } from "types";
import type { WithSubscription } from "providers/ContextPublisher";
import { isEqual, trimObjectValues } from "./support";

export const getFormInputProps = (
  fieldName = "",
  { errors, touched }: Pick<FormikState<FormikValues>, 'errors' | 'touched'>
) => {
  return {
    error: Boolean(touched[fieldName] && errors[fieldName]),
    helperText:
      (touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined) as ReactNode,
  };
};

export const getDirtyFields = <T extends object>({
  values,
  touched,
  initialValues
}: Pick<FormikState<FormikValues>, 'values'>
  & Pick<FormikConfig<T>, 'initialValues'>
  & {
    readonly touched?: FormikState<FormikValues>['touched'];
  }) =>
  Object.entries(values).reduce((acc: T, [key, value]) => {
    const isFieldTouched = touched
      ? touched[key]
      : true;

    if (isFieldTouched && !isEqual(initialValues[key as keyof T], value)) {
      return {
        ...acc,
        [key]: value
      };
    }

    return acc;
  }, {} as T);

export const fromFormableRecord = (record: FormableRecord, tab: Scalar<string>) => record[
  tab
];

export const aggregateFormableRecord = (
  record: FormableRecord,
  aggregateFn: ([key, value]: [Scalar<string>, UnsavedChangesFormState]) => void
) => Object.entries(record)
  .forEach(entries =>
    aggregateFn(entries)
  );

export const isFormChanged = ({ touched, dirty }: PickFormState<'touched' | 'dirty'>) =>
  Object.values(touched).includes(true) && dirty;

export const hasActiveChanges = (record: FormableRecord) => Object.values(record)
  .some(isFormChanged);

export const canSubmit = (record: FormableRecord) => (
  Object.values(record)
    .every(form => form.isValid) &&
  hasActiveChanges(record)
);

export const resetActiveChanges = (record: FormableRecord) =>
  Object.values(record)
    .forEach(form => form.setTouched({}));

export const validate = (record: FormableRecord) =>
  Object.values(record)
    .forEach(form => form.validateForm());

export const getValues = <T extends Record<PropertyKey, unknown>>(values: Record<PropertyKey, unknown>) => (
  Object.entries(values)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value)
        ? [value].flat()
          .join(',')
        : value
    }), {} as T)
);

export const withDirty = <T extends Record<PropertyKey, unknown>, TReturn>(
  fn: (values: Record<PropertyKey, unknown>) => TReturn,
  {
    getInitialValues,
    addExtras,
    prepareValues = getValues
  }: WithDirtyOptions<T>
) => (values: Partial<T>) => {
  const changeValues = getDirtyFields({
    values,
    initialValues: trimObjectValues<T, T>(getInitialValues())
  });

  return fn({
    ...prepareValues(changeValues),
    ...(addExtras?.(getInitialValues()) ?? {})
  });
};

export type WithDirtyOptions<T extends Record<PropertyKey, unknown>> = {
  readonly getInitialValues: () => T;
  readonly addExtras?: (entity?: Partial<T>) => Record<PropertyKey, unknown>;
  readonly prepareValues?: (values: Record<PropertyKey, unknown>) => Record<PropertyKey, unknown>;
};

export type PickFormState<T extends keyof ReturnType<typeof useForm>> =
  & Pick<ReturnType<typeof useForm>, T>;

export type UnsavedChangesFormState = WithSubscription<
  PickFormState<
    | 'isValid'
    | 'setTouched'
    | 'touched'
    | 'dirty'
  > & {
    readonly validateForm: () => void;
  }
>;

export type FormableRecord = Record<
  Scalar<string>,
  UnsavedChangesFormState
>;
