import { memo } from 'react';
import Typography from '@mui/material/Typography';
import { ConfirmableAction } from 'ui/molecules/Action';
import { usePaymentMethodContext } from '../PaymentMethodProvider';

const PaymentMethodAccordionAction = () => {
    const {
        isProcessing,
        hasActiveChanges,
        updateOrCreate: onConfirm
    } = usePaymentMethodContext();

    const dialogContent = {
        dialogContent: [
            <>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        fontSize: '18px',
                        margin: '0'
                    }}
                >
                    Changes to payment method have been made. Are you sure you want to save these adjustments?
                </Typography>
            </>
        ],
        actionContent: 'Save Changes'
    };

    return (
        <ConfirmableAction
            ActionProps={{
                loading: isProcessing,
                disabled: !hasActiveChanges(),
                variant: 'contained',
                color: 'primary',
                children: 'Save'
            }}
            DialogProps={{
                content: [
                    dialogContent,
                    dialogContent
                ],
                onConfirm,
                onCancel: () => { },
                checkPredicate: () => false,
                cancelActionContentSlot: 'Cancel'
            }}
        />
    );
};

export default memo(PaymentMethodAccordionAction);