import type { ThunkReturnType } from "types";
import { useRequest } from "hooks";
import { PER_PAGE_SIZE } from "consts/transactions";
import useFetchFeatureResource from "hooks/useFetchFeatureResource";
import { getPaymentMethods } from "features/paymentMethods/api";
import { changePaymentMethodsLoadingState, changePaymentMethodsSlice } from "features/paymentMethods/slice";
import { ApiState } from "infrastructure/api";
import { selectIsNotMor } from "features/auth/selectors";
import type { PaymentMethod, PaymentMethodResponse } from "./types";
import { updateOrCreatePaymentMethodThunk } from "./thunks";

const useActions = () => {
    const getAllPaymentMethods = useFetchFeatureResource({
        shouldFetchAll: true,
        perPage: PER_PAGE_SIZE,
        resourceRequest: getPaymentMethods,
        changeLoadingState: changePaymentMethodsLoadingState,
        changeSlice: changePaymentMethodsSlice,
        condition: getState => selectIsNotMor(getState()) && ![
            ApiState.Succeeded,
            ApiState.Pending
        ].includes(getState().paymentMethods.paymentMethodsLoadingState)
    });

    const updateOrCreatePaymentMethod = useRequest<
        ThunkReturnType<PaymentMethodResponse>,
        Partial<PaymentMethod>
    >({
        thunk: updateOrCreatePaymentMethodThunk
    });

    return {
        getAllPaymentMethods,
        updateOrCreatePaymentMethod
    };
};

export default useActions;
