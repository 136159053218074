import { memo } from "react";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";

const AddMerchantFeeAction = (props: LoadingButtonProps) => (
    <LoadingButton
        variant='text'
        startIcon={<AddIcon />}
        color='primary'
        {...props}
        sx={{
            whiteSpace: 'nowrap',
            ...props.sx
        }}
    />
);

export default memo(AddMerchantFeeAction);
