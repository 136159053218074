import { memo } from "react";
import type { PaymentMethod } from "features/paymentMethods/types";
import Label from "./Label";

const PaymentMethodProvider = ({ PSP }: PaymentMethod) => (
    <Label
        title='Provider'
    >
        {PSP}
    </Label>
);

export default memo(PaymentMethodProvider);
