import type { TabsProps } from "ui/atoms/Tabs";

export type StatefulTabsProps = TabsProps & {
    readonly searchParamName?: string;
};

export const enum StatefulTabs {
    InitialValue = '1',
    SearchParamName = 'activeTab'
};
