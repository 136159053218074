import { memo } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete from "./Autocomplete";
import type { CheckboxAutocompleteProps } from "./types";
import { getOptionName } from "util/option";

const CheckboxAutocomplete = ({ CheckboxBox, ...restProps }: CheckboxAutocompleteProps) => (
    <Autocomplete
        {...restProps}
        renderOption={(props, option, { selected }) => (
            <Box
                component='li'
                {...props}
            >
                <Checkbox
                    icon={(
                        <CheckBoxOutlineBlankIcon
                            fontSize='small'
                        />
                    )}
                    checkedIcon={(
                        <CheckBoxIcon
                            fontSize='small'
                        />
                    )}
                    {...CheckboxBox}
                    sx={{
                        mr: 1,
                        ...CheckboxBox?.sx
                    }}
                    checked={selected}
                />
                {getOptionName(option, option)}
            </Box>
        )}
    />
);

export default memo(CheckboxAutocomplete);
