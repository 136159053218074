import { memo, useMemo } from "react";
import Chip from "@mui/material/Chip";
import WarningIcon from '@mui/icons-material/Warning';
import type { MerchantsMethodsPivot } from "features/pivots/types";

export enum RiskLevelEnum {
    Min = 20,
    Average = 40,
    High = 60,
    Max = 80
};

type Props = Pick<MerchantsMethodsPivot, 'maxRiskLevel'>;

const RiskLevel = ({ maxRiskLevel }: Props) => {
    const color = useMemo(() => {
        if (maxRiskLevel <= RiskLevelEnum.Min) {
            return 'success';
        }
        if (maxRiskLevel <= RiskLevelEnum.Average) {
            return 'info';
        }

        if (maxRiskLevel <= RiskLevelEnum.High) {
            return 'warning';
        }

        if (maxRiskLevel >= RiskLevelEnum.Max) {
            return 'error';
        }

        return 'default';
    }, [maxRiskLevel]);

    return (
        <Chip
            size='small'
            icon={(
                <WarningIcon />
            )}
            color={color}
            label={`${maxRiskLevel} Risk`}
        />
    );
};

export default memo(RiskLevel);
