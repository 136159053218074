import { Filters } from "consts/consumers";
import { ManualBulkKycImportStatus } from "consts/kyc";
import { Option } from "util/option";
import { TableProps } from "ui/widgets/Table";

export default function useFiltersProps(): TableProps['FiltersProps'] {
    return {
        filtersModelOverrides: {
            [Filters.active]: [
                Option.make(0, 'Inactive'),
                Option.make(1, 'Active')
            ],
            [Filters.hasImmunityForForbiddenWords]: [
                Option.make(0, 'Exclude'),
                Option.make(1, 'Add')
            ],
            [Filters.manualBulkKycImportStatus]: [
                Option.make(ManualBulkKycImportStatus.Created, `Is Bulk KYC'ed`),
                Option.make(ManualBulkKycImportStatus.NotCreated, `Is not Bulk KYC'ed`)
            ]
        }
    };
};
