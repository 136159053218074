import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Event } from "consts/auth";
import { subscribe, unsubscribe } from "util/custom-event";
import useAuthActions from "features/auth/useActions";
import { WebRoutes } from "consts/enpoints/web";

export default function useSignIn() {
    const navigate = useNavigate();
    const { getUser } = useAuthActions();

    const handlers = {
        navigate,
        getUser
    };
    const handlersRef = useRef(handlers);
    handlersRef.current = handlers;

    useEffect(() => {
        const { navigate, getUser } = handlersRef.current;

        const handleRedirect2FA: EventListener = event => {
            const { detail } = event as CustomEvent;

            navigate(WebRoutes.Login.Verify2FA, {
                state: {
                    from: WebRoutes.Login.Index,
                    email: detail.email
                }
            });
        };

        const handleGetUser = async () => {
            const [request] = getUser();

            await request;
        };

        subscribe(Event.Redirect2FA, handleRedirect2FA);
        subscribe(Event.GetUser, handleGetUser);

        return () => {
            unsubscribe(Event.Redirect2FA, handleRedirect2FA);
            unsubscribe(Event.GetUser, handleGetUser);
        };
    }, []);
};
