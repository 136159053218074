import { createContext, memo, type PropsWithChildren } from "react";
import type {
    PaymentMethodProviderProps,
    PaymentMethodContext as PaymentMethodDetailContextType,
} from "./types";
import usePaymentMethodDetail from "./usePaymentMethod";

export const PaymentMethodContext = createContext<
    PaymentMethodDetailContextType
>({} as PaymentMethodDetailContextType);

const PaymentMethodProvider = ({ children, ...paymentMethod }: PropsWithChildren<PaymentMethodProviderProps>) => (
    <PaymentMethodContext.Provider
        value={usePaymentMethodDetail(paymentMethod)}
    >
        {children}
    </PaymentMethodContext.Provider>
);

export default memo(PaymentMethodProvider);
