import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CurrencySymbol } from "consts/general";
import type { MerchantsMethodsPivot } from "features/pivots/types";

const LimitationsInfo = (data: MerchantsMethodsPivot) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}
        >
            <Typography>
                {data.maxNumberOfTransactionProfile24}
                <strong> - max number of trx/24h/profile</strong>
            </Typography>
            <Typography>
                {[data.maxTotalAmountProfile24, CurrencySymbol.EUR].join(' ')}
                <strong> - max amount /24h/profile</strong>
            </Typography>
            <Typography>
                {`${data.minTicketSize} - ${data.maxTicketSize} `}
                <strong>/trx</strong>
            </Typography>
        </Box>
    );
};

export default memo(LimitationsInfo);