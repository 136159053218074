import { useEffect, useState } from "react";
import type { FormikHelpers } from "formik";
import type { AggregateRoot } from "types";
import { useTypedSelector } from "hooks";
import { withSubscription } from "providers/ContextPublisher";
import { isEqual } from "util/support";
import { parse } from "util/parsers";
import { SpecInParams } from "ui/forms/Setup/PaymentMethod";
import { selectBootstrapData } from "features/general/selectors";
import usePaymentMethodActions from "features/paymentMethods/useActions";
import type { UsePaymentMethodArg } from "./types";

export default function usePaymentMethodSpecInParams({
    useCaseDiscriminationKey,
    paymentMethod
}: UsePaymentMethodArg) {
    const { updateOrCreatePaymentMethod } = usePaymentMethodActions();

    const paymentMethodSpecInParameters = paymentMethod?.specInParameters;
    const [specInParameters, setSpecInParameters] = useState<Array<SpecInParams.SetupPaymentMethodSpecInParams>>([]);
    const {
        TransactionSpecins,
        PaymentMethodSpecinFieldTypes,
        PaymentMethodSpecinOptionTypes
    } = useTypedSelector(selectBootstrapData);

    const getSpecInParameters = (
        specInParameters: Array<SpecInParams.SetupPaymentMethodSpecInParams>,
        aggregateRoot?: AggregateRoot
    ) => ({
        ...aggregateRoot,
        specInParameters: JSON.stringify(specInParameters)
    });

    const update = (specInParameters: Array<SpecInParams.SetupPaymentMethodSpecInParams>) =>
        updateOrCreatePaymentMethod(
            getSpecInParameters(
                specInParameters,
                { coreId: paymentMethod!.coreId }
            )
        );

    const bootstrapData = {
        isBootstrapDataLoading: false,
        bootstrapData: {
            [SpecInParams.FormField.SpecIn]: TransactionSpecins,
            [SpecInParams.FormField.Option]: PaymentMethodSpecinOptionTypes,
            [SpecInParams.FormField.Type]: PaymentMethodSpecinFieldTypes
        },
        onSaveOrCreate: (
            values: Partial<SpecInParams.SetupPaymentMethodSpecInParams>,
            formikHelpers?: FormikHelpers<Partial<SpecInParams.SetupPaymentMethodSpecInParams>>
        ) => {
            setSpecInParameters(state => [
                ...state,
                values as SpecInParams.SetupPaymentMethodSpecInParams
            ]);

            formikHelpers!.resetForm();
        }
    };

    const deleteSpecInParamsByIndex = async (index: number) => {
        const state = specInParameters.filter((_, i) => !Object.is(i, index));

        setSpecInParameters(state);

        const [request] = update(state);

        return (await request);
    };

    useEffect(() => {
        setSpecInParameters(
            parse(paymentMethodSpecInParameters!, [])
        );
    }, [paymentMethodSpecInParameters]);

    const hasChanges = () => !isEqual(
        paymentMethodSpecInParameters ?? '[]',
        JSON.stringify(specInParameters)
    );

    const getChangedValues = (): Partial<UsePaymentMethodArg['paymentMethod']> => (
        hasChanges()
            ? getSpecInParameters(specInParameters)
            : {}
    );

    const specInParamsForm = SpecInParams.useForm({
        ...bootstrapData,
        onSaveOrCreate: bootstrapData.onSaveOrCreate
    });

    const resetForm = () => {
        setSpecInParameters([]);
        specInParamsForm.resetForm();
    };

    return {
        ...withSubscription({
            ...bootstrapData,
            ...specInParamsForm
        }, `PaymentMethodDetailTab.SpecificInputParameters${[paymentMethod!.coreId, useCaseDiscriminationKey]}`),
        isValid: true,
        specInParameters,
        hasChanges,
        getChangedValues,
        deleteSpecInParamsByIndex,
        resetForm
    };
};
