import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CurrencySymbol } from "consts/general";
import { fNumber } from "util/formaters";
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import type { FeeProps } from "./types";
import Actions from "./Actions";

type Props = FeeProps<{
    readonly merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxes;
}>;

const Fee = ({
    start,
    end,
    currency = CurrencySymbol.EUR,
    merchantPayoutIncrementTaxes
}: Props) => {
    const {
        currentIncMultiplierForEachRefColumn
    } = merchantPayoutIncrementTaxes;

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto auto',
                gap: 4
            }}
        >
            <Typography
                sx={{
                    fontWeight: 600
                }}
            >
                {`${fNumber(start)} - ${Number.isFinite(end) ? fNumber(end) : '∞'} ${currency}`}
            </Typography>
            <Typography>
                {currentIncMultiplierForEachRefColumn} {currency}
            </Typography>
            <Actions
                {...merchantPayoutIncrementTaxes}
            />
        </Box>
    );
};

export default memo(Fee);
