import BusinessLogicException from "exceptions/BusinessLogicException";
import type { ID } from "types";
import type { ActionHookArg } from "../Props";
import { generateFileName } from "util/support";
import useActionHandler from "./useActionHandler";
import type { AbstractFileEntity } from "features/merchantPayouts/types";

const defaultRequestHandler = async (coreId: ID, fileName: string, MOR: string) => new Response();

export default function usePreviewSettlementXLS<T extends AbstractFileEntity>({
    row,
    handler = defaultRequestHandler
}: ActionHookArg<
    T,
    typeof defaultRequestHandler
>) {
    const actionHandler = useActionHandler(row);

    return actionHandler(async () => {
        if (!row.coreId) {
            throw new BusinessLogicException("Invalid fileKey!", {});
        }

        const response = await handler(
            row.coreId,
            generateFileName({
                prefix: 'Preview Settlement',
                fileKey: row.fileKey ?? row.coreId.toString(),
                extension: 'xls'
            }),
            row.MOR
        );

        if (!response.ok) {
            throw new BusinessLogicException("Something went wrong while generating .XLS file", { response });
        }
    });
};
