import { useEffect, useRef, useState, type MouseEvent } from "react";
import type { ID, PaginateResourceResponse } from "types";
import useFetchResource from "hooks/useFetchResource";
import { getMerchantsMethodsPivot, updateMerchantsMethodsPivot } from "features/pivots/api";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters, PER_PAGE_SIZE } from "consts/transactions";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { useSimpleRequest } from "hooks/useRequest";
import { compareIds } from "util/support";
import useConfirmationDialog from "./useConfirmationDialog";
import { fromPaginateResourceResponse } from "util/api";
import { toBooleanSwitch } from "util/casts";

export type UseFetchPaymentMethodsArg = {
    readonly merchantId?: ID;
};

export default function useFetchPaymentMethods({ merchantId }: UseFetchPaymentMethodsArg) {
    const [isLoading, setLoading] = useState(false);

    const [merchantMethodsPivot, setMerchantMethodsPivot] = useState<Array<MerchantsMethodsPivot>>([]);

    const [processingMethodIds, setProcessingMethodsIds] = useState<Array<ID>>([]);

    const isRequestsProcessingRef = useRef(false);

    const request = useSimpleRequest();

    const merchantsMethodsPivotRequestRef = useRef(
        useFetchResource({
            shouldFetchAll: true,
            perPage: PER_PAGE_SIZE
        })
    );

    const {
        openConfirmationDialog,
        closeConfirmationDialog,
        onConfirm,
        onCancel,
        open,
        processingMethodRef,
        isProcessing
    } = useConfirmationDialog();

    useEffect(() => {
        if (isRequestsProcessingRef.current) {
            return;
        }

        setMerchantMethodsPivot([]);
        setProcessingMethodsIds([]);

        let isLoading = isRequestsProcessingRef.current = true;
        setLoading(isLoading);

        merchantsMethodsPivotRequestRef.current(searchQueryParams =>
            getMerchantsMethodsPivot(
                RequestQueryMapper.from(searchQueryParams)
                    .containsIn(Filters.merchantId, String(merchantId))
                    .toString()
            ))
            .then((merchantMethodsResponse: PaginateResourceResponse<MerchantsMethodsPivot>) => {
                setMerchantMethodsPivot(
                    fromPaginateResourceResponse(merchantMethodsResponse)
                );
            })
            .finally(() => {
                isLoading = isRequestsProcessingRef.current = false;
                setLoading(isLoading);
            });
    }, [merchantId]);

    const getActiveMerchantPaymentMethodIds = () =>
        merchantMethodsPivot.reduce((ids, { shouldKyc, coreId }) => {
            if (shouldKyc) {
                return [
                    ...ids,
                    coreId
                ];
            }

            return ids;
        }, [] as ID[]);

    const getIsMethodProcessing = (coreId: ID) =>
        processingMethodIds.includes(coreId);

    const updateMerchantMethodsPivot = (merchantPaymentMethodIds: ID[]) =>
        setMerchantMethodsPivot(state =>
            state.map(merchantMethod => ({
                ...merchantMethod,
                shouldKyc: toBooleanSwitch(merchantPaymentMethodIds.includes(merchantMethod.coreId))
            })));

    const handleChange = (_: MouseEvent<HTMLElement>, merchantPaymentMethodIds: ID[]) =>
        updateMerchantMethodsPivot(merchantPaymentMethodIds);

    const handleProcess = async ({ coreId, shouldKyc }: Pick<
        MerchantsMethodsPivot,
        | 'coreId'
        | 'shouldKyc'
    >) => {
        setProcessingMethodsIds(state => [
            ...state,
            coreId
        ]);

        try {
            await openConfirmationDialog({
                coreId,
                shouldKyc
            });

            const updatedShouldKyc = toBooleanSwitch(!shouldKyc);

            await request(() => updateMerchantsMethodsPivot({
                coreId,
                shouldKyc: updatedShouldKyc
            }));

            setMerchantMethodsPivot(state =>
                state.map(merchantMethod => {
                    if (compareIds(merchantMethod.coreId, coreId)) {
                        return {
                            ...merchantMethod,
                            shouldKyc: updatedShouldKyc
                        };
                    }

                    return merchantMethod;
                }));
        } catch {
            setMerchantMethodsPivot(state =>
                state.map(merchantMethod => {
                    if (compareIds(merchantMethod.coreId, coreId)) {
                        return {
                            ...merchantMethod,
                            shouldKyc
                        };
                    }

                    return merchantMethod;
                }));
        } finally {
            setProcessingMethodsIds(state =>
                state.filter(id => !compareIds(id, coreId)));

            closeConfirmationDialog();
        }
    };

    return {
        isLoading: isLoading,
        isMethodsAvailable: merchantMethodsPivot.length > 0,
        merchantMethodsPivot,
        getActiveMerchantPaymentMethodIds,
        handleChange,
        handleProcess,
        getIsMethodProcessing,
        onConfirm,
        onCancel,
        open,
        processingMethodRef,
        isProcessing
    };
};
