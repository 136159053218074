import { memo } from "react";
import Box from "@mui/material/Box";
import { Resource, ResourceProps } from "ui/widgets/Resource";
import { TableGridMode } from "consts/table";
import { Filters } from "consts/merchants";
import { BooleanSwitchValue } from "consts/general";
import { Status } from "consts/users";
import { Option } from "util/option";
import {
    selectIsMerchantsLoading,
    selectMerchantsSlice
} from "features/merchants/selectors";
import useMerchants from "./useMerchants";
import MerchantToolbar from "./MerchantToolbar";
import MerchantCreateEdit from "./MerchantCreateEdit";
import ToolbarFilters, {
    activeInactiveSelectorKey,
    useToolbarFiltersContext
} from "../shared/ToolbarFilters";

type Props = Pick<
    ResourceProps,
    | 'LayoutProps'
    | 'filterTypes'
    | 'FiltersProps'
    | 'columns'
>;

const Merchants = ({
    LayoutProps,
    filterTypes,
    FiltersProps,
    columns
}: Props) => {
    const { filtersRef } = useToolbarFiltersContext();

    return (
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Resource
                name='Merchant'
                useResource={useMerchants}
                mode={TableGridMode.Merchant}
                selectIsTableUninitialized={() => true}
                selectIsTableLoading={selectIsMerchantsLoading()}
                selectTableDataSlice={selectMerchantsSlice()}
                Toolbar={MerchantToolbar}
                Detail={MerchantCreateEdit}
                LayoutProps={LayoutProps}
                filterTypes={filterTypes}
                FiltersProps={FiltersProps}
                columns={columns}
                filtersRef={filtersRef}
            />
        </Box>
    );
};

export default memo((props: Props) => (
    <ToolbarFilters
        toolbarFilters={new Map([
            [activeInactiveSelectorKey, {
                filterId: Filters.isActive,
                options: [
                    Option.make(BooleanSwitchValue.On, Status.Active),
                    Option.make(BooleanSwitchValue.Off, Status.Inactive)
                ]
            }]
        ])}
    >
        <Merchants
            {...props}
        />
    </ToolbarFilters>
));
