import { memo } from "react";
import { BasicInfo as SetupPaymentMethodBasicInfo } from 'ui/forms/Setup/PaymentMethod';
import { usePaymentMethodContext } from "../../PaymentMethodProvider";

const BasicInfo = () => {
    const { selectBasicInfo } = usePaymentMethodContext();

    return (
        <SetupPaymentMethodBasicInfo.BasicInfo
            {...selectBasicInfo()}
        />
    );
};

export default memo(BasicInfo);
