import { memo } from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { getFormInputProps } from "util/forms";
import { PasswordTextField } from "ui/atoms/TextField";
import useValidationSchema, { FormField } from "./useValidationSchema";
import type { SignInCredentials } from "./types";
import SubmitButton from "../SubmitButton";

type Props = {
    readonly onSubmit: (values: SignInCredentials) => Promise<void>;
};

const SignIn = (props: Props) => {
    const {
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        isSubmitting,
        ...formik
    } = useFormik<SignInCredentials>({
        ...props,
        initialValues: {
            [FormField.Email]: '',
            [FormField.Password]: ''
        },
        validateOnMount: true,
        validationSchema: useValidationSchema()
    });

    return (
        <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
        >
            <TextField
                margin='normal'
                required
                fullWidth
                id={FormField.Email}
                label='Email Address'
                autoComplete='email'
                type='email'
                autoFocus
                name={FormField.Email}
                onChange={handleChange}
                onBlur={handleBlur}
                {...getFormInputProps(FormField.Email, formik)}
            />
            <PasswordTextField
                {...getFormInputProps(FormField.Password, formik)}
                name={FormField.Password}
                id={FormField.Password}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <SubmitButton
                disabled={!isValid}
                loading={isSubmitting}
            >
                Login
            </SubmitButton>
        </Box>
    );
};

export default memo(SignIn);
