import { useEffect, useRef, type ReactNode } from "react";
import type { DialogContent } from "ui/molecules/Dialog";
import useUnsavedChangesContext from "./useUnsavedChangesContext";

type UseBootUnsavedChangesArg = {
    readonly content: DialogContent;
    readonly cancelActionContentSlot?: ReactNode;
};

export default function useBootUnsavedChanges(props?: UseBootUnsavedChangesArg) {
    const propsRef = useRef(props);
    propsRef.current = props;

    const context = useUnsavedChangesContext();
    const contextRef = useRef(context);
    contextRef.current = context;

    useEffect(() => {
        const context = contextRef.current;

        if (propsRef.current) {
            const { content, cancelActionContentSlot } = propsRef.current;

            context.setConfirmationDialogContent(content);

            context.customizeConfirmationDialog({ cancelActionContentSlot });
        }

        return () => context.resetConfirmationDialog();
    }, []);
};
