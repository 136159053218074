import { memo, useRef, type MouseEventHandler } from "react";
import MuiLoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";

const LoadingButton = ({ onMouseUp, ...restProps }: LoadingButtonProps) => {
    const ref = useRef<HTMLButtonElement>(null);

    const handleMouseUp: MouseEventHandler<HTMLButtonElement> = event => {
        ref.current?.click();

        return onMouseUp?.(event);
    };

    return (
        <MuiLoadingButton
            ref={ref}
            {...restProps}
            onMouseUp={handleMouseUp}
        />
    );
};

export default memo(LoadingButton);
