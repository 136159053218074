export class ManagementApiMapper {
    public static from(relation: string) {
        return new this(relation);
    }

    private constructor(
        private relation: string
    ) { }

    public get(key: string) {
        return `${this.relation}${key}`;
    }
}
