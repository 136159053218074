import { ApiState } from "infrastructure/api";
import type { Merchant, MerchantBusiness, MerchantDomain } from "features/merchants/types";
import type { DomainAction, PaginateResourceResponse, ResourceResponse } from "types";
import type { IDomainRepository } from "hooks/useDomainState";
import type { UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";

export const enum MerchantDomainsActionType {
    FetchMerchantDomains = 'MerchantDomainsActionType:FetchMerchantDomains',
    UpdateMerchantBusiness = 'MerchantDomainsActionType:UpdateMerchantBusiness',
    UpdateMerchantDomain = 'MerchantDomainsActionType:UpdateMerchantDomain',
    CreateMerchantBusiness = 'MerchantDomainsActionType:CreateMerchantBusiness',
    CreateMerchantDomain = 'MerchantDomainsActionType:CreateMerchantDomain',
    DeleteMerchantBusiness = 'MerchantDomainsActionType:DeleteMerchantBusiness',
    DeleteMerchantDomain = 'MerchantDomainsActionType:DeleteMerchantDomain',
    Reset = 'MerchantDomainsActionType:Reset'
};

export type MerchantDomainsRepositoryState = {
    readonly apiState: ApiState;
    readonly businesses: Array<MerchantBusiness>;
    readonly domains: Map<MerchantDomain['merchantBusinessId'], Array<MerchantDomain>>;
};

export type MerchantDomainsTuple = [MerchantDomain['merchantBusinessId'], Array<MerchantDomain>];

export type MerchantDomainsRepositoryAction =
    | DomainAction<
        MerchantDomainsActionType.FetchMerchantDomains,
        MerchantDomainsRepositoryState
    >
    | DomainAction<
        MerchantDomainsActionType.Reset,
        undefined
    >
    | DomainAction<
        MerchantDomainsActionType.UpdateMerchantBusiness,
        Partial<MerchantBusiness>
    >
    | DomainAction<
        MerchantDomainsActionType.UpdateMerchantDomain,
        MerchantDomain
    >
    | DomainAction<
        MerchantDomainsActionType.CreateMerchantBusiness,
        MerchantBusiness
    >
    | DomainAction<
        MerchantDomainsActionType.CreateMerchantDomain,
        MerchantDomain
    >
    | DomainAction<
        MerchantDomainsActionType.DeleteMerchantBusiness,
        MerchantBusiness
    >
    | DomainAction<
        MerchantDomainsActionType.DeleteMerchantDomain,
        MerchantDomain
    >;

export type CreateMerchantBusinessPayload = Pick<
    MerchantBusiness,
    | 'businessName'
    | 'merchantId'
>;

export type CreateMerchantDomainPayload = Pick<
    MerchantDomain,
    | 'merchantBusinessId'
    | 'sourceUrl'
>;

export interface MerchantDomainsRepository extends IDomainRepository {
    readonly state: MerchantDomainsRepositoryState;

    readonly merchantDomains: Array<MerchantDomain>;

    readonly merchantBusinesses: Array<MerchantBusiness>;

    readonly isMerchantDomainsLoading: boolean;

    readonly isMerchantDomainsError: boolean;

    readonly isMerchantBusinessesEmpty: boolean;

    getIsMerchantDomainsByBusinessIdEmpty(merchantBusinessId: MerchantDomain['merchantBusinessId']): boolean;

    fetchMerchantDomains(merchant: Merchant): Promise<void>;

    fetchMerchantBusinesses(): Promise<PaginateResourceResponse<MerchantBusiness>>;

    fetchMerchantBusinessById(merchantBusinessId: MerchantBusiness['coreId']): Promise<ResourceResponse<MerchantBusiness>>;

    getMerchantDomainsByBusinessId(merchantBusinessId: MerchantDomain['merchantBusinessId']): Array<MerchantDomain>;

    updateMerchantBusiness(
        merchantBusiness: Partial<MerchantBusiness>,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ): Promise<ResourceResponse<MerchantBusiness>>;

    updateMerchantDomain(merchantDomain: Partial<MerchantDomain>): Promise<ResourceResponse<MerchantDomain>>;

    createMerchantBusiness(merchantBusiness: CreateMerchantBusinessPayload): Promise<ResourceResponse<MerchantBusiness>>;

    createMerchantDomain(merchantDomain: CreateMerchantDomainPayload): Promise<ResourceResponse<MerchantDomain>>;

    deleteMerchantBusiness(merchantBusiness: MerchantBusiness): Promise<void>;

    deleteMerchantDomain(merchantDomain: MerchantDomain): Promise<void>;
};
