// !!!!!!!!!!!!!! TODO: this file deprecated, please use renderers from src\ui\widgets\Table\renderers
import { useState, type FunctionComponent } from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Switch from '@mui/material/Switch';
import type { GridRowModel, GridValueFormatterParams } from "@mui/x-data-grid";
import { useTypedSelector } from "hooks";
import { selectMerchantPayoutIncrementsVia } from "features/general/selectors";
import { metaParser } from "./transactionTableConfigTransformer";
import { getNullTextMapper } from "./formatters";

const withRendererFactory = <T extends BaseStatusProps>(Component: FunctionComponent<any>) =>
    (props: T) =>
    (
        <Component
            {...props}
        />
    );

export type BaseStatusProps = {
    readonly value: string;
};

interface CountryISOProps extends BaseStatusProps {
}

export const countryISO = withRendererFactory(({ value }: CountryISOProps) => {
    if (value) {
        return (
            <Box display='flex' columnGap={2}>
                <img
                    {...metaParser('countryIso2', value, 'renderer')}
                    loading="lazy"
                    width="20"
                    alt={value}
                />
                <span>{value}</span>
            </Box>
        );
    }
    return null;
});

/*interface TransactionFundStatusProps extends BaseStatusProps {
    readonly value: FundStatus;
}

export const transactionFundStatus = withRendererFactory(({ value }: TransactionFundStatusProps) => (
    <When
        if={isStatusValid(fundStatus, value)}
        then={(
            <Chip
                {...fundStatus[value]}
                size='small'
                label={value}
            />
        )}
    />
));

interface TransactionStatusProps extends BaseStatusProps {
    readonly value: Status;
}

export const transactionStatus = withRendererFactory(({ value }: TransactionStatusProps) => (
    <When
        if={isStatusValid(transactStatus, value)}
        then={(
            <Chip
                {...transactStatus[value]}
                size='small'
                label={value}
            />
        )}
    />
));

interface SettlementStatusProps extends BaseStatusProps {
    readonly value: SettlementStatus;
}

export const settlementStatus = withRendererFactory(({ value }: SettlementStatusProps) => (
    <When
        if={isStatusValid(settleStatus, value)}
        then={(
            <Chip
                {...settleStatus[value]}
                size='small'
                label={value}
            />
        )}
    />
));

interface BooleanStatusProps {
    readonly value: boolean;
}
export const booleanStatus = withRendererFactory(({ value }: BooleanStatusProps) => {
    if (value) {
        return (
            <Icon
                name="Check"
                color='success'
            />
        );
    }

    return (
        <Icon
            name='Close'
            color='error'
        />
    );
});*/

export const merchantsActiveSwitch = withRendererFactory(({ value }: GridRowModel) => {
    const [isActive, setActiveState] = useState(Boolean(value));

    const onChange = () => {
        setActiveState(state => !state);
    };

    return (
        <Switch
            checked={isActive}
            onChange={onChange}
        />
    );
});

export const genericStatus = withRendererFactory(({ value }) => (
    <Chip
        variant='outlined'
        label={value}
        color='success'
    />
));

export const viaStatus = withRendererFactory(({ value }) => {
    const merchantPayoutIncrementsVia = useTypedSelector(selectMerchantPayoutIncrementsVia);

    return (
        <>
            {merchantPayoutIncrementsVia.find(({ id }) => Object.is(id, value))?.name ?? '-'}
        </>
    );
});

export const nullValue = withRendererFactory((params: GridValueFormatterParams<string>) => {
    const { field, value } = params;

    return value === null ? (
      <Box
        sx={{
          color: "grey.500",
        }}
      >
        {getNullTextMapper(field)}
      </Box>
    ) : (
      <Box>{value}</Box>
    );
})

// export const transactionErrorMessage = withRendererFactory(({ value }: any) => {
//     console.log('value => ', value);
//     if (value?.ResultCdcData?.ERRMSG) {
//         return (
//             <div>
//                 {value.ResultCdcData.ERRMSG}
//             </div>
//         );
//     }

//     return null;
// });

// function isStatusValid<T extends object>(statuses: T, value: BaseStatusProps['value']) {
//     return Object.keys(statuses).includes(value);
// }
