import {
    memo,
    type FC,
    type ComponentType
} from "react";
import Box from "@mui/material/Box";
import { SpecInParams } from "ui/forms/Setup/PaymentMethod";
import { Collection } from "ui/molecules/Resource";
import type {
    SpecInParamsRowProps,
    SpecInParamsValueProps,
    SpecInKey
} from "./types";
import { SpecInParamsValueJson, SpecInParamsValueScalar } from "./SpecInParamsValue";
import SpecInParamsRowActions from "./SpecInParamsRowActions";

const SpecInParamsRow: FC<SpecInParamsRowProps> = ({
    specInParameters,
    valueRenderStrategyRegistry,
    ...restProps
}) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: 1
        }}
    >
        <Collection
            sx={{
                display: 'flex',
                gap: 3,
                py: 2
            }}
            collection={Array.from(valueRenderStrategyRegistry!.entries())}
            renderResource={([key, ValueComponent]: [SpecInKey, ComponentType<SpecInParamsValueProps>]) => (
                <ValueComponent
                    key={key}
                    specInKey={key}
                    specInValue={specInParameters[key]}
                />
            )}
        />
        <SpecInParamsRowActions
            {...restProps}
        />
    </Box>
);

SpecInParamsRow.defaultProps = {
    valueRenderStrategyRegistry: new Map()
        .set(SpecInParams.FormField.SpecIn, SpecInParamsValueScalar)
        .set(SpecInParams.FormField.Option, SpecInParamsValueScalar)
        .set(SpecInParams.FormField.Values, SpecInParamsValueJson)
        .set(SpecInParams.FormField.Type, SpecInParamsValueScalar)
        .set(SpecInParams.FormField.Description, SpecInParamsValueScalar)
};

export default memo(SpecInParamsRow);
