import { useTypedSelector } from "hooks";
import { withSubscription } from "providers/ContextPublisher";
import { getDirtyFields, getValues, isFormChanged, withDirty } from "util/forms";
import { BasicInfo } from "ui/forms/Setup/PaymentMethod";
import { selectCurrencies, selectPSPs } from "features/general/selectors";
import usePaymentMethodActions from "features/paymentMethods/useActions";
import type { UsePaymentMethodArg } from "./types";

export default function usePaymentMethodBasicInfo({
    paymentMethod,
    useCaseDiscriminationKey,
    addExtras
}: UsePaymentMethodArg) {
    const forCurrencies = useTypedSelector(selectCurrencies);
    const PSP = useTypedSelector(selectPSPs);

    const { updateOrCreatePaymentMethod } = usePaymentMethodActions();

    const bootstrapData = {
        isBootstrapDataLoading: false,
        bootstrapData: {
            forCurrencies,
            PSP
        },
        onSaveOrCreate: withDirty(updateOrCreatePaymentMethod, {
            getInitialValues: () => BasicInfo.getInitialValues(paymentMethod),
            addExtras
        })
    };

    const basicInfoForm = BasicInfo.useForm({
        ...bootstrapData,
        initialValues: paymentMethod,
        onSaveOrCreate: bootstrapData.onSaveOrCreate
    });

    const hasChanges = () => isFormChanged(basicInfoForm);

    const getChangedValues = () => getValues(
        getDirtyFields({
            ...basicInfoForm,
            initialValues: BasicInfo.getInitialValues(paymentMethod)
        })
    );

    const resetForm = () => basicInfoForm.resetForm();

    return withSubscription({
        ...bootstrapData,
        ...basicInfoForm,
        resetForm,
        hasChanges,
        getChangedValues,
    }, `PaymentMethodDetailTab.BasicInfo${[paymentMethod!.coreId, useCaseDiscriminationKey]}`);
};
