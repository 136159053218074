import { memo } from "react";
import { useTypedSelector } from "hooks";
import Autocomplete, { type AutocompleteProps } from "ui/atoms/Autocomplete";
import type { RootState } from "infrastructure/store";

type StatefulSelectorProps = Omit<AutocompleteProps, 'options'> & {
    readonly selectOptions: (state: RootState) => any;
};

const StatefulSelector = ({
    selectOptions,
    ...currencySelectorProps
}: StatefulSelectorProps) => (
    <Autocomplete
        {...currencySelectorProps}
        options={useTypedSelector(selectOptions)}
    />
);

export default memo(StatefulSelector);
