import { memo, type PropsWithChildren } from "react";
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import { SupportEmail } from "ui/molecules/Link";
import type { SignInProps } from "./types";

const SignIn = ({ children, title }: PropsWithChildren<SignInProps>) => (
    <>
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                py: 2
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    mb: 5,
                    position: 'relative'
                }}
            >
                <Typography variant="h4">{title}</Typography>
            </Stack>
            {children}
        </Box>
        <Box
            sx={{
                mb: 2
            }}
        >
            <Typography
                sx={{
                    fontWeight: 'bold',
                    display: 'inline'
                }}
            >
                Support:&nbsp;
            </Typography>
            <Typography
                sx={{
                    display: 'inline'
                }}
            >
                if you have any questions please submit an email to <SupportEmail />
            </Typography>
        </Box>
    </>
);

export default memo(SignIn);
