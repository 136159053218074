import { useEffect, useRef } from "react";
import { capitalize } from "@mui/material/utils";
import type { GridColDef, GridRowIdGetter, GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { DEFAULT_PAGE_SIZE } from "consts/table";
import { UiDateTimeFormatter, formatDate } from "util/formaters";
import type { MerchantsMethodsPivot, MerchantsMethodsPivotHistory } from "features/pivots/types";
import { BooleanStatus } from "ui/widgets/Table/renderers";
import { useAccordionContext } from "ui/widgets/Accordion";
import useMerchantDetailsTabContext from "../../../useMerchantDetailsTabContext";
import type { MerchantPaymentMethodsRepository } from "../../repository";
import { Boldable, State } from "./MerchantPaymentHistoryColumns";
import { RiskLevel, MinMaxTicketSize } from "../MerchantPaymentMethodPSPSummary";

export type UseMerchantPaymentMethodHistoryArg = {
    readonly merchantMethod: MerchantsMethodsPivot;
    readonly forceRefetch?: boolean;
};

export default function useMerchantPaymentMethodHistory({ merchantMethod, forceRefetch }: UseMerchantPaymentMethodHistoryArg) {
    const { methodId, coreId } = merchantMethod;

    const { expandedPanel } = useAccordionContext();

    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    const coreIdRef = useRef(coreId);
    coreIdRef.current = coreId;

    useEffect(() => {
        if (
            !forceRefetch &&
            (!methodId || !Object.is(expandedPanel, coreIdRef.current))
        ) {
            return;
        }

        repositoryRef.current
            .fetchMerchantsMethodsHistory({ methodId });
    }, [
        methodId,
        expandedPanel,
        forceRefetch
    ]);

    const dateTimeValueFormatter = ({ value }: GridValueFormatterParams<string>) =>
        formatDate(value, { pattern: UiDateTimeFormatter.Write });

    const columns: GridColDef[] = [
        {
            field: 'isEnabled',
            headerName: 'State',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            renderCell: State,
            sortable: true
        },
        {
            field: 'MOR',
            headerName: 'Domain',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            valueGetter: ({ value }) => capitalize(value || 'Default'),
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'createdAt',
            headerName: 'Date Created',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: dateTimeValueFormatter,
            sortable: true
        },
        {
            field: 'updatedAt',
            headerName: 'Date updated',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: dateTimeValueFormatter,
            sortable: true
        },
        {
            field: 'maxNumberOfTransaction24',
            headerName: 'Maxim no. of trx/24',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'minTicketSize',
            headerName: 'Min - Max ticket size',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ row }) => (
                <MinMaxTicketSize
                    minTicketSize={row.minTicketSize}
                    maxTicketSize={row.maxTicketSize}
                />
            ),
        },
        {
            field: 'velocitySeconds',
            headerName: 'Velocity',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'maxRiskLevel',
            headerName: 'Risk',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: ({ value }) => (
                <RiskLevel
                    maxRiskLevel={value}
                />
            ),
            sortable: true
        },
        {
            field: 'maxNumberOfTransactionWeek',
            headerName: 'Maxim no. of trx/week',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'maxNumberOfTransactionMonth',
            headerName: 'Maxim no. of trx/month',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'maxNumberOfTransactionProfile24',
            headerName: 'Maximum no. of Transactions in 24 hours for same profile / tag',
            width: 300,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'maxTotalAmountWeek',
            headerName: 'Maxim total amount trx/week',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'maxTotalAmountMonth',
            headerName: 'Maxim total amount trx/month',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'maxTotalAmountProfile24',
            headerName: 'Maximum total no. of Transactions in 24 hours for same profile / tag',
            width: 300,
            align: 'center',
            headerAlign: 'center',
            renderCell: Boldable,
            sortable: true
        },
        {
            field: 'isLimitEnabled',
            headerName: 'Trx. limit enabled',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: BooleanStatus,
            sortable: true
        },
        {
            field: 'requireConsumerData',
            headerName: 'Require consumer data',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: BooleanStatus,
            sortable: true
        },
        {
            field: 'shouldKyc',
            headerName: 'Should KYC',
            width: 160,
            align: 'center',
            headerAlign: 'center',
            renderCell: BooleanStatus,
            sortable: true
        }
    ];

    const getRowId: GridRowIdGetter<MerchantsMethodsPivotHistory> = ({ historyCreatedAt }) => historyCreatedAt;

    return {
        columns,
        rows: repository.merchantsMethodsHistory,
        loading: repository.isMerchantMethodsHistoryLoading,
        getRowId,
        pagination: true,
        initialState: {
            pagination: {
                pageSize: DEFAULT_PAGE_SIZE
            }
        },
        rowsPerPageOptions: [
            DEFAULT_PAGE_SIZE,
            DEFAULT_PAGE_SIZE * 5,
            DEFAULT_PAGE_SIZE * 10
        ]
    };
};
