import { memo, useEffect } from "react";
import {
    Navigate as NavigateRouterDom,
    useLocation,
    type NavigateProps
} from "react-router-dom";

type Props = NavigateProps & {
    readonly reload?: boolean;
};

const Navigate = ({
    reload,
    state,
    ...props
}: Props) => {
    const location = useLocation();

    useEffect(() => {
        if (reload) {
            window.location.reload();
        }
    }, [reload]);

    return (
        <NavigateRouterDom
            {...props}
            state={state ?? { from: location }}
        />
    );
};

export default memo(Navigate);
