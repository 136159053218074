import { memo } from "react";
import Box from "@mui/material/Box";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import Checkbox, { type CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import { CurrencySymbol } from "consts/general";
import { getFormInputProps } from "util/forms";
import { FormField, type WithMerchantsMethodsPivot, type PaymentMethodLimitsFormProps } from "./types";
import InputGroup from "./InputGroup";

const PaymentMethodLimits = ({
    isValid,
    isSubmitting,
    values,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    paymentMethodIsAssignable,
    ...props
}: WithMerchantsMethodsPivot<PaymentMethodLimitsFormProps>) => {
    const onCheckboxChange: (formField: FormField) => CheckboxProps['onChange'] = (formField: FormField) =>
        (_, value) => {
            setFieldTouched(formField, true);
            setFieldValue(
                formField,
                value,
                true
            );
        };

    const withPaymentMethodIsAssignable = () => ({
        disabled: !paymentMethodIsAssignable
    });

    const withTextFieldProps = (
        propsOverride?: TextFieldProps,
        inputPropsOverride?: TextFieldProps['inputProps']
    ): TextFieldProps => ({
        fullWidth: true,
        size: 'small',
        type: 'number',
        placeholder: '.00',
        ...propsOverride,
        inputProps: {
            step: .01,
            min: 0,
            max: 100_000_000,
            ...inputPropsOverride
        },
        ...withPaymentMethodIsAssignable(),
        onChange: handleChange,
        onBlur: handleBlur
    });

    return (
        <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{
                display: 'grid',
                gap: 2
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        alignContent: 'space-between',
                        gap: 2
                    }}
                >
                    <TextField
                        {...withTextFieldProps()}
                        {...getFormInputProps(FormField.MinimumTicketSize, props)}
                        name={FormField.MinimumTicketSize}
                        value={values[FormField.MinimumTicketSize]}
                        label='Minimum Ticket Size'
                        InputProps={{
                            endAdornment: CurrencySymbol.EUR
                        }}
                    />
                    <TextField
                        {...withTextFieldProps()}
                        {...getFormInputProps(FormField.MaximumTicketSize, props)}
                        name={FormField.MaximumTicketSize}
                        value={values[FormField.MaximumTicketSize]}
                        label='Maximum Ticket Size'
                        InputProps={{
                            endAdornment: CurrencySymbol.EUR
                        }}
                    />
                    <TextField
                        {...withTextFieldProps({
                            placeholder: ''
                        }, { step: 1 })}
                        {...getFormInputProps(FormField.MaxTransactionNumberWithin24HoursPerMerchant, props)}
                        name={FormField.MaxTransactionNumberWithin24HoursPerMerchant}
                        value={values[FormField.MaxTransactionNumberWithin24HoursPerMerchant]}
                        label='Max No. of Transactions within 24hrs / merchant'
                    />
                    <TextField
                        {...withTextFieldProps({
                            placeholder: ''
                        }, { step: 1 })}
                        {...getFormInputProps(FormField.MaxTransactionNumberWithinWeekPerMerchant, props)}
                        name={FormField.MaxTransactionNumberWithinWeekPerMerchant}
                        value={values[FormField.MaxTransactionNumberWithinWeekPerMerchant]}
                        label='Max No. of Transactions with week / merchant'
                    />
                    <TextField
                        {...withTextFieldProps({
                            placeholder: ''
                        }, { step: 1 })}
                        {...getFormInputProps(FormField.MaxTransactionNumberWithinMounthPerMerchant, props)}
                        name={FormField.MaxTransactionNumberWithinMounthPerMerchant}
                        value={values[FormField.MaxTransactionNumberWithinMounthPerMerchant]}
                        label='Max No. of Transaction with month / merchant'
                    />
                    <TextField
                        {...withTextFieldProps()}
                        {...getFormInputProps(FormField.MaxTotalAmountWithinWeekPerMerchant, props)}
                        name={FormField.MaxTotalAmountWithinWeekPerMerchant}
                        value={values[FormField.MaxTotalAmountWithinWeekPerMerchant]}
                        label='Max Total Amount within week / merchant'
                        InputProps={{
                            endAdornment: CurrencySymbol.EUR
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gap: 2
                    }}
                >
                    <InputGroup>
                        <TextField
                            {...withTextFieldProps({
                                placeholder: ''
                            }, { step: 1 })}
                            {...getFormInputProps(FormField.MaxTransactionNumberIn24HoursForSamePersonTag, props)}
                            name={FormField.MaxTransactionNumberIn24HoursForSamePersonTag}
                            value={values[FormField.MaxTransactionNumberIn24HoursForSamePersonTag]}
                            label='Maximum No. of trx/24h/profile'
                        />
                        <FormControlLabel
                            {...withPaymentMethodIsAssignable()}
                            sx={{
                                whiteSpace: 'nowrap'
                            }}
                            control={(
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Adjust by Consumer Risk',
                                    }}
                                    name={FormField.AdjustMaxNumberOfTransactionProfile24ByConsumerRisk}
                                    onChange={onCheckboxChange(FormField.AdjustMaxNumberOfTransactionProfile24ByConsumerRisk)}
                                    onBlur={handleBlur}
                                    checked={Boolean(values[FormField.AdjustMaxNumberOfTransactionProfile24ByConsumerRisk])}
                                />
                            )}
                            label='Adjust by Consumer Risk'
                        />
                    </InputGroup>
                    <InputGroup>
                        <TextField
                            {...withTextFieldProps()}
                            {...getFormInputProps(FormField.MaxTotalAmountIn24HoursForSamePersonTag, props)}
                            name={FormField.MaxTotalAmountIn24HoursForSamePersonTag}
                            value={values[FormField.MaxTotalAmountIn24HoursForSamePersonTag]}
                            label='Total Amount /24h/profile'
                            InputProps={{
                                endAdornment: CurrencySymbol.EUR
                            }}
                        />
                        <FormControlLabel
                            {...withPaymentMethodIsAssignable()}
                            sx={{
                                whiteSpace: 'nowrap'
                            }}
                            control={(
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Adjust by Consumer Risk',
                                    }}
                                    name={FormField.AdjustMaxTotalAmountProfile24ByConsumerRisk}
                                    onChange={onCheckboxChange(FormField.AdjustMaxTotalAmountProfile24ByConsumerRisk)}
                                    onBlur={handleBlur}
                                    checked={Boolean(values[FormField.AdjustMaxTotalAmountProfile24ByConsumerRisk])}
                                />
                            )}
                            label='Adjust by Consumer Risk'
                        />
                    </InputGroup>
                    <InputGroup>
                        <TextField
                            {...withTextFieldProps()}
                            {...getFormInputProps(FormField.MaxTotalAmountForSameProfileTag, props)}
                            name={FormField.MaxTotalAmountForSameProfileTag}
                            value={values[FormField.MaxTotalAmountForSameProfileTag]}
                            label='Max Total Amount per month for the same person/tag'
                            InputProps={{
                                endAdornment: CurrencySymbol.EUR
                            }}
                        />
                        <FormControlLabel
                            {...withPaymentMethodIsAssignable()}
                            sx={{
                                whiteSpace: 'nowrap'
                            }}
                            control={(
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Adjust by Consumer Risk',
                                    }}
                                    name={FormField.AdjustMaxTotalAmountMonthByConsumerRisk}
                                    onChange={onCheckboxChange(FormField.AdjustMaxTotalAmountMonthByConsumerRisk)}
                                    onBlur={handleBlur}
                                    checked={Boolean(values[FormField.AdjustMaxTotalAmountMonthByConsumerRisk])}
                                />
                            )}
                            label='Adjust by Consumer Risk'
                        />
                    </InputGroup>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        alignContent: 'flex-start',
                        gap: 2
                    }}
                >
                    <InputGroup>
                        <TextField
                            {...withTextFieldProps({
                                placeholder: ''
                            }, { step: 1 })}
                            {...getFormInputProps(FormField.VelocitySec, props)}
                            name={FormField.VelocitySec}
                            value={values[FormField.VelocitySec]}
                            label='Velocity (Seconds)'
                        />
                        <FormControlLabel
                            {...withPaymentMethodIsAssignable()}
                            sx={{
                                whiteSpace: 'nowrap'
                            }}
                            control={(
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'Adjust by Consumer Risk',
                                    }}
                                    name={FormField.AdjustVelocityByConsumerRisk}
                                    onChange={onCheckboxChange(FormField.AdjustVelocityByConsumerRisk)}
                                    onBlur={handleBlur}
                                    checked={Boolean(values[FormField.AdjustVelocityByConsumerRisk])}
                                />
                            )}
                            label='Adjust by Consumer Risk'
                        />
                    </InputGroup>
                    <TextField
                        {...withTextFieldProps({
                            placeholder: ''
                        }, { step: 1 })}
                        {...getFormInputProps(FormField.EmailRiskLevel, props)}
                        name={FormField.EmailRiskLevel}
                        value={values[FormField.EmailRiskLevel]}
                        label='Email Risk Level'
                    />
                    <FormControlLabel
                        {...withPaymentMethodIsAssignable()}
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                        control={(
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Enable require user verification page',
                                }}
                                name={FormField.EnableUserVerificationPage}
                                onChange={onCheckboxChange(FormField.EnableUserVerificationPage)}
                                onBlur={handleBlur}
                                checked={Boolean(values[FormField.EnableUserVerificationPage])}
                            />
                        )}
                        label='Enable require user verification page'
                    />
                    <FormControlLabel
                        {...withPaymentMethodIsAssignable()}
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                        control={(
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Enable method limits',
                                }}
                                name={FormField.EnableMethodLimits}
                                onChange={onCheckboxChange(FormField.EnableMethodLimits)}
                                onBlur={handleBlur}
                                checked={Boolean(values[FormField.EnableMethodLimits])}
                            />
                        )}
                        label='Enable method limits'
                    />
                </Box>
            </Box>
            <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
                disabled={!isValid}
                sx={{
                    justifySelf: 'flex-end',
                    display: paymentMethodIsAssignable
                        ? 'block'
                        : 'none'
                }}
            >
                Save Changes
            </LoadingButton>
        </Box>
    );
};

export default memo(PaymentMethodLimits);
