import Chip from '@mui/material/Chip';
import Icon from 'ui/atoms/Icon';
import type { Status, ChipStatusProps } from './types';
import When from '../When';

export const withChipStatus = <T extends string>(statusMap: Status<T>) => ({
    label,
    ...props
}: ChipStatusProps) => {
    const key = label as T;
    const { icon = '', ...overrideProps } = statusMap.get(key) ?? {};

    return (
        <When
            if={statusMap.has(key)}
            then={(
                <Chip
                    size='small'
                    {...props}
                    {...overrideProps}
                    label={label}
                    icon={(
                        <Icon name={icon} />
                    )}
                />
            )}
        />
    );
};

export default withChipStatus;
