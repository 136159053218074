import { styled } from '@mui/material/styles';
import MuiButton, { type ButtonProps } from '@mui/material/Button';

const Button = styled(MuiButton)<ButtonProps>({
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
    textTransform: 'none',
    borderRadius: 8,
    border: '1px solid',
    paddingLeft: 13,
    paddingRight: 13

}) as typeof MuiButton;

export default Button;
