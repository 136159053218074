import { DomainRepository, type Dispatcher } from "hooks/useDomainState";
import type { UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import {
    MerchantDomainsActionType,
    MerchantDomainsRepositoryState,
    MerchantDomainsRepository,
    CreateMerchantBusinessPayload
} from "./types";
import { ApiState } from "infrastructure/api";
import type { PaginateResourceResponse, ResourceResponse } from "types";
import type { Merchant, MerchantBusiness, MerchantDomain } from "features/merchants/types";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/merchants";
import { fromPaginateResourceResponse } from "util/api";

export class Repository extends DomainRepository<
    MerchantDomainsRepositoryState,
    MerchantDomainsActionType
> implements MerchantDomainsRepository {
    public constructor(
        public readonly state: MerchantDomainsRepositoryState,
        protected readonly dispatch: Dispatcher<MerchantDomainsActionType>,
        private readonly getMerchantDomainsRequest: (requestQueryMapper: RequestQueryMapper) => Promise<PaginateResourceResponse<MerchantDomain>>,
        private readonly getMerchantBusinessesRequest: (merchant?: Partial<Merchant>) => Promise<PaginateResourceResponse<MerchantBusiness>>,
        private readonly getMerchantBusinessByIdRequest: (merchantBusinessId: MerchantBusiness['coreId']) => Promise<ResourceResponse<MerchantBusiness>>,
        private readonly updateOrCreateMerchantBusinessRequest: (
            body: Partial<MerchantBusiness>,
            requestSettings?: UseSimpleRequestReturnCallbackArg
        ) => Promise<ResourceResponse<MerchantBusiness>>,
        private readonly updateOrCreateMerchantDomainRequest: (
            body: Partial<MerchantDomain>,
            requestSettings?: UseSimpleRequestReturnCallbackArg
        ) => Promise<ResourceResponse<MerchantDomain>>,
        private readonly deleteMerchantBusinessRequest: (merchantBusinessId: MerchantBusiness['coreId']) => Promise<ResourceResponse<MerchantBusiness>>,
        private readonly deleteMerchantDomainRequest: (merchantDomainId: MerchantDomain['coreId']) => Promise<ResourceResponse<MerchantDomain>>
    ) {
        super(state, dispatch);
    }

    public get merchantDomains() {
        return Array.from(this.state.domains.values()).flat();
    }

    public get merchantBusinesses() {
        return this.state.businesses;
    }

    public get isMerchantDomainsLoading() {
        return [
            ApiState.Idle,
            ApiState.Pending
        ].includes(this.state.apiState);
    }

    public get isMerchantDomainsError() {
        return Object.is(
            this.state.apiState,
            ApiState.Failed
        );
    }

    public get isMerchantBusinessesEmpty() {
        return !this.state.businesses.length;
    }

    public getMerchantDomainsByBusinessId(merchantBusinessId: MerchantDomain['merchantBusinessId']) {
        return this.state.domains.get(merchantBusinessId) || [];
    }

    public getIsMerchantDomainsByBusinessIdEmpty(merchantBusinessId: MerchantDomain['merchantBusinessId']) {
        return !this.getMerchantDomainsByBusinessId(merchantBusinessId).length;
    }

    public async fetchMerchantDomains(merchant: Merchant): Promise<void> {
        if (this.shouldSendRequest(this.state.apiState)) {
            return;
        }

        this.dispatch({
            type: MerchantDomainsActionType.FetchMerchantDomains,
            payload: {
                apiState: ApiState.Pending
            }
        });


        const merchantBusinessesResponse = await this.getMerchantBusinessesRequest(merchant);
        const businesses = fromPaginateResourceResponse(merchantBusinessesResponse);

        let domains = new Map<MerchantDomain['merchantBusinessId'], Array<MerchantDomain>>();

        if (businesses.length) {
            const merchantDomainsResponse = await this.getMerchantDomainsRequest(
                fromPaginateResourceResponse(merchantBusinessesResponse)
                    .reduce((requestQueryMapper, { coreId }) => (
                        requestQueryMapper
                            .containsIn(Filters.merchantBusinessId, String(coreId))
                    ), RequestQueryMapper.from())
            );

            domains = fromPaginateResourceResponse(merchantDomainsResponse)
                .reduce((domains, domain) =>
                    domains.set(
                        domain.merchantBusinessId,
                        [...domains.get(domain.merchantBusinessId) || [], domain]
                    ), new Map<MerchantDomain['merchantBusinessId'], Array<MerchantDomain>>());
        }

        this.dispatch({
            type: MerchantDomainsActionType.FetchMerchantDomains,
            payload: {
                apiState: ApiState.Succeeded,
                domains,
                businesses
            }
        });
    }

    public fetchMerchantBusinesses(): Promise<PaginateResourceResponse<MerchantBusiness>> {
        return this.getMerchantBusinessesRequest();
    }

    public async fetchMerchantBusinessById(merchantBusinessId: MerchantBusiness["coreId"]): Promise<ResourceResponse<MerchantBusiness>> {
        const response = await this.getMerchantBusinessByIdRequest(merchantBusinessId);

        this.dispatch({
            type: MerchantDomainsActionType.UpdateMerchantBusiness,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }

    public async updateMerchantBusiness(
        merchantBusiness: Partial<MerchantBusiness>,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ): Promise<ResourceResponse<MerchantBusiness>> {
        const response = await this.updateOrCreateMerchantBusinessRequest(merchantBusiness, requestSettings);

        this.dispatch({
            type: MerchantDomainsActionType.UpdateMerchantBusiness,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }

    public async updateMerchantDomain(merchantDomain: Partial<MerchantDomain>): Promise<ResourceResponse<MerchantDomain>> {
        const response = await this.updateOrCreateMerchantDomainRequest(merchantDomain, { notifyOnSuccess: false });

        this.dispatch({
            type: MerchantDomainsActionType.UpdateMerchantDomain,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }

    public async createMerchantBusiness(merchantBusiness: CreateMerchantBusinessPayload): Promise<ResourceResponse<MerchantBusiness>> {
        const response = await this.updateOrCreateMerchantBusinessRequest(merchantBusiness);

        this.dispatch({
            type: MerchantDomainsActionType.CreateMerchantBusiness,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }

    public async createMerchantDomain(merchantDomain: Partial<MerchantDomain>): Promise<ResourceResponse<MerchantDomain>> {
        const response = await this.updateOrCreateMerchantDomainRequest(merchantDomain);

        this.dispatch({
            type: MerchantDomainsActionType.CreateMerchantDomain,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }

    public async deleteMerchantBusiness(merchantBusiness: MerchantBusiness): Promise<void> {
        await this.deleteMerchantBusinessRequest(merchantBusiness.coreId);

        this.dispatch({
            type: MerchantDomainsActionType.DeleteMerchantBusiness,
            payload: merchantBusiness
        });
    }

    public async deleteMerchantDomain(merchantDomain: MerchantDomain): Promise<void> {
        await this.deleteMerchantDomainRequest(merchantDomain.coreId);

        this.dispatch({
            type: MerchantDomainsActionType.DeleteMerchantDomain,
            payload: merchantDomain
        });
    }

    public reset(): void {
        this.dispatch({
            type: MerchantDomainsActionType.Reset,
            payload: undefined
        });
    }
}
