import { useState } from "react";
import type { IOptionableModel } from "util/option";
import { useModal } from "ui/atoms/Modal";
import type { IncrementTaxesFormValues } from "ui/forms/IncrementTaxes";
import type { WithCreateTaxableEntityInitialValues } from "./types";

export default function useCreateTaxableEntity({ withInitialValues = _ => _ }: WithCreateTaxableEntityInitialValues) {
    const [initialValues, setInitialValuesModel] = useState<IOptionableModel<IncrementTaxesFormValues>>();
    const { onOpen, onClose, ...restModalProps } = useModal();

    const handleOpen = (initialValues?: IOptionableModel<IncrementTaxesFormValues>) => {
        setInitialValuesModel(
            withInitialValues(initialValues)
        );
        onOpen();
    };

    const handleClose = () => {
        setInitialValuesModel(undefined);
        onClose();
    };

    return {
        ...restModalProps,
        initialValues,
        onOpen: handleOpen,
        onClose: handleClose
    };
};
