import { memo } from "react";
import { Email } from "consts/general";
import Link, { type LinkProps } from "ui/atoms/Link";

const SupportEmail = (props: LinkProps) => (
    <Link
        {...props}
        mailable
        href={Email.Support}
    >
        {Email.Support}
    </Link>
);

export default memo(SupportEmail);
