import { memo } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link, Navigate, useLocation } from "react-router-dom";
import { WebRoutes } from "consts/enpoints/web";
import When from "ui/molecules/When";
import { SignIn } from "ui/layouts/SignIn";
import { Verify2FA } from "ui/forms/Auth";
import useVerify2FA from "./useVerify2FA";
import ResendCode from "./ResendCode";

const Verify2FAComponent = () => {
    const { state } = useLocation();
    const { from, email }  = state ?? {};

    const { verify } = useVerify2FA();

    return (
        <When
            if={from}
            then={(
                <SignIn
                    title='2FA Verification'
                >
                    <Typography
                        sx={{
                            color: 'text.secondary'
                        }}
                    >
                        Enter the code which was sent to you at&nbsp;
                        <Box
                            component='span'
                            sx={{
                                display: 'inline',
                                color: 'text.primary',
                                fontWeight: 'bold'
                            }}
                        >
                            {email}
                        </Box>
                    </Typography>
                    <Verify2FA.Form
                        onSubmit={verify}
                        resendCodeSlot={(
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <ResendCode />
                            </Box>
                        )}
                        trustDeviceSlot={(
                            <Typography
                                sx={{
                                    color: 'text.secondary',
                                    mt: 2,
                                    fontSize: '.85rem'
                                }}
                            >
                                We won’t ask you for a 2FA again in the next 2 weeks on this device
                            </Typography>
                        )}
                    />
                    <Link
                        to={WebRoutes.Login.Index}
                    >
                        Back to login
                    </Link>
                </SignIn>
            )}
            else={(
                <Navigate
                    replace
                    to={WebRoutes.Login.Index}
                />
            )}
        />
    );
};

export default memo(Verify2FAComponent);
