import { memo, useState } from "react";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { TriggerableDialog } from "ui/molecules/Dialog";
import useAddMerchantPaymentMethod from "./useAddMerchantPaymentMethod";
import AddMerchantPaymentMethodFilters from "./AddMerchantPaymentMethodFilters";

export type AddMerchantPaymentMethodWithFiltersProps = LoadingButtonProps;

const AddMerchantPaymentMethodWithFilters = (buttonProps: AddMerchantPaymentMethodWithFiltersProps) => {
    const [isProcessing, setProcessing] = useState(false);

    const { addMerchantPaymentMethod, getFiltersState, onReset } = useAddMerchantPaymentMethod();

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    variant='contained'
                    color='primary'
                    {...buttonProps}
                    onClick={onClick}
                    loading={isProcessing}
                >
                    Add Merchant Method
                </LoadingButton>
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <Button
                    sx={{
                        width: '180px'
                    }}
                        onClick={()=> {
                            onReset()
                            onClose()
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color='primary'
                        loading={isProcessing}
                        disabled={getFiltersState().isSomeValueEmpty}
                        onClick={() => {
                            setProcessing(true);

                            addMerchantPaymentMethod()
                                .then(() => onClose())
                                .finally(() => {
                                    onReset()
                                    setProcessing(false)});
                        }}
                    >
                        Add Merchant Method
                    </LoadingButton>
                </>
            )}
            ModalProps={{
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto',
                            '& .MuiDialogContent-root': {
                                p: 0,
                                border: 'none'
                            }
                        }
                    }
                },
                titleSlot: 'Add Merchant Method'
            }}
        >
            <AddMerchantPaymentMethodFilters />
        </TriggerableDialog>
    );
};

export default memo(AddMerchantPaymentMethodWithFilters);
