import { useTypedSelector } from "hooks";
import { BooleanSwitchValue } from "consts/general";
import { selectPaymentMethodsData } from "features/paymentMethods/selectors";
import type { PaymentMethod } from "features/paymentMethods/types";
import { useFeatureFiltersContext } from "ui/widgets/Filters";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import type { MerchantPaymentMethodsRepository } from "../repository";
import useMerchantDetail from "../../useMerchantDetail";

export default function useAddMerchantPaymentMethod() {
    const paymentMethods = useTypedSelector(selectPaymentMethodsData);

    const { merchant } = useMerchantDetail();

    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();

    const { withFilters, getFiltersState, onReset } = useFeatureFiltersContext();

    const addMerchantPaymentMethod = () => {
        const [paymentMethod] = withFilters<PaymentMethod>(paymentMethods);

        return repository.createMerchantMethodPivot({
            methodId: paymentMethod.coreId,
            merchantId: merchant.coreId,
            MOR: null,
            enabled: BooleanSwitchValue.Off
        });
    };

    return {
        getFiltersState,
        addMerchantPaymentMethod,
        onReset
    };
};
