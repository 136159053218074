import { memo } from "react";
import DialogActions from '@mui/material/DialogActions';
import Button from "@mui/material/Button";
import type { PickersActionBarProps } from '@mui/x-date-pickers-v.6/PickersActionBar';

const CustomActionBar = ({ className, onClear }: PickersActionBarProps) => (
    <DialogActions
        className={className}
    >
        <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={onClear}
        >
            Clear
        </Button>
    </DialogActions>
);

export default memo(CustomActionBar);
