import { useCallback } from 'react';
import { useAppDispatch, useRequest, useTypedSelector } from "hooks";
import useExportTable from "hooks/useExportTable";
import type { CoreBankingEntity, ID, ResourceResponse, ThunkReturnType } from "types";
import {
    getMerchantPayoutCalculationsThunk,
    getMerchantPayoutFilesThunk,
    getMerchantPayoutFileTaxCalculationsThunk,
    getMerchantPayoutIncrementExchangeRatesThunk,
    getMerchantPayoutIncrementsThunk,
    getMerchantPayoutIncrementTaxesThunk,
    getMerchantPayoutSummaryFilesThunk,
    getTransactionsMerchantPayoutIncrementsPivotThunk,
    postMerchantPayoutIncrementExchangeRatesThunk,
    deleteMerchantPayoutIncrementExchangeRatesThunk,
    postMerchantPayoutFilesThunk,
    postMerchantPayoutSummaryFilesThunk,
    mergeMerchantPayoutSummaryFilesThunk,
    mergeMerchantPayoutFilesThunk,
    putMerchantPayoutIncrementsThunk,
    postMerchantPayoutIncrementTaxesThunk,
    deleteMerchantPayoutIncrementTaxesThunk,
    togglePayMerchantPayoutFilesThunk,
    deleteMerchantPayoutFilesThunk,
    deleteMerchantPayoutSummaryFilesThunk,
    getMerchantPayoutFilesByIdThunk,
    putMerchantPayoutFilesThunk,
    putMerchantPayoutIncrementTaxesThunk
} from "./thunks";
import type {
    CalculationsResponse,
    Files,
    FilesResponse,
    FileTaxCalculationsResponse,
    IncrementExchangeRates,
    IncrementExchangeRatesResponse,
    Increments,
    IncrementsPivotResponse,
    IncrementsResponse,
    IncrementTaxesResponse,
    SummaryFilesResponse,
    SummaryFiles,
    AbstractFileEntity,
    IncrementTaxes,
    TogglePayMerchantPayoutFilesRequest,
    FileResponse
} from "./types";
import {
    changeExportLoading,
    changeMultiFileMerge,
    changeSelectionModel,
    changeTab,
    updateEntityById
} from './slice';
import { MerchantPayoutsTabs, merchantPayoutTabValueMapping } from "consts/merchantPayouts";
import { ApiRouteTypes } from 'consts/enpoints/api';
import { selectMerchantPayoutsActiveTab } from "./selectors";
import UnknownTabMappingException from './exceptions';

const useActions = () => {
    const dispatch = useAppDispatch();

    const activeTab = useTypedSelector(selectMerchantPayoutsActiveTab);

    const getApiResourceType = (activeTab: MerchantPayoutsTabs) =>
        new Map<MerchantPayoutsTabs, ApiRouteTypes>([
            [MerchantPayoutsTabs.SummaryFiles, ApiRouteTypes.GetMerchantPayoutSummaryFiles],
            [MerchantPayoutsTabs.Files, ApiRouteTypes.GetMerchantPayoutFiles],
            [MerchantPayoutsTabs.Calculations, ApiRouteTypes.GetMerchantPayoutCalculations],
            [MerchantPayoutsTabs.Increments, ApiRouteTypes.GetMerchantPayoutIncrements],
            [MerchantPayoutsTabs.IncrementsPivot, ApiRouteTypes.GetTransactionsMerchantPayoutIncrementsPivot],
            [MerchantPayoutsTabs.FileTaxCalculations, ApiRouteTypes.GetMerchantPayoutFileTaxCalculations],
            [MerchantPayoutsTabs.IncrementsTaxCalculations, ApiRouteTypes.GetMerchantPayoutIncrementTaxes],
            [MerchantPayoutsTabs.IncrementExchangeRates, ApiRouteTypes.GetMerchantPayoutIncrementExchangeRates]
        ])
            .get(activeTab)!;


    const getMerchantPayoutSummaryFiles = useRequest<ThunkReturnType<SummaryFilesResponse>, string>({
        thunk: getMerchantPayoutSummaryFilesThunk
    });

    const deleteMerchantPayoutSummaryFiles = useRequest<ThunkReturnType<null>, ID>({
        thunk: deleteMerchantPayoutSummaryFilesThunk
    });

    const getMerchantPayoutFiles = useRequest<ThunkReturnType<FilesResponse>, string>({
        thunk: getMerchantPayoutFilesThunk
    });

    const getMerchantPayoutFilesById = useRequest<ThunkReturnType<FileResponse>, ID>({
        thunk: getMerchantPayoutFilesByIdThunk
    });

    const deleteMerchantPayoutFiles = useRequest<ThunkReturnType<null>, ID>({
        thunk: deleteMerchantPayoutFilesThunk
    });

    const getMerchantPayoutCalculations = useRequest<ThunkReturnType<CalculationsResponse>, string>({
        thunk: getMerchantPayoutCalculationsThunk
    });

    const getMerchantPayoutFileTaxCalculations = useRequest<ThunkReturnType<FileTaxCalculationsResponse>, string>({
        thunk: getMerchantPayoutFileTaxCalculationsThunk
    });

    const getTransactionsMerchantPayoutIncrementsPivot = useRequest<ThunkReturnType<IncrementsPivotResponse>, string>({
        thunk: getTransactionsMerchantPayoutIncrementsPivotThunk
    });

    const getMerchantPayoutIncrementTaxes = useRequest<ThunkReturnType<IncrementTaxesResponse>, string>({
        thunk: getMerchantPayoutIncrementTaxesThunk
    });

    const getMerchantPayoutIncrements = useRequest<ThunkReturnType<IncrementsResponse>, string>({
        thunk: getMerchantPayoutIncrementsThunk
    });

    const putMerchantPayoutIncrements = useRequest<ThunkReturnType<ResourceResponse<Increments>>, Partial<Increments>>({
        thunk: putMerchantPayoutIncrementsThunk
    });

    const togglePayMerchantPayoutFiles = useRequest<
        ThunkReturnType<ResourceResponse<AbstractFileEntity>>,
        TogglePayMerchantPayoutFilesRequest
    >({
        thunk: togglePayMerchantPayoutFilesThunk
    });

    const getMerchantPayoutIncrementExchangeRates = useRequest<ThunkReturnType<IncrementExchangeRatesResponse>, string>({
        thunk: getMerchantPayoutIncrementExchangeRatesThunk
    });

    const postMerchantPayoutIncrementExchangeRates = useRequest<
        ThunkReturnType<ResourceResponse<IncrementExchangeRates>>,
        Partial<IncrementExchangeRates>
    >({
        thunk: postMerchantPayoutIncrementExchangeRatesThunk
    });
    const postMerchantPayoutIncrementTax = useRequest<
        ThunkReturnType<IncrementTaxes>,
        Partial<IncrementTaxes>
    >({
        thunk: postMerchantPayoutIncrementTaxesThunk
    });

    const postMerchantPayoutFiles = useRequest<
        ThunkReturnType<ResourceResponse<Files>>,
        FormData
    >({
        thunk: postMerchantPayoutFilesThunk
    });

    const putMerchantPayoutFiles = useRequest<
        ThunkReturnType<ResourceResponse<Files>>,
        Partial<Files>
    >({
        thunk: putMerchantPayoutFilesThunk
    });

    const postMerchantPayoutSummaryFiles = useRequest<
        ThunkReturnType<ResourceResponse<SummaryFiles>>,
        FormData
    >({
        thunk: postMerchantPayoutSummaryFilesThunk
    });

    const mergeMerchantPayoutSummaryFiles = useRequest<
        ThunkReturnType<ResourceResponse<AbstractFileEntity>>,
        FormData
    >({
        thunk: mergeMerchantPayoutSummaryFilesThunk
    });

    const mergeMerchantPayoutFiles = useRequest<
        ThunkReturnType<ResourceResponse<AbstractFileEntity>>,
        FormData
    >({
        thunk: mergeMerchantPayoutFilesThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: getApiResourceType(activeTab)
    });

    const deleteMerchantPayoutIncrementExchangeRates = useRequest<
        ThunkReturnType<null>, ID
    >({
        thunk: deleteMerchantPayoutIncrementExchangeRatesThunk
    });

    const deleteMerchantPayoutIncrementTaxes = useRequest<
        ThunkReturnType<null>, ID
    >({
        thunk: deleteMerchantPayoutIncrementTaxesThunk
    });

    const putMerchantPayoutIncrementTaxes = useRequest<
        ThunkReturnType<ResourceResponse<IncrementTaxes>>,
        Partial<IncrementTaxes>
    >({
        thunk: putMerchantPayoutIncrementTaxesThunk
    });
    // const saveSearchParams = useCallback(() => {
    //     dispatch(saveTabSearchParams(window.location.search));
    // }, [dispatch]);

    const onChangeTab = useCallback((value: string) => {
        if (!merchantPayoutTabValueMapping.has(value)) {
            throw new UnknownTabMappingException(
                'Tab value does not satisfy valueTabMapping',
                {}
            );
        }

        dispatch(changeTab(merchantPayoutTabValueMapping.get(value)!));
    }, [dispatch]);


    const onChangeMultiFileMerge = useCallback((isEnabled: boolean) => {
        dispatch(changeMultiFileMerge(isEnabled));
    }, [dispatch]);

    const onSelectionModelChange = useCallback((selectionModel: Array<ID>) => {
        dispatch(changeSelectionModel(selectionModel));
    }, [dispatch]);

    const updateMerchantPayoutEntityById = useCallback(<T extends CoreBankingEntity>(entity: T) => {
        dispatch(updateEntityById(entity));
    }, [dispatch]);

    return {
        getMerchantPayoutSummaryFiles,
        getMerchantPayoutFiles,
        getMerchantPayoutFilesById,
        getMerchantPayoutCalculations,
        getMerchantPayoutFileTaxCalculations,
        getTransactionsMerchantPayoutIncrementsPivot,
        getMerchantPayoutIncrementTaxes,
        postMerchantPayoutIncrementTax,
        deleteMerchantPayoutIncrementTaxes,
        getMerchantPayoutIncrements,
        putMerchantPayoutIncrements,
        getMerchantPayoutIncrementExchangeRates,
        postMerchantPayoutIncrementExchangeRates,
        postMerchantPayoutFiles,
        deleteMerchantPayoutIncrementExchangeRates,
        downloadCsv,
        onChangeTab,
        onChangeMultiFileMerge,
        onSelectionModelChange,
        postMerchantPayoutSummaryFiles,
        mergeMerchantPayoutSummaryFiles,
        mergeMerchantPayoutFiles,
        togglePayMerchantPayoutFiles,
        deleteMerchantPayoutSummaryFiles,
        deleteMerchantPayoutFiles,
        updateMerchantPayoutEntityById,
        putMerchantPayoutFiles,
        putMerchantPayoutIncrementTaxes
    };
};

export default useActions;
