import { memo, type PropsWithChildren } from 'react';
import QueryBuilderProvider from "./QueryBuilderProvider";
import ConditionTextArea from 'ui/screens/QueryBuilder/ConditionTextArea';
import Typography from '@mui/material/Typography';

interface Condition {
    dropdown1: string;
    dropdown2: string;
    inputValue: string;
    condition: string;
}

interface Aggregation {
    dropdown1: string;
    dropdown2: string;
    aggregation: string;
}

interface QueryBuilderProps {
    updatedConditions: Condition[];
    updatedAggregations: Aggregation[];
    onConditionTextAreaChange: (value: string) => void;
}

const QueryBuilder = ({
    children,
    updatedConditions,
    updatedAggregations,
    onConditionTextAreaChange
}: PropsWithChildren<QueryBuilderProps>) => (
    <QueryBuilderProvider>
        <Typography>
            Resulting query
        </Typography>
        <ConditionTextArea
            updatedConditions={updatedConditions}
            updatedAggregations={updatedAggregations}
            onChange={onConditionTextAreaChange}
        />
        {children}
    </QueryBuilderProvider>
);

export default memo(QueryBuilder);