import { createSlice } from "@reduxjs/toolkit";
import {
    paymentMethodsRequestFulfilled,
    paymentMethodsRequestLoading,
    paymentMethodsRequestRejected,
    sortPaymentMethodsPayload,
    updateOrCreatePaymentMethodRequestFulfilled
} from "./reducers";
import initialState, { sliceToken } from "./state";
import { getPaymentMethodsThunk, updateOrCreatePaymentMethodThunk } from "./thunks";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        changePaymentMethodsLoadingState: (state, { payload }) => {
            state.paymentMethodsLoadingState = payload;
        },
        changePaymentMethodsSlice: (state, { payload }) => {
            state.paymentMethodsSlice = sortPaymentMethodsPayload(payload.data);
        }
    },
    extraReducers: builder =>
        builder
            // Get Payment Methods
            .addCase(getPaymentMethodsThunk.pending, paymentMethodsRequestLoading)
            .addCase(getPaymentMethodsThunk.fulfilled, paymentMethodsRequestFulfilled)
            .addCase(getPaymentMethodsThunk.rejected, paymentMethodsRequestRejected)
            // Update or create Payment Method
            .addCase(updateOrCreatePaymentMethodThunk.fulfilled, updateOrCreatePaymentMethodRequestFulfilled)
});

export const {
    name,
    actions: {
        changePaymentMethodsLoadingState,
        changePaymentMethodsSlice
    }
} = slice;
export default slice.reducer;
