import { useMemo } from "react";
import { object, string } from "yup";
import { FormField } from "./types";

export default function useValidationSchema() {
    return useMemo(() =>
        object().shape({
            [FormField.TwoFactorCode]: string()
                .required()
                .max(10)
                .label('2FA Code')
        })
        , []);
};
