import { useMemo } from "react";
import { CoreBankingEntity } from "types";
import { object, string } from "yup";

export const useValidationSchema = <T extends CoreBankingEntity>(fee: keyof T) =>
    useMemo(() =>
        object().shape({
            [fee]: string()
                .optional()
                .max(255)
        })
        , [fee]);
