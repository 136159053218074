
import { useEffect, useRef } from "react";
import { useBlocker, type BlockerFunction } from "react-router-dom";
import { useUnsavedChangesContext } from "ui/widgets/UnsavedChanges";

export default function useUnsavedChanges() {
    const unsavedChanges = useUnsavedChangesContext();
    const unsavedChangesRef = useRef(unsavedChanges);
    unsavedChangesRef.current = unsavedChanges;

    const blockerFunction: BlockerFunction = ({
        currentLocation,
        nextLocation
    }) => (
        unsavedChanges.hasUnsavedChanges() &&
        !Object.is(currentLocation.pathname, nextLocation.pathname)
    );

    const { state, proceed, reset } = useBlocker(blockerFunction);

    useEffect(() => {
        if (state === 'blocked') {
            unsavedChangesRef.current.checkUnsavedChanges({
                onConfirm: proceed,
                onCancel: reset
            });
        }
    }, [state, proceed, reset]);

    return unsavedChanges;
};
