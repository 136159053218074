import { memo } from "react";
import type { Status } from "types";
import withChipStatus from "./withChipStatus";
import type { ChipStatusProps } from "./types";

export default memo(withChipStatus<Status>(
    new Map<Status, ChipStatusProps<Status>>()
        .set('PENDING', {
            icon: 'HourglassEmpty',
            color: 'warning'
        })
        .set('FAILED', {
            icon: 'SmsFailed',
            color: 'error'
        })
        .set('REJECT', {
            icon: 'ThumbDownOffAlt',
            color: 'secondary'
        })
        .set('SUCCEEDED', {
            icon: 'CheckCircleOutline',
            color: 'success'
        })
        .set('REFUNDED', {
            icon: 'LocalAtm',
            color: 'primary'
        })
        .set('CHARGE_BACK', {
            icon: 'CurrencyExchange',
            color: 'info'
        })
));
