import { FilterRenderTypes, type Filter } from "ui/widgets/Filters/types";
import type { AutocompleteProps } from "ui/widgets/Filters/components";
import dropdownFilterStrategy from "./dropdown/filterStrategy";

export const filterStrategyRegistry = new Map<
    FilterRenderTypes,
    (
        filter: Filter<AutocompleteProps>,
        value: Record<string, unknown>
    ) => boolean
>([
    [FilterRenderTypes.Dropdown, dropdownFilterStrategy]
]);
