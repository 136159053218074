import { useState } from "react";
import { useContextPublisherContext } from "providers/ContextPublisher";
import HasUnsavedChangesException from "./HasUnsavedChangesException";
import type { CheckUnsavedChangesArg } from "./types";
import useSubscriber from "./useSubscriber";
import useConfirmationDialog from "./useConfirmationDialog";

export default function useUnsavedChanges() {
    const [resolvers, setResolvers] = useState<any>(null);

    const publisher = useContextPublisherContext();

    const resetResolvers = () => setResolvers(null);

    const checkUnsavedChanges = ({
        onConfirm,
        onCancel,
        shouldResetResolvers = true
    }: CheckUnsavedChangesArg = {}) => {
        try {
            publisher.notify();
        } catch (exception) {
            if (!(exception instanceof HasUnsavedChangesException)) {
                throw exception;
            }

            // TODO: remove any assertion in TypeScript 5 +
            const resolvers = (Promise as any).withResolvers();

            setResolvers(resolvers);

            return resolvers.promise
                .then(() => onConfirm?.(resetResolvers))
                .catch(() => onCancel?.(resetResolvers))
                .finally(() => (
                    shouldResetResolvers &&
                    resetResolvers()
                ));
        }

        return Promise.resolve();
    };

    const hasUnsavedChanges = () => {
        try {
            publisher.notify();
        } catch (exception) {
            if (exception instanceof HasUnsavedChangesException) {
                return true;
            }
        }

        return false;
    };

    hasUnsavedChanges.withResolvers = () => Boolean(resolvers);

    const confirmExit = () => resolvers.resolve();

    const cancelExit = () => resolvers.reject();

    return {
        ...useConfirmationDialog(),
        ...useSubscriber(),
        hasUnsavedChanges,
        resetResolvers,
        checkUnsavedChanges,
        confirmExit,
        cancelExit
    };
};
