import type { Tabs } from "features/merchantPayouts/types";
import { multiColumnFilterFactory } from "util/filters";
import { Filters as GeneralFilters } from 'consts/filters';

export const enum MerchantPayoutsTabs {
    SummaryFiles = 'SummaryFiles',
    Files = 'Files',
    Calculations = 'Calculations',
    Increments = 'Increments',
    IncrementsPivot = 'IncrementsPivot',
    FileTaxCalculations = 'FileTaxCalculations',
    IncrementsTaxCalculations = 'IncrementsTaxCalculations',
    IncrementExchangeRates = 'IncrementExchangeRates'
}

export const Filters = {
    coreId: 'coreId',
    fileKey: 'fileKey',
    via: 'via',
    MOR: 'MOR',
    merchantId: 'merchantId',
    merchantName: 'merchantName',
    currency: 'currency',
    rollingReserveRetainedPaidInFileId: 'rollingReserveRetainedPaidInFileId',
    PSP: 'PSP',
    calculationStatus: 'calculationStatus',
    incrementStatus: 'incrementStatus',
    methodId: 'methodId',
    merchantPayoutCalculationId: 'merchantPayoutCalculationId',
    idOfTransaction: 'idOfTransaction',
    initialTransactionId: 'initialTransactionId',
    merchantPayoutIncId: 'merchantPayoutIncId',
    merchantPayoutFileId: 'merchantPayoutFileId',
    payoutFileId: 'payoutFileId',
    taxName: 'taxName',
    tag: 'tag',
    dateFrom: 'dateFrom',
    dateTo: 'dateTo',
    transactionCurrency: 'transactionCurrency',
    currencyExchangeRate: 'currencyExchangeRate',
    payoutCurrency: 'payoutCurrency',
    isPaid: 'isPaid',
    refColFrom:"refColFrom",
    refColFromMinSum:"refColFromMinSum",
    currentIncMultiplierForEachRefColumn:"currentIncMultiplierForEachRefColumn",
    parentId: 'parentId',
    rollingReserveRetainedUntil: 'rollingReserveRetainedUntil'
};

export const text = [
    Filters.fileKey,
    Filters.rollingReserveRetainedPaidInFileId,
    Filters.merchantPayoutCalculationId,
    Filters.idOfTransaction,
    Filters.initialTransactionId,
    Filters.merchantPayoutIncId,
    Filters.merchantPayoutFileId,
    Filters.payoutFileId,
    Filters.parentId,
    Filters.taxName
];

export const single = [
    Filters.MOR,
    GeneralFilters.parentVisibility,
    Filters.isPaid,
];

export const multi = [
    Filters.merchantId,
    Filters.via,
    Filters.currency,
    Filters.PSP,
    Filters.calculationStatus,
    Filters.incrementStatus,
    Filters.methodId,
    Filters.transactionCurrency,
    Filters.payoutCurrency,
    Filters.tag,
];

export const date = [
    multiColumnFilterFactory([
        Filters.dateFrom,
        Filters.dateTo
    ])
];

export const merchantPayoutTabValueMapping = new Map<string, Tabs>([
    ['1', MerchantPayoutsTabs.SummaryFiles],
    ['2', MerchantPayoutsTabs.Files],
    ['3', MerchantPayoutsTabs.Calculations],
    ['4', MerchantPayoutsTabs.Increments],
    ['5', MerchantPayoutsTabs.IncrementsPivot],
    ['6', MerchantPayoutsTabs.FileTaxCalculations],
    ['7', MerchantPayoutsTabs.IncrementsTaxCalculations],
    ['8', MerchantPayoutsTabs.IncrementExchangeRates]
]);
