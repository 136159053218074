import { memo } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from 'lib/logo';
import Image from 'lib/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
import useSignIn from './useSignIn';
import type { SignInOutletProps } from './types';

// ----------------------------------------------------------------------

const SignInOutlet = ({ illustration, title }: SignInOutletProps) => {
    useSignIn();

    return (
        <StyledRoot>
            <Logo
                brandingImage
                sx={{
                    zIndex: 9,
                    position: 'absolute',
                    mt: { xs: 1.5, md: 5 },
                    ml: { xs: 2, md: 5 },
                }}
            />

            <StyledSection>
                <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
                    {title || 'Hi, Welcome back'}
                </Typography>

                <Image
                    disabledEffect
                    visibleByDefault
                    alt="auth"
                    src={illustration || '/assets/illustrations/illustration_dashboard.png'}
                    sx={{ maxWidth: 720 }}
                />

                <StyledSectionBg />
            </StyledSection>

            <StyledContent>
                <Stack
                    sx={{
                        width: 1,
                        justifyContent: 'space-between',
                        minHeight: 'inherit'
                    }}
                >
                    <Outlet />
                </Stack>
            </StyledContent>
        </StyledRoot>
    );
};

export default memo(SignInOutlet);
