import {
    getMerchantPayoutFilesSettlementXLS,
    getMerchantPayoutSummaryFilesTransactionCSV,
} from "features/merchantPayouts/api";
import useActions from "features/merchantPayouts/useActions";
import { ActionRequestHandlers, MenuActions } from "ui/organizms/MerchantPayouts/ActionMenu";

export default function useSummaryFilesActions() {
    const { deleteMerchantPayoutSummaryFiles } = useActions();
    const getMenuItems = () => [
        MenuActions.SettlementXLS,
        MenuActions.TransactionsCSV,
        MenuActions.Delete
       
    ];

    const getRequestMappings = () =>
        new Map<MenuActions, ActionRequestHandlers>([          
            [MenuActions.SettlementXLS, { menu: getMerchantPayoutFilesSettlementXLS }],
            [MenuActions.TransactionsCSV, { menu: getMerchantPayoutSummaryFilesTransactionCSV }],
            [MenuActions.Delete, { dialog: deleteMerchantPayoutSummaryFiles }],
        ]);

    return {
        getMenuItems,
        getRequestMappings
    };
};
