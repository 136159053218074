import { memo, type FC } from "react";
import Box from "@mui/material/Box";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { CurrencySymbol } from "consts/general";

type Props<TCurrency extends string> = Pick<
    MerchantsMethodsPivot,
    | 'minTicketSize'
    | 'maxTicketSize'
> & {
    readonly currency?: TCurrency;
};

const MinMaxTicketSize: FC<Props<CurrencySymbol>> = ({
    minTicketSize,
    maxTicketSize,
    currency
}) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            fontWeight: 600
        }}
    >
        {[minTicketSize, maxTicketSize]
            .map(value => (
                `${value}${currency}`
            ))
            .join(' - ')}
    </Box>
);

MinMaxTicketSize.defaultProps = {
    currency: CurrencySymbol.EUR
};

export default memo(MinMaxTicketSize);
