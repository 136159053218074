import { memo, type PropsWithChildren } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { useAppStore } from "hooks";
import { WebRoutes } from "consts/enpoints/web";
import { selectIsNotMor } from "features/auth/selectors";

const DashboardIndexRedirect = ({ children }: PropsWithChildren) => {
    const store = useAppStore();
    const { pathname } = useLocation();

    return (
        <>
            {children}
            {pathname.length <= 1 && (
                <Navigate
                    replace
                    to={selectIsNotMor(store.getState())
                        ? WebRoutes.Dashboard
                        : WebRoutes.TransactionList}
                />
            )}
        </>
    );
};

export default memo(DashboardIndexRedirect);
