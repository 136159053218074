import { memo } from "react";
import { DataGridPremium, type DataGridPremiumProps } from '@mui/x-data-grid-premium';
import useMerchantPaymentMethodHistory, { type UseMerchantPaymentMethodHistoryArg } from "./useMerchantPaymentMethodHistory";
import MerchantPaymentMethodProvider from "./MerchantPaymentMethodProvider";
import MerchantPaymentMethodHistoryPaginator from "./MerchantPaymentMethodHistoryPaginator";

type Props = UseMerchantPaymentMethodHistoryArg & {
    readonly DataGridPremiumProps?: Omit<DataGridPremiumProps, 'columns' | 'rows'>;
};

const MerchantPaymentMethodHistory = ({ forceRefetch, merchantMethod, DataGridPremiumProps }: Props) => (
    <MerchantPaymentMethodProvider
        merchantPaymentMethodPivot={merchantMethod}
    >
        <DataGridPremium
            {...DataGridPremiumProps}
            disableSelectionOnClick
            sx={{
                minWidth: 650,
                height: 450,
                ...DataGridPremiumProps?.sx
            }}
            {...useMerchantPaymentMethodHistory({
                merchantMethod,
                forceRefetch
            })}
            components={{
                Pagination: MerchantPaymentMethodHistoryPaginator
            }}
        />
    </MerchantPaymentMethodProvider>
);

export default memo(MerchantPaymentMethodHistory);
