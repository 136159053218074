import { memo } from "react";
import { lightBlue, red } from "@mui/material/colors";
import type { FundStatus } from "types";
import withChipStatus from "./withChipStatus";
import type { ChipStatusProps } from "./types";

export default memo(withChipStatus<FundStatus>(
    new Map<FundStatus, ChipStatusProps<FundStatus>>()
        .set('NOT_EXPECTED', {
            icon: 'NotInterested',
            color: 'primary',
            sx: {
                bgcolor: red[500]
            }
        })
        .set('WAITING', {
            icon: 'HourglassEmpty',
            color: 'warning'
        })
        .set('RECEIVED', {
            icon: 'KeyboardDoubleArrowDown',
            color: 'success'
        })
        .set('SENT', {
            icon: 'Outbox',
            color: 'info'
        })
        .set('MISSING', {
            icon: 'CallMissed',
            color: 'error'
        })
        .set('REFUNDED', {
            icon: 'LocalAtm',
            color: 'secondary'
        })
        .set('PARTIALLY_REFUNDED', {
            icon: 'AccountBalanceWallet',
            color: 'secondary'
        })
        .set('PARTIALLY_SENT', {
            icon: 'ForwardOutlined',
            color: 'info'
        })
        .set('PARTIALLY_SENT_REFUNDED', {
            icon: 'SwapHorizontalCircle',
            color: 'info',
            sx: {
                bgcolor: lightBlue[500]
            }
        })
        .set('PARTIALLY_REFUNDED_SENT', {
            icon: 'SwapVerticalCircle',
            color: 'info',
            sx: {
                bgcolor: lightBlue[500]
            }
        })
        .set('FULLY_SENT_REFUNDED', {
            icon: 'CompareArrows',
            color: 'info',
            sx: {
                bgcolor: lightBlue[500]
            }
        })
        .set('FULLY_REFUNDED', {
            icon: 'LocalAtm',
            color: 'secondary'
        })
        .set('FULLY_REFUNDED_SENT', {
            icon: 'Sync',
            color: 'info',
            sx: {
                bgcolor: lightBlue[500]
            }
        })
        .set('FULLY_SENT', {
            icon: 'Outbox',
            color: 'info'
        })
));
