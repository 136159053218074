import { Filters } from "consts/paymentMethods";
import { selectPaymentMethodsData } from "features/paymentMethods/selectors";
import type { PaymentMethod } from "features/paymentMethods/types";
import { useTypedSelector } from "hooks";
import { useFeatureFiltersContext, useFiltersModel } from "ui/widgets/Filters";
import { fromCoreBankingCollectionToOptions } from "util/transformers";

export default function useAddMerchantPaymentMethodModel() {
    const paymentMethods = useTypedSelector(selectPaymentMethodsData);

    const { withFilters } = useFeatureFiltersContext();

    const fromMerchantsMethods = fromCoreBankingCollectionToOptions<PaymentMethod>(
        withFilters(paymentMethods),
        ({ isAssignable }) => Boolean(isAssignable)
    );

    const selectTags = () => fromMerchantsMethods('tag');

    const selectPSPs = () => fromMerchantsMethods('PSP');

    return useFiltersModel({
        [Filters.tag]: selectTags(),
        PSPs: selectPSPs()
    });
};
