import { useEffect, useRef, useState } from "react";

export type UseCountdownArg = {
    readonly onChange?: (currentTimeout: number) => void;
    readonly pace?: number;
    readonly timeout?: number;
    readonly intervalMs?: number;
};

export default function useCountdown({
    onChange,
    pace = 1,
    timeout = 0,
    intervalMs = 1000
}: UseCountdownArg) {
    const [countdown, setCountdown] = useState(timeout);

    const helpers = {
        countdown,
        onChange
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval>;

        const reset = () => {
            clearInterval(intervalId);
            setCountdown(0);
        };

        if (timeout <= 0) {
            return reset;
        }

        setCountdown(timeout);

        intervalId = setInterval(() => {
            let { countdown, onChange } = helpersRef.current;

            if (countdown <= 0) {
                reset();
                return;
            }

            countdown -= pace;

            setCountdown(countdown);
            onChange?.(countdown);
        }, intervalMs);

        return reset;
    }, [timeout, intervalMs, pace]);

    return countdown;
};
