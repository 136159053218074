export const ACCESS_TOKEN_EXPIRY_THRESHOLD = 3;

export const RESEND_OTP_TIMEOUT_SEC = 60;

export const TRUST_DEVICE_TOKEN_EXPIRES_DAYS = 14;

export const enum UserRoles {
    Admin,
    Employee
};

export const enum UserRolesNames {
    Admin = 'Admin',
    Employee = 'Employee',
    RootAdmin = 'RootAdmin',
    Merchant = 'Merchant',
    Mors = 'mors',
    MerchantsAgent = 'MerchantsAgent',
    MorsAgent = 'MorsAgent'
};


export const enum Event {
    Redirect2FA = 'Event.Redirect2FA',
    GetUser = 'Event.GetUser'
};
