export const enum StorageEngines {
    LocalStorage = 'localStorage',
    SessionStorage = 'sessionStorage'
}

export default class Storage {
    private static serializer = JSON;
    private static engine: StorageEngines = StorageEngines.LocalStorage;

    public static setEngine(engine: StorageEngines): void {
        this.engine = engine;
    }

    public static setSerializer(serializer: JSON): void {
        this.serializer = serializer;
    }

    public static getItem<T>(key: string): T | null {
        const item = window[this.engine].getItem(key);

        if (item !== null) {
            try {
                return this.serializer.parse(item);
            }catch {
                return null;
            }
        }

        return item;
    }

    public static setItem<T>(key: string, value: T): void {
        window[this.engine].setItem(
            key,
            this.serializer.stringify(value)
        );
    }

    public static removeItem(key: string): void {
        window[this.engine].removeItem(key);
    }
}
