import { memo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ManagementApiResponseFactory } from "util/api";
import type { MerchantBusiness } from "features/merchants/types";
import { SwitchableAction } from "ui/molecules/Action";
import useMerchantDomains from "../../../useMerchantDomains";

const MerchantBusinessActivator = (merchantBusiness: MerchantBusiness) => {
    const { isEnabled, businessName } = merchantBusiness;

    const [isProcessing, setProcessing] = useState(false);

    const { toggleEnabledMerchantBusiness } = useMerchantDomains();

    const handleProcess = async (isEnabled: boolean) => {
        setProcessing(true);

        let response = ManagementApiResponseFactory.make<MerchantBusiness>();

        try {
            response = await toggleEnabledMerchantBusiness({
                ...merchantBusiness,
                isEnabled
            });
        } catch {
        } finally {
            setProcessing(false);
        }

        return response;
    };

    const renderDialogContent = (shouldActivate: boolean) => (
        <Box
            sx={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                fontWeight: 500
            }}
        >
            Are you sure you want to {shouldActivate ? 'activate' : 'deactivate'}&nbsp;
            "<Typography
                sx={{
                    fontWeight: 600,
                    display: 'inline',
                    textTransform: 'uppercase'
                }}
            >
                {businessName}
            </Typography>"&nbsp;?
        </Box>
    );

    return (
        <Box
            onClick={event => event.stopPropagation()}
        >
            <SwitchableAction
            undoableDialog={{
                handleProcess,
                i18n: [
                <Typography
                    sx={{
                    fontWeight: 600,
                    color: isEnabled ? 'success.main' : 'text.disabled'
                    }}
                >
                    {isEnabled ? 'Active' : 'Inactive'}
                </Typography>
                ],
                getDefaultChecked: () => Boolean(isEnabled),
                getDisabled: () => isProcessing
            }}
            LabeledSwitchProps={{
                FormControlLabelProps: {
                labelPlacement: 'start'
                },
                SwitchProps: {
                color: isEnabled
                    ? 'success'
                    : 'default'
                }
            }}
            ConfirmatableDialogProps={{
                isProcessing,
                checkPredicate: () => Boolean(isEnabled),
                content: [
                {
                    dialogContent: [
                    renderDialogContent(true)
                    ],
                    actionContent: 'Activate'
                },
                {
                    dialogContent: [
                    renderDialogContent(false)
                    ],
                    actionContent: 'Deactivate'
                }
                ],
                cancelActionContentSlot: 'Cancel'
            }}
            />
        </Box>
    );
};

export default memo(MerchantBusinessActivator);
