import type { BootstrapableForm } from "types";
import type { useForm } from "hooks";

export const enum MethodSpecInFieldType {
    Select = 'select'
};

export const enum FormField {
    SpecIn = 'method_spec_in_field',
    Option = 'method_spec_in_field_option',
    Values = 'method_spec_in_field_options',
    Type = 'method_spec_in_field_type',
    Description = 'method_spec_in_field_description'
};

export type SetupPaymentMethodSpecInParams = {
    readonly [FormField.SpecIn]: string | null,
    readonly [FormField.Option]: string | null,
    readonly [FormField.Values]: string,
    readonly [FormField.Type]: string | null,
    readonly [FormField.Description]: string
};

export type SetupPaymentMethodSpecInParamsForm =
    & BootstrapableForm<SetupPaymentMethodSpecInParams, {
        readonly [FormField.SpecIn]: Array<string>,
        readonly [FormField.Option]: Array<string>,
        readonly [FormField.Type]: Array<string>
    }>;

export type SetupPaymentMethodBasicInfoProps =
    & SetupPaymentMethodSpecInParamsForm
    & ReturnType<typeof useForm<SetupPaymentMethodSpecInParams>>;
