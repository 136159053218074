import { createContext, memo, type PropsWithChildren } from "react";
import type { PaymentMethodsContext as PaymentMethodsContextType } from "./types";
import usePaymentMethods from './usePaymentMethods';

export const PaymentMethodsContext = createContext<PaymentMethodsContextType>({} as PaymentMethodsContextType);

const PaymentMethodsProvider = ({ children }: PropsWithChildren) => (
    <PaymentMethodsContext.Provider
        value={usePaymentMethods()}
    >
        {children}
    </PaymentMethodsContext.Provider>
);

export default memo(PaymentMethodsProvider);
