import type { DomainAction } from "types";
import type { MerchantRepository, MerchantSettingsRepository } from "../../repository";
import type { IDomainRepository } from "hooks";
import type { MerchantSettings } from "features/merchants/types";
import type { MerchantAccountApiSettings } from "ui/forms/Merchant/AccountApiSettings";

export const enum MerchantAccountSettingsActionType {
    Reset = 'MerchantAccountSettingsActionType:Reset'
};

export type MerchantAccountSettingsRepositoryState = {};

export type MerchantSettingsActionPayload = Partial<
    MerchantSettings
>;

export type MerchantAccountSettingsRepositoryAction =
    | DomainAction<
        MerchantAccountSettingsActionType.Reset,
        undefined
    >;

export interface MerchantAccountSettingsRepository extends MerchantSettingsRepository, MerchantRepository, IDomainRepository {
    readonly state: MerchantAccountSettingsRepositoryState;
    readonly accountApiSettings: MerchantAccountApiSettings;
}
