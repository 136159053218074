import type { FundStatus as FundStatusType } from "types";
import { TransactionFundStatus as Status } from "ui/molecules/Status";
import withGridCellParams from "./withGridCellParams";

const FundStatus = withGridCellParams<FundStatusType>(({ value }) => (
    <Status
        label={value}
    />
));

export default FundStatus;
