import { useContextPublisherContext } from "providers/ContextPublisher";
import HasUnsavedChangesException from "./HasUnsavedChangesException";
import type { UnsavedChangesSubscriber, UnsavedChangesSubscriberKey } from "./types";

export default function useSubscriber() {
    const publisher = useContextPublisherContext();

    const addUnsavedChangesSubscriber = ([
        unsavedChangesSubscriberKey,
        unsavedChangesSubscriber
    ]: [UnsavedChangesSubscriberKey, UnsavedChangesSubscriber]) =>
        publisher.addSubscriber([
            unsavedChangesSubscriberKey,
            () => {
                if (unsavedChangesSubscriber()) {
                    throw new HasUnsavedChangesException('There are unsaved changes', {});
                }
            }]);

    const removeUnsavedChangesSubscriber = (unsavedChangesSubscriberKey: UnsavedChangesSubscriberKey) =>
        publisher.removeSubscriber(unsavedChangesSubscriberKey);

    return {
        addUnsavedChangesSubscriber,
        removeUnsavedChangesSubscriber
    };
};
