import { useMemo } from "react";
import { object, string } from "yup";
import { FormField, MethodSpecInFieldType } from "./types";

export const specInFieldValuesRequired = (value: string | null) => Object.is(
    value,
    MethodSpecInFieldType.Select
);

export default function useValidationSchema() {
    return useMemo(() =>
        object().shape({
            [FormField.SpecIn]: string()
                .required()
                .max(264)
                .label('Spec-In'),

            [FormField.Option]: string()
                .required()
                .label('Option'),

            [FormField.Values]: string()
                .when(FormField.Type, {
                    is: specInFieldValuesRequired,
                    then: string().required()
                })
                .max(1500)
                .test('is-valid-json', 'Invalid JSON', value => {
                    if (!value) {
                        return true;
                    }

                    try {
                        JSON.parse(value);
                        return true;
                    } catch {
                        return false;
                    }
                })
                .label('Values'),

            [FormField.Type]: string()
                .required()
                .label('Type'),

            [FormField.Description]: string()
                .required()
                .max(1500)
                .label('Description')
        })
        , []);
};
