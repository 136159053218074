import { memo } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { DateRangePickerToolbar, type DateRangePickerToolbarProps } from "@mui/x-date-pickers-pro-v.6/DateRangePicker";
import type { PickersActionBarProps } from '@mui/x-date-pickers-v.6/PickersActionBar';
import type { Moment } from "moment";

type Props =
    & DateRangePickerToolbarProps<Moment>
    & Pick<PickersActionBarProps, 'onCancel'>;

const CustomToolbar = ({ onCancel, ...restProps }: Props) => (
    <Box
        className={restProps.className}
        sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
        }}
    >
        <DateRangePickerToolbar
            {...restProps}
        />
        <IconButton
            onClick={onCancel}
            size='small'
            sx={{
                m: 2
            }}
        >
            <CloseIcon />
        </IconButton>
    </Box>
);

export default memo(CustomToolbar);
