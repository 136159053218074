import Chip, { type ChipProps } from "@mui/material/Chip";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import type { ID } from "types";
import { withGridCellParams } from "ui/widgets/Table/renderers";

const State = withGridCellParams<ID>(({ value }) => {
    const getProps = (): ChipProps => {
        if (Number(value)) {
            return {
                size: 'small',
                color: 'success',
                label: (
                    <Box
                        fontWeight='bold'
                    >
                        Enabled
                    </Box>
                ),
                icon: (
                    <CheckIcon />
                )
            };
        }

        return {
            size: 'small',
            color: 'error',
            label: (
                <Box
                    fontWeight='bold'
                >
                    Disabled
                </Box>
            ),
            icon: (
                <CloseIcon />
            )
        };
    };

    return (
        <Chip
            {...getProps()}
        />
    );
});

export default State;