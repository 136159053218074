import { memo, type SyntheticEvent } from "react";
import { useTypedSelector } from "hooks";
import { getFormInputProps } from "util/forms";
import { selectCountries } from "features/general/selectors";
import type { Country } from "features/general/types";
import CountrySelector from "ui/widgets/CountrySelector";
import { FormField, type SetupPaymentMethodBasicInfoProps } from "./types";

type Props = Pick<
    SetupPaymentMethodBasicInfoProps,
    | 'errors'
    | 'touched'
> & {
    readonly value: Country['iso2'] | Array<Country['iso2']>;
    readonly onChange: (event: SyntheticEvent, values: Array<Country['iso2']>) => void;
};

const CountryIso2Adapter = ({
    value,
    onChange,
    ...restProps
}: Props) => {
    const options = useTypedSelector(selectCountries());

    const countriesIso2 = () => (
        options.filter((option: Country) => (
            value.includes(option.iso2)
        ))
    );

    const getOptionLabel = ({ iso2 }: Country) => iso2;

    const handleChange = (event: SyntheticEvent, countries: Country | Array<Country>) =>
        onChange(event, [countries]
            .flat()
            .map(({ iso2 }) => iso2)
        );

    return (
        <CountrySelector
            fullWidth
            multiple
            size='small'
            value={countriesIso2()}
            getOptionLabel={getOptionLabel}
            OptionProps={{
                mode: 'iso2'
            }}
            TextFieldProps={{
                ...getFormInputProps(FormField.ForCountries, restProps),
                required: true,
                name: FormField.ForCountries,
                label: 'Countries Available'
            }}
            onChange={handleChange}
        />
    );
};

export default memo(CountryIso2Adapter);
