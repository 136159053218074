import {
    memo,
    useState,
    type ComponentType,
    type PropsWithChildren,
    type ReactNode
} from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import type { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import EditIcon from '@mui/icons-material/Edit';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { TextFieldLoadable, type TextFieldLoadableProps } from "ui/atoms/TextField";

export type DeleteAction = {
    readonly variant: 'delete';
    readonly onDelete: () => Promise<unknown>;
};

export type EditAction = {
    readonly variant: 'edit';
    readonly onSave: (value?: string) => Promise<unknown>;
};

export type Action =
    | DeleteAction
    | EditAction;

type Props<TTextField extends TextFieldLoadableProps> =
    & BoxProps
    & Action
    & {
        readonly TextField?: ComponentType<MuiTextFieldProps & TTextField>;
        readonly EditActionProps?: LoadingButtonProps;
        readonly DeleteActionProps?: LoadingButtonProps;
        readonly SaveActionProps?: LoadingButtonProps;
        readonly TextFieldProps?: MuiTextFieldProps & TTextField;
        readonly textFieldSlot?: ReactNode;
    };

const Editable = <TTextField extends TextFieldLoadableProps>({
    children,
    sx,
    textFieldSlot,
    TextFieldProps,
    EditActionProps,
    DeleteActionProps,
    SaveActionProps,
    TextField = TextFieldLoadable,
    ...restProps
}: PropsWithChildren<Props<TTextField>>) => {
    const [isEditing, setEditing] = useState(false);

    const [isSaving, setSaving] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const getActionByVariant = () => {
        if (restProps.variant === 'delete') {
            return restProps.onDelete;
        }

        return restProps.onSave;
    };

    const getTextFieldProps = () => ({
        ...TextFieldProps,
    } as MuiTextFieldProps & TTextField);

    const handleSave = () => {
        setSaving(true);

        return getActionByVariant()(`${getTextFieldProps().value}`)
            .then(() => setEditing(false))
            .finally(() => setSaving(false));
    };

    const handleDelete = () => {
        setDeleting(true);

        return getActionByVariant()()
            .then(() => setEditing(false))
            .finally(() => setDeleting(false));
    };

    const handleEdit = () => setEditing(true);

    const renderVariantAction = () => {
        if (restProps.variant === 'delete') {
            return (
                <>
                    {/* Here add dialog to handle confirmation */}
                    <LoadingButton
                        size='small'
                        color='error'
                        variant='contained'
                        startIcon={(
                            <DeleteForeverIcon />
                        )}
                        {...DeleteActionProps}
                        loading={isDeleting}
                        onClick={handleDelete}
                    >
                        Delete
                    </LoadingButton>
                </>
            );
        }

        return (
            <LoadingButton
                size='small'
                color='primary'
                variant='contained'
                startIcon={(
                    <EditIcon />
                )}
                {...EditActionProps}
                onClick={handleEdit}
            >
                Edit
            </LoadingButton>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                ...sx
            }}
        >
            {children}
            {textFieldSlot ?? (
                <TextField
                    {...getTextFieldProps()}
                    disabled={!isEditing || getTextFieldProps().loading}
                    InputProps={{
                        ...getTextFieldProps().InputProps,
                        endAdornment: (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                {getTextFieldProps().InputProps?.endAdornment}
                                {isEditing
                                    ? (
                                        <LoadingButton
                                            size='small'
                                            color='warning'
                                            variant='contained'
                                            startIcon={(
                                                <SaveAsIcon />
                                            )}
                                            {...SaveActionProps}
                                            loading={isSaving}
                                            onClick={handleSave}
                                        >
                                            Save
                                        </LoadingButton>
                                    )
                                    : renderVariantAction()}
                            </Box>
                        )
                    }}
                />
            )}
        </Box>
    );
};

export default memo(Editable);
