import { compose } from "@reduxjs/toolkit";
import useSearchCriteriaFilter from "./useSearchCriteriaFilter";

export default function usePaymentMethodFilter() {
    const { filter, ...restSearchCriteriaFilter } = useSearchCriteriaFilter();

    return {
        ...restSearchCriteriaFilter,
        filter: compose(filter)
    };
};
