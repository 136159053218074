import { memo } from "react";
import { compareIds } from "util/support";
import type { PaymentMethod as PaymentMethodType } from "features/paymentMethods/types";
import { useAccordion } from "ui/atoms/Accordion";
import { Collection } from "ui/molecules/Resource";
import { NoDataOverlay } from "ui/molecules/TableGrids";
import ResourceLayout from "ui/layouts/Resource";
import { useBootUnsavedChanges } from "ui/widgets/UnsavedChanges";
import PaymentMethodsToolbar from "./PaymentMethodsToolbar";
import PaymentMethodProvider from "./PaymentMethodsProvider";
import PaymentMethod from "./PaymentMethod";
import { usePaymentMethodsContext } from "./PaymentMethodsProvider";

const PaymentMethods = () => {
    const { getPaymentMethods, isPaymentMethodsLoading } = usePaymentMethodsContext();
    const { expanded, handleChange } = useAccordion();

    useBootUnsavedChanges({
        cancelActionContentSlot: 'Stay on page',
        content: {
            dialogContent: [
                `The method hasn't been saved. Do you want to discard your action(s)?`
            ],
            actionContent: 'Discard'
        }
    });

    const paymentMethods = getPaymentMethods();

    return (
        <ResourceLayout
            breadcrumbsProps={{
                heading: 'Payment Method Setup',
                links: [{ name: '' }],
                action: (
                    <PaymentMethodsToolbar />
                )
            }}
        >
            <Collection
                isLoading={isPaymentMethodsLoading}
                isEmpty={!paymentMethods.length}
                emptySlot={(
                    <NoDataOverlay
                        sx={{
                            mt: 10
                        }}
                    >
                        No payment methods available for your search criteria
                    </NoDataOverlay>
                )}
                collection={paymentMethods}
                renderResource={(paymentMethod: PaymentMethodType) => (
                    <PaymentMethod
                        key={paymentMethod.coreId}
                        expanded={compareIds(String(expanded), paymentMethod.coreId)}
                        onChange={handleChange(paymentMethod.coreId)}
                        useCaseDiscriminationKey='edit'
                        paymentMethod={paymentMethod}
                        addExtras={(paymentMethod?: Partial<PaymentMethodType>) => ({
                            coreId: paymentMethod?.coreId
                        })}
                    />
                )}
            />
        </ResourceLayout>
    );
};

export default memo(() => (
    <PaymentMethodProvider>
        <PaymentMethods />
    </PaymentMethodProvider>
));
