import { memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SortOrder } from "consts/request-query";
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import { Collection, Resource } from "ui/molecules/Resource";
import { useCreateTaxableEntityContext } from "ui/organizms/MerchantPayouts";
import Card from "ui/atoms/Card";
import { IncrementTaxesModel } from "ui/forms/IncrementTaxes";
import type { Sortable, WithPrepopulatedFormValues } from "./types";
import useMerchantFeesAndCommisions from "../useMerchantFeesAndCommisions";
import Fees, { type FeesProps } from "./Fees";
import { AddMerchantFeeAction } from "../AddMerchantFee";

type Props = Sortable<
    WithPrepopulatedFormValues<{
        readonly incrementTaxes: Array<MerchantPayoutIncrementTaxes>;
    }>
>;

const FeesByTaxNameGroup = ({
    taxName,
    tag,
    incrementTaxes,
    sortOrder
}: PropsWithChildren<Props>) => {
    const { onOpen } = useCreateTaxableEntityContext();

    const { groupIncrementTaxesBy } = useMerchantFeesAndCommisions();

    const fees = Object.entries<MerchantPayoutIncrementTaxes[]>(groupIncrementTaxesBy({
        groupByKey: 'refColFromMinSum',
        incrementTaxes
    }))
        .reduce((acc, [refColFromMinSum, incrementTaxes]) => {
            const refColFromMinSumNumber = Number(refColFromMinSum);
            const prev = acc.at(-1);

            if (prev) {
                prev.end = refColFromMinSumNumber - 1;
            }

            return [
                ...acc,
                {
                    start: refColFromMinSumNumber,
                    end: Number.POSITIVE_INFINITY,
                    incrementTaxes: incrementTaxes.sort((a, b) => (
                        Object.is(sortOrder, SortOrder.Asc)
                            ? a.refColFromMinSum - b.refColFromMinSum
                            : b.refColFromMinSum - a.refColFromMinSum
                    ))
                }
            ];
        }, Array.of<FeesProps>());

    return (
        <Resource
            isEmpty={!fees.length}
            emptySlot={<></>}
        >
            <Box
                sx={{
                    display: 'grid',
                    gap: 2
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    {taxName}
                    <AddMerchantFeeAction
                        onClick={() => onOpen(
                            IncrementTaxesModel.init()
                                .initSingleOptionValues({ tag, taxName })
                        )}
                    >
                        Add Fees
                    </AddMerchantFeeAction>
                </Typography>
                <Collection
                    component={Card}
                    sx={{
                        p: 2,
                        display: 'grid',
                        gap: 2
                    }}
                    collection={fees}
                    renderResource={(props, index) => (
                        <Fees
                            {...props}
                            key={String(index)}
                        />
                    )}
                />
            </Box>
        </Resource>
    );
};

export default memo(FeesByTaxNameGroup);
