import { memo } from "react";
import type { MerchantBusiness as MerchantBusinessType } from "features/merchants/types";
import Accordion, { type AccordionProps } from "ui/atoms/Accordion";
import MerchantBusinessSummary from "./MerchantBusinessSummary";
import MerchantBusinessDetail from "./MerchantBusinessDetail";
import { AddMerchantDomain } from "ui/forms/Merchant/Domains";
import useMerchantDomains from "../useMerchantDomains";

type Props = Pick<
    AccordionProps,
    | 'expanded'
    | 'onChange'
> & {
    readonly merchantBusiness: MerchantBusinessType;
};

const MerchantBusiness = (props: Props) => {
    const { createMerchantDomain } = useMerchantDomains();

    const { merchantBusiness, ...accordionProps } = props;

    return (
        <Accordion
            {...accordionProps}
            AccordionSummaryProps={{
                children: (
                    <MerchantBusinessSummary
                        {...props}
                    />
                ),
                'aria-controls': `${merchantBusiness.businessName} content`,
                id: `${merchantBusiness.businessName} summary`
            }}
            AccordionActionsProps={{
                children: (
                    <AddMerchantDomain
                        businessName={merchantBusiness.businessName}
                        onSaveOrCreate={merchantDomain => createMerchantDomain({
                            ...merchantDomain,
                            merchantBusinessId: merchantBusiness.coreId
                        })}
                    />
                )
            }}
        >
            <MerchantBusinessDetail
                {...merchantBusiness}
            />
        </Accordion>
    );
};

export default memo(MerchantBusiness);
