import { memo } from "react";
import type { PaymentMethod } from "features/paymentMethods/types";
import Label from "./Label";
import { Box, Tooltip } from "@mui/material";

const PaymentMethodCountries = ({ forCountries }: PaymentMethod) => (
    <Label
        title='Countries'
    >
         <Tooltip title={forCountries}>
       <Box
            sx={{
                maxWidth: '100px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                cursor: 'pointer'
            }}
        >
            {forCountries}
        </Box>
        </Tooltip>
    </Label>
);

export default memo(PaymentMethodCountries);