import { ApiRoutes } from "consts/enpoints/api";
import { createOrUpdateResource, fetchApi } from "infrastructure/api";
import type { PaymentMethod } from "./types";

export const getPaymentMethods = (queryParams = '') =>
    fetchApi(`${ApiRoutes.GetPaymentMethods}${queryParams}`);

export const updateOrCreatePaymentMethod = (body: Partial<PaymentMethod>) =>
    createOrUpdateResource({
        body,
        getRoute: ApiRoutes.PostPaymentMethods,
        getId: body => body.coreId
    });
