import { forwardRef, type ForwardRefRenderFunction } from "react";
import MuiAutocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import InputLabel from "ui/atoms/InputLabel";
import type { AutocompleteLayoutProps, AutocompleteProps } from "./types";

const Autocomplete: ForwardRefRenderFunction<any, AutocompleteProps> = ({
    loading,
    label,
    TextFieldProps = {},
    FormControlProps = {},
    layout: LayoutComponent = Layout,
    ...props
}, ref) => {
    const component = (
        <MuiAutocomplete
            ref={ref}
            size='small'
            {...props}
            loading={loading}
            renderInput={params => (
                <TextField
                    {...TextFieldProps}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        ...({
                            endAdornment: (
                                <>
                                    {loading
                                        ? (
                                            <CircularProgress
                                                color='primary'
                                                size={20}
                                            />
                                        )
                                        : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                            ...TextFieldProps?.InputProps
                        })
                    }}
                />
            )}
        />
    );

    return LayoutComponent
        ? (
            <LayoutComponent
                label={label}
                {...FormControlProps}
            >
                {component}
            </LayoutComponent>
        )
        : component;
};

export default forwardRef(Autocomplete);

function Layout({ label, children, ...props }: AutocompleteLayoutProps) {
    return (
        <FormControl
            variant='standard'
            {...props}
        >
            <InputLabel>
                {label}
            </InputLabel>
            {children}
        </FormControl>
    );
}
