import { ApiState } from "infrastructure/api";
import type { CoreBankingEntity } from "types";
import { DomainRepository, type Dispatcher } from "hooks/useDomainState";
import type { UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import type {
    IncrementTaxes as MerchantPayoutIncrementTaxes,
    IncrementTaxesResponse as MerchantPayoutIncrementTaxesResponse
} from "features/merchantPayouts/types";
import {
    MerchantFeesAndCommisionsActionType,
    type MerchantFeesAndCommisionsRepositoryState,
    type MerchantFeesAndCommisionsRepository
} from "./types";
import type {
    MerchantSettingsActionPayload,
    MerchantSettingsRepository
} from "../../repository";

export class Repository extends DomainRepository<
    MerchantFeesAndCommisionsRepositoryState,
    MerchantFeesAndCommisionsActionType
> implements MerchantFeesAndCommisionsRepository {
    public constructor(
        public readonly state: MerchantFeesAndCommisionsRepositoryState,
        protected readonly dispatch: Dispatcher<MerchantFeesAndCommisionsActionType>,
        private readonly merchantRepository: MerchantSettingsRepository,
        private readonly getMerchantPayoutIncrementTaxesRequest: () => Promise<MerchantPayoutIncrementTaxesResponse>
    ) {
        super(state, dispatch);
    }

    public get merchantSettings() {
        return this.merchantRepository.merchantSettings;
    }

    public get isMerchantSettingsLoading() {
        return (
            this.isMerchantSettingsUninitialized ||
            this.merchantRepository.isMerchantSettingsLoading
        );
    }

    public get isMerchantSettingsUninitialized() {
        return this.merchantRepository.isMerchantSettingsUninitialized;
    }

    public get isMerchantSettingsSucceeded() {
        return this.merchantRepository.isMerchantSettingsSucceeded;
    }

    public get generalFees() {
        const {
            chargebackUnitFee,
            chargebacksNoThresholdPercent,
            payoutTransFee,
            payoutTransAmountThreshold,
            rollingReserveHoldDays
        } = this.merchantSettings ?? {};

        return {
            chargebackUnitFee,
            chargebacksNoThresholdPercent,
            payoutTransFee,
            payoutTransAmountThreshold,
            rollingReserveHoldDays
        };
    }

    public get merchantPayoutIncrementTaxes() {
        return this.state.data?.data ?? [];
    }

    public async fetchMerchantFeesAndCommisions(): Promise<void> {
        await Promise.allSettled([
            this.fetchMerchantSettings(),
            this._fetchMerchantPayoutIncrementTaxes()
        ]);
    }

    public async fetchMerchantSettings(): Promise<void> {
        return this.merchantRepository.fetchMerchantSettings();
    }

    public addMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxes): void {
        this.dispatch({
            type: MerchantFeesAndCommisionsActionType.AddMerchantPayoutIncrementTaxes,
            payload: merchantPayoutIncrementTaxes
        });
    }

    public updateMerchantSettings(
        merchantSettings: MerchantSettingsActionPayload,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ) {
        return this.merchantRepository.updateMerchantSettings(
            merchantSettings,
            requestSettings
        );
    }

    public updateMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxes): void {
        this.dispatch({
            type: MerchantFeesAndCommisionsActionType.UpdateMerchantPayoutIncrementTaxes,
            payload: merchantPayoutIncrementTaxes
        });
    }

    public deleteMerchantPayoutIncrementTaxes<T extends CoreBankingEntity>(merchantPayoutIncrementTaxes: T): void {
        this.dispatch({
            type: MerchantFeesAndCommisionsActionType.DeleteMerchantPayoutIncrementTaxes,
            payload: merchantPayoutIncrementTaxes
        });
    }

    public async fetchMerchantPayoutIncrementTaxes() {
        const merchantPayoutIncrementTaxesResponse = await this.getMerchantPayoutIncrementTaxesRequest();

        this.dispatch({
            type: MerchantFeesAndCommisionsActionType.FetchMerchantPayoutIncrementTaxes,
            payload: {
                apiState: ApiState.Succeeded,
                data: merchantPayoutIncrementTaxesResponse.data
            }
        });
    }

    public reset(): void {
        this.dispatch({
            type: MerchantFeesAndCommisionsActionType.Reset,
            payload: undefined
        });
    }

    private async _fetchMerchantPayoutIncrementTaxes(): Promise<void> {
        if (this.shouldSendRequest(this.state.apiState)) {
            return;
        }

        this.dispatch({
            type: MerchantFeesAndCommisionsActionType.FetchMerchantPayoutIncrementTaxes,
            payload: {
                apiState: ApiState.Pending
            }
        });

        this.fetchMerchantPayoutIncrementTaxes();
    }
}
