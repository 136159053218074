import { BooleanStatus as Status } from "ui/molecules/Status";
import withGridCellParams from "./withGridCellParams";

const BooleanStatus = withGridCellParams<boolean>(({ value = false }) => (
    <Status
        label={value}
    />
));

export default BooleanStatus;
