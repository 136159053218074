import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList, { type TabListProps } from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import type { TabsProps } from './types';

const Tabs = ({
    model,
    onChange,
    value = '1',
    ContainerProps = {},
    TabListContainerProps = {},
    TabListProps = {},
    TabProps = {},
    TabPanelProps = {}
}: TabsProps) => {
    const [activeTab, setActiveTab] = useState(value);

    const handleChange: TabListProps['onChange'] = async (_, value) => {
        try {
            await onChange?.(value, setActiveTab);
            setActiveTab(value);
        }catch {}
    };

    return (
        <Box
            {...ContainerProps}
        >
            <TabContext value={activeTab}>
                <Box
                    {...TabListContainerProps}
                >
                    <TabList
                        {...TabListProps}
                        onChange={handleChange}
                    >
                        {model.map(({ label, key }, value) => (
                            <Tab
                                {...TabProps}
                                key={key ?? String(label)}
                                label={label}
                                value={String(value + 1)}
                            />
                        ))}
                    </TabList>
                </Box>
                {model.map(({ label, component, key, sxProps }, tabIndex) => (
                    <TabPanel
                    sx={sxProps}
                        {...TabPanelProps}
                        key={key ?? String(label)}
                        value={String(tabIndex + 1)}
                    >
                        {component}
                    </TabPanel>
                ))}
            </TabContext>
        </Box>
    );
};

export default memo(Tabs);
