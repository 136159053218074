import { memo, type FC } from "react";
import Tooltip, { type TooltipProps } from "@mui/material/Tooltip";
import type { Option } from "types";
import { useTypedSelector } from "hooks";
import { selectAllMerchants } from "features/merchants/selectors";
import type { Merchant } from "features/merchants/types";
import Autocomplete, { type AutocompleteProps } from "ui/atoms/Autocomplete";
import { getOptionName, isOptionEqualToValue } from "util/option";

type MerchantSelectorProps = Omit<AutocompleteProps, 'options'> & {
    readonly tooltipProps?: Omit<TooltipProps, 'children'>;
    readonly getOption?: (merchant: Merchant, index?: number, array?: Array<Merchant>) => Option;
};

const MerchantSelector: FC<MerchantSelectorProps> = ({ tooltipProps, getOption, ...restProps }) => {
    const merchants = useTypedSelector(selectAllMerchants);

    const getOptions: () => Option[] = () =>
        merchants.map(getOption);

    return (
        <Tooltip
            arrow
            title='Please select merchant, this will help us to better track the reason'
            {...tooltipProps}
        >
            <Autocomplete
                {...restProps}
                options={getOptions()}
                getOptionLabel={getOptionName}
                isOptionEqualToValue={isOptionEqualToValue}
                TextFieldProps={{
                    fullWidth: true,
                    placeholder: 'Select merchant*',
                    ...restProps.TextFieldProps
                }}
                FormControlProps={{
                    ...restProps.FormControlProps,
                    sx: {
                        width: '100%',
                        ...restProps.FormControlProps?.sx
                    }
                }}
            />
        </Tooltip>
    );
};

MerchantSelector.defaultProps = {
    getOption: (merchant: Merchant): Option => ({
        id: merchant.coreId,
        name: merchant.fullName
    })
};

export default memo(MerchantSelector);
