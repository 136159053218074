import { memo, useState } from "react";
import { RESEND_OTP_TIMEOUT_SEC } from "consts/auth";
import useCookieState from "hooks/useCookieState";
import { TimeoutableAction } from "ui/molecules/Action";
import useVerify2FA from "./useVerify2FA";

type Props = {
    readonly resendCodeTimeout?: number;
};

const ResendCode = ({ resendCodeTimeout = RESEND_OTP_TIMEOUT_SEC }: Props) => {
    const [isLoading, setLoading] = useState(false);
    const [allowResendCodeCounter, setAllowResendCodeCounter] = useCookieState({
        key: 'resendOTPWithin',
        defaultValue: 0
    });
    const { resend } = useVerify2FA();

    const onClick = () => {
        setLoading(true);

        resend({
            onSuccess: () => setAllowResendCodeCounter(resendCodeTimeout)
        })
        .finally(() => setLoading(false));
    };

    const onChange = (currentTimeout: number) => {
        if (currentTimeout > 0) {
            return;
        }

        setAllowResendCodeCounter(0, { strategy: 'remove' });
    };

    const renderChildren = (currentTimeout?: number) => {
        if (!currentTimeout) {
            return 'Resend code';
        }

        return `Resend code in ${currentTimeout} seconds`;
    };

    const timeout = Number(allowResendCodeCounter);

    return (
        <TimeoutableAction
            timeout={timeout}
            onChange={onChange}
            renderChildren={renderChildren}
            ActionProps={{
                onClick,
                disabled: timeout > 0,
                variant: 'text',
                loading: isLoading
            }}
        />
    );
};

export default memo(ResendCode);
