import { memo } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SortOrder } from "consts/request-query";
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import PaymentMethod from "ui/molecules/PaymentMethod";
import { Collection, Resource } from "ui/molecules/Resource";
import { useCreateTaxableEntityContext } from "ui/organizms/MerchantPayouts";
import { IncrementTaxesModel } from "ui/forms/IncrementTaxes";
import GroupedFees from "../GroupedFees";
import useMerchantFeesAndCommisions from "../useMerchantFeesAndCommisions";
import { AddMerchantFeeAction } from "../AddMerchantFee";

const MethodFees = ({ sx }: BoxProps) => {
    const { onOpen } = useCreateTaxableEntityContext();

    const { getMethodFees, groupIncrementTaxesBy } = useMerchantFeesAndCommisions();

    const fees = Object.entries<Array<MerchantPayoutIncrementTaxes>>(groupIncrementTaxesBy({
        groupByKey: 'tag',
        incrementTaxes: getMethodFees()
    }));

    return (
        <Resource
            isEmpty={!fees.length}
            emptySlot={<></>}
        >
            <Typography
                variant='h5'
            >
                Method Fees
            </Typography>
            <Collection
                sx={{
                    display: 'grid',
                    gap: 2
                }}
                collection={fees}
                renderResource={([tag, methodFees]) => (
                    <Resource
                        key={tag}
                        isEmpty={!methodFees.length}
                        emptySlot={<></>}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gap: 2
                            }}
                        >
                            <PaymentMethod
                                tag={tag}
                            >
                                <AddMerchantFeeAction
                                    onClick={() => onOpen(
                                        IncrementTaxesModel.init()
                                            .initSingleOptionValues({ tag })
                                    )}
                                >
                                    Add {tag} Fees
                                </AddMerchantFeeAction>
                            </PaymentMethod>
                            <GroupedFees
                                sx={sx}
                                tag={tag}
                                incrementTaxes={methodFees}
                                sortOrder={SortOrder.Asc}
                            />
                        </Box>
                    </Resource>
                )}
            />
        </Resource>
    );
};

export default memo(MethodFees);
