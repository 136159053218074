import { memo } from "react";
import type { BoxProps } from "@mui/material/Box";
import { SortOrder } from "consts/request-query";
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import { Collection } from "ui/molecules/Resource";
import type { Sortable, WithPrepopulatedFormValues } from "./types";
import useMerchantFeesAndCommisions from "../useMerchantFeesAndCommisions";
import FeesByTaxNameGroup from "./FeesByTaxNameGroup";

type Props = Sortable<
    & BoxProps
    & WithPrepopulatedFormValues<{
        readonly incrementTaxes?: Array<MerchantPayoutIncrementTaxes>;
    }, 'taxName'>
>;

const GroupedFees = ({ sx, incrementTaxes, tag, sortOrder }: Props) => {
    const { groupIncrementTaxesBy } = useMerchantFeesAndCommisions();

    const getOrderedFees = () => {
        const fees = Object.entries<Array<MerchantPayoutIncrementTaxes>>(groupIncrementTaxesBy({
            groupByKey: 'taxName',
            incrementTaxes
        }));

        if (Object.is(sortOrder, SortOrder.Asc)) {
            return fees.reverse();
        }

        return fees;
    };

    return (
        <Collection
            sx={{
                py: 2,
                px: 1,
                display: 'grid',
                gap: 3,
                ...sx
            }}
            collection={getOrderedFees()}
            renderResource={([taxName, incrementTaxes]) => (
                <FeesByTaxNameGroup
                    key={taxName}
                    taxName={taxName}
                    tag={tag}
                    incrementTaxes={incrementTaxes}
                    sortOrder={sortOrder}
                />
            )}
        >
        </Collection>
    );
};

export default memo(GroupedFees);
