import { memo } from "react";
import Typography from "@mui/material/Typography";
import Account from "ui/molecules/Account";
import type { PaymentMethod } from "features/paymentMethods/types";
import { Box, Tooltip } from "@mui/material";

const PaymentMethodName = ({ name }: PaymentMethod) => (
    <Account
        CustomAvatarProps={{
            name: String(name),
            alt: String(name)
        }}
    >

        <Typography
            sx={{
                textDecoration: 'capitalize'
            }}
        >
            <Tooltip title={name}>
                <Box
                    sx={{
                        maxWidth: '100px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {name}
                </Box>
            </Tooltip>
        </Typography>
    </Account>
);

export default memo(PaymentMethodName);
