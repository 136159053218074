import type { PaymentMethod } from "features/paymentMethods/types";
import type usePaymentMethodDetail from "./usePaymentMethod";
import type { AccordionProps } from "ui/atoms/Accordion";
import type { WithDirtyOptions } from "util/forms";

export const enum PaymentMethodDetailTab {
    BasicInfo = 'BasicInfo',
    SpecificInputParameters = 'SpecificInputParameters'
};


export type PaymentMethodContext = ReturnType<typeof usePaymentMethodDetail>;

export type UsePaymentMethodArg =
    & Pick<WithDirtyOptions<{}>, 'addExtras'>
    & {
        readonly useCaseDiscriminationKey: 'edit' | 'create';
        readonly paymentMethod?: PaymentMethod;
    };

export type PaymentMethodProviderProps =
    & Pick<
        AccordionProps,
        | 'expanded'
        | 'onChange'
    >
    & UsePaymentMethodArg;
