import useFormHook from "hooks/useForm";
import { Filters } from "consts/merchantPayouts";
import type { IncrementTaxes } from "features/merchantPayouts/types";
import type { IncrementTaxesForm, IncrementTaxesFormValues } from "./types";
import { useValidationSchema } from "./useValidationSchema";

export const getInitialValues = (values?: IncrementTaxesFormValues) => ({
    [Filters.merchantId]: null,
    [Filters.taxName]: null,
    [Filters.tag]: null,
    [Filters.refColFrom]: null,
    [Filters.refColFromMinSum]: null,
    [Filters.currentIncMultiplierForEachRefColumn]: null,
    ...values
});

export function useForm({
    initialValues,
    ...restFormArgs
}: IncrementTaxesForm) {
    return useFormHook<IncrementTaxesFormValues, IncrementTaxes>({
        ...restFormArgs,
        initialValues: getInitialValues(initialValues),
        validationSchema: useValidationSchema()
    });
};
