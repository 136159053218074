import { memo, type FC } from "react";
import { FilterVariant } from "consts/filters";
import { Filters } from "consts/paymentMethods";
import { FeatureFiltersProvider, FilterRenderTypes, type FeatureFiltersProviderProps } from "ui/widgets/Filters";
import AddMerchantPaymentMethodAction, { type AddMerchantPaymentMethodWithFiltersProps } from "./AddMerchantPaymentMethodAction";

type Props =
    & AddMerchantPaymentMethodWithFiltersProps
    & Partial<FeatureFiltersProviderProps>;

const AddMerchantPaymentMethod: FC<Props> = ({ filtersConfig, ...restProps }) => (
    <FeatureFiltersProvider
        filtersConfig={filtersConfig!}
    >
        <AddMerchantPaymentMethodAction
            {...restProps}
        />
    </FeatureFiltersProvider>
);

AddMerchantPaymentMethod.defaultProps = {
    filtersConfig: [
        {
            filterId: Filters.tag,
            filterVariant: FilterVariant.SingleOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Payment Method Tag',
                    multiple: false,
                    InputProps: {
                        placeholder: 'Select tag'
                    }
                }
            ]
        },
        {
            filterId: Filters.PSP,
            filterVariant: FilterVariant.SingleOption,
            renderAs: FilterRenderTypes.Dropdown,
            inputs: [
                {
                    label: 'Payment Method PSP',
                    multiple: false,
                    InputProps: {
                        placeholder: 'Select PSP'
                    }
                }
            ]
        }
    ]
};

export default memo(AddMerchantPaymentMethod);
