import type { BooleanSwitch, Option } from "types";
import type {
    Merchant,
    MerchantPayoutAccount,
    MerchantPayoutCryptoAccount,
    MerchantSettings
} from "./types";
import { AccountType, ActiveStatus, KycType } from "consts/merchants";
import { BooleanSwitchValue } from "consts/general";
import { Option as OptionFactory } from "util/option";
import { compareIds } from "util/support";
import { toBooleanSwitch } from "util/casts";

export const getAccountType = (account: Partial<MerchantPayoutAccount | MerchantPayoutCryptoAccount>) => {
    if (['chain', 'address'].some(accountProperty => accountProperty in account)) {
        return AccountType.Crypto;
    }

    return AccountType.Bank;
};

export const toBooleanActive = (activeStatus?: MerchantSettings['activeStatus']): BooleanSwitch =>
    new Map<ActiveStatus | undefined, BooleanSwitch>([
        [ActiveStatus.Active, BooleanSwitchValue.On],
        [ActiveStatus.Disabled, BooleanSwitchValue.Off]
    ]).get(activeStatus) ?? BooleanSwitchValue.Off;

export const toMerchantActiveStatus = (activeStatus: boolean | BooleanSwitch): ActiveStatus =>
    new Map<BooleanSwitch, ActiveStatus>([
        [BooleanSwitchValue.On, ActiveStatus.Active],
        [BooleanSwitchValue.Off, ActiveStatus.Disabled]
    ]).get(toBooleanSwitch(activeStatus)) ?? ActiveStatus.Disabled;

export class MerchantOption {
    public static get accountStatus(): Array<Option> {
        return [
            OptionFactory.make(ActiveStatus.Active, 'Active'),
            OptionFactory.make(ActiveStatus.Disabled, 'Inactive')
        ];
    }

    public static get kycTypes(): Array<Option> {
        return [
            OptionFactory.make(KycType.None, 'OFF'),
            OptionFactory.make(KycType.Strict, 'ON')
        ];
    }

    public static fromAccountStatus = (status: ActiveStatus): Option | null =>
        this.accountStatus.find(option => compareIds(option.id, status)) ?? null;

    public static fromKycType = (kycType: KycType): Option | null =>
        this.kycTypes.find(option => compareIds(option.id, kycType)) ?? null;

    public static fromMerchant = ({ coreId, fullName }: Merchant): Option =>
        OptionFactory.make(
            coreId,
            `${fullName} (${coreId})`
        );
}
