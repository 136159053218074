import { memo } from "react";
import PaymentMethodLimitsForm, {
    type WithMerchantsMethodsPivot,
    type PaymentMethodLimitsFormProps
} from "ui/forms/Merchant/PaymentMethodLimits";

const MethodLimits = (props: WithMerchantsMethodsPivot<PaymentMethodLimitsFormProps>) => (
    <PaymentMethodLimitsForm
        {...props}
    />
);

export default memo(MethodLimits);
