import { useState } from 'react';
// @mui
import Box from '@mui/material/Box';
// hooks
import {
  useAppStore,
  useResponsive,
  useDashboardBootstrap,
  useUnsavedChanges
} from 'hooks';
import { selectIsNotMor } from 'features/auth/selectors';
// components
import { useSettingsContext } from 'lib/settings';
//
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import DashboardOutletTarget from './DashboardOutletTarget';

// ----------------------------------------------------------------------

export default function Dashboard() {
  const store = useAppStore();

  const { themeLayout } = useSettingsContext();

  useDashboardBootstrap();

  useUnsavedChanges();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = (
    <NavVertical
      openNav={open}
      onCloseNav={handleClose}
      shouldRenderDocumentation={selectIsNotMor(store.getState())}
    />
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <DashboardOutletTarget />
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <DashboardOutletTarget />
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <DashboardOutletTarget />
      </Box>
    </>
  );
}
