import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal";
import {
    merchantPayoutsRequestLoading,
    merchantPayoutsRequestFulfilled,
    merchantPayoutsRequestRejected,
    createIncrementExchangeRatesBulkRequestFulfilled,
    createIncrementExchangeRatesRequestFulfilled,
    deleteRowRequestFulfilled,
    // mergeMerchantPayoutFilableRequestFulfilled,
    // putMerchantPayoutFilesRequestFulfilled,
    changeMultiFileMerge as changeMultiFileMergeReducer,
    changeSelectionModel as changeSelectionModelReducer,
    updateMerchantPayoutResourceRequestFulfilled,
    updateMerchantPayoutEntityById,
    updateMerchantPayoutIncrementTaxesRequestFulfilled
} from "./reducers";
import initialState, { sliceToken } from "./state";
import {
    deleteMerchantPayoutIncrementExchangeRatesThunk,
    getMerchantPayoutCalculationsThunk,
    getMerchantPayoutFilesThunk,
    getMerchantPayoutFileTaxCalculationsThunk,
    getMerchantPayoutIncrementExchangeRatesThunk,
    getMerchantPayoutIncrementsThunk,
    getMerchantPayoutIncrementTaxesThunk,
    getMerchantPayoutSummaryFilesThunk,
    getTransactionsMerchantPayoutIncrementsPivotThunk,
    postMerchantPayoutIncrementExchangeRatesBulkThunk,
    postMerchantPayoutIncrementExchangeRatesThunk,
    mergeMerchantPayoutSummaryFilesThunk,
    mergeMerchantPayoutFilesThunk,
    putMerchantPayoutIncrementsThunk,
    deleteMerchantPayoutIncrementTaxesThunk,
    togglePayMerchantPayoutFilesThunk,
    deleteMerchantPayoutFilesThunk,
    deleteMerchantPayoutSummaryFilesThunk,
    getMerchantPayoutFilesByIdThunk,
    putMerchantPayoutFilesThunk,
    putMerchantPayoutIncrementTaxesThunk
} from "./thunks";
import type {
    MerchantPayoutsState,
    Tabs
} from "./types";
import type { CoreBankingEntity } from "types";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";

const slice = createSlice({
    name: sliceToken,
    reducers: {
        ...sliceMixin<MerchantPayoutsState, Tabs>(initialState),
        updateEntityById: <T extends CoreBankingEntity>(state: WritableDraft<MerchantPayoutsState>, { payload }: PayloadAction<T>) => {
            updateMerchantPayoutEntityById(state, payload);
        },
        /*changeExportLoading: (state: WritableDraft<MerchantPayoutsState>, { payload }: PayloadAction<Draft<ApiState>>) => {
            const activeTab = state.activeTab;
            state[activeTab].exportLoadingState = payload;
        },
        resetState: (state: WritableDraft<MerchantPayoutsState>) => {
            Object.assign(state, initialState);
        },
        saveTabSearchParams: (state: WritableDraft<MerchantPayoutsState>, { payload }: PayloadAction<Draft<string>>) => {
            const activeTab = state.activeTab;
            state[activeTab].searchParams = payload;
        },
        changeTab: (state: WritableDraft<MerchantPayoutsState>, { payload }: PayloadAction<Draft<Tabs>>) => {
            state.activeTab = payload;
        },*/
        changeMultiFileMerge: changeMultiFileMergeReducer,
        changeSelectionModel: changeSelectionModelReducer,
    },
    initialState,
    extraReducers: builder =>
        builder
            // Summary Files
            .addCase(getMerchantPayoutSummaryFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutSummaryFilesThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutSummaryFilesThunk.rejected, merchantPayoutsRequestRejected)
            // Merge Summary Files
            .addCase(mergeMerchantPayoutSummaryFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(mergeMerchantPayoutSummaryFilesThunk.rejected, merchantPayoutsRequestRejected)
            .addCase(mergeMerchantPayoutSummaryFilesThunk.fulfilled, updateMerchantPayoutResourceRequestFulfilled())
            // Delete summary file
            .addCase(deleteMerchantPayoutSummaryFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(deleteMerchantPayoutSummaryFilesThunk.fulfilled, deleteRowRequestFulfilled)
            .addCase(deleteMerchantPayoutSummaryFilesThunk.rejected, merchantPayoutsRequestRejected)
            // Files
            .addCase(getMerchantPayoutFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutFilesThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutFilesThunk.rejected, merchantPayoutsRequestRejected)
            // File by Id
            .addCase(getMerchantPayoutFilesByIdThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutFilesByIdThunk.fulfilled, updateMerchantPayoutResourceRequestFulfilled())
            .addCase(getMerchantPayoutFilesByIdThunk.rejected, merchantPayoutsRequestRejected)
            // Update File
            .addCase(putMerchantPayoutFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(putMerchantPayoutFilesThunk.fulfilled, updateMerchantPayoutResourceRequestFulfilled())
            .addCase(putMerchantPayoutFilesThunk.rejected, merchantPayoutsRequestRejected)
            // Delete File
            .addCase(deleteMerchantPayoutFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(deleteMerchantPayoutFilesThunk.fulfilled, deleteRowRequestFulfilled)
            .addCase(deleteMerchantPayoutFilesThunk.rejected, merchantPayoutsRequestRejected)
            // Merge Files
            .addCase(mergeMerchantPayoutFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(mergeMerchantPayoutFilesThunk.rejected, merchantPayoutsRequestRejected)
            .addCase(mergeMerchantPayoutFilesThunk.fulfilled, updateMerchantPayoutResourceRequestFulfilled())
            // Toggle Paid / Unpaid
            .addCase(togglePayMerchantPayoutFilesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(togglePayMerchantPayoutFilesThunk.fulfilled, updateMerchantPayoutResourceRequestFulfilled())
            .addCase(togglePayMerchantPayoutFilesThunk.rejected, merchantPayoutsRequestRejected)
            // Calculations
            .addCase(getMerchantPayoutCalculationsThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutCalculationsThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutCalculationsThunk.rejected, merchantPayoutsRequestRejected)
            // FileTaxCalculations
            .addCase(getMerchantPayoutFileTaxCalculationsThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutFileTaxCalculationsThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutFileTaxCalculationsThunk.rejected, merchantPayoutsRequestRejected)
            // IncrementsPivot
            .addCase(getTransactionsMerchantPayoutIncrementsPivotThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getTransactionsMerchantPayoutIncrementsPivotThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getTransactionsMerchantPayoutIncrementsPivotThunk.rejected, merchantPayoutsRequestRejected)
            // IncrementsTaxCalculations
            .addCase(getMerchantPayoutIncrementTaxesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutIncrementTaxesThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutIncrementTaxesThunk.rejected, merchantPayoutsRequestRejected)
            // Increments
            .addCase(getMerchantPayoutIncrementsThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutIncrementsThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutIncrementsThunk.rejected, merchantPayoutsRequestRejected)
            .addCase(putMerchantPayoutIncrementsThunk.pending, merchantPayoutsRequestLoading)
            .addCase(putMerchantPayoutIncrementsThunk.fulfilled, updateMerchantPayoutResourceRequestFulfilled())
            .addCase(putMerchantPayoutIncrementsThunk.rejected, merchantPayoutsRequestRejected)
            // IncrementExchangeRates
            .addCase(getMerchantPayoutIncrementExchangeRatesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(getMerchantPayoutIncrementExchangeRatesThunk.fulfilled, merchantPayoutsRequestFulfilled)
            .addCase(getMerchantPayoutIncrementExchangeRatesThunk.rejected, merchantPayoutsRequestRejected)
            // Delete IncrementExchangeRates
            .addCase(deleteMerchantPayoutIncrementTaxesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(deleteMerchantPayoutIncrementTaxesThunk.fulfilled, deleteRowRequestFulfilled)
            .addCase(deleteMerchantPayoutIncrementTaxesThunk.rejected, merchantPayoutsRequestRejected)
            // CreateIncrementExchangeRates
            .addCase(postMerchantPayoutIncrementExchangeRatesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(postMerchantPayoutIncrementExchangeRatesThunk.fulfilled, createIncrementExchangeRatesRequestFulfilled)
            .addCase(postMerchantPayoutIncrementExchangeRatesThunk.rejected, merchantPayoutsRequestRejected)
            // DeleteIncrementExchangeRates
            .addCase(deleteMerchantPayoutIncrementExchangeRatesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(deleteMerchantPayoutIncrementExchangeRatesThunk.fulfilled, deleteRowRequestFulfilled)
            .addCase(deleteMerchantPayoutIncrementExchangeRatesThunk.rejected, merchantPayoutsRequestRejected)
            // ImportCSVBulkUpload
            .addCase(postMerchantPayoutIncrementExchangeRatesBulkThunk.pending, merchantPayoutsRequestLoading)
            .addCase(postMerchantPayoutIncrementExchangeRatesBulkThunk.fulfilled, createIncrementExchangeRatesBulkRequestFulfilled)
            .addCase(postMerchantPayoutIncrementExchangeRatesBulkThunk.rejected, merchantPayoutsRequestRejected)
            // Update IncrementTaxes
            .addCase(putMerchantPayoutIncrementTaxesThunk.pending, merchantPayoutsRequestLoading)
            .addCase(putMerchantPayoutIncrementTaxesThunk.fulfilled, updateMerchantPayoutIncrementTaxesRequestFulfilled)
            .addCase(putMerchantPayoutIncrementTaxesThunk.rejected, merchantPayoutsRequestRejected)
});

export const {
    name,
    actions: {
        changeExportLoading,
        resetState,
        saveTabSearchParams,
        changeTab,
        changeMultiFileMerge,
        changeSelectionModel,
        updateEntityById
    }
} = slice;

export default slice.reducer;
