import {
    DomainRepository,
    type Dispatcher
} from "hooks/useDomainState";
import {
    MerchantAccountSettingsActionType,
    type MerchantAccountSettingsRepository,
    type MerchantAccountSettingsRepositoryState,
    type MerchantSettingsActionPayload
} from "./types";
import type { MerchantRepository, MerchantSettingsRepository } from "../../repository";
import type { UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import type { Merchant } from "features/merchants/types";
import { toBooleanActive } from "features/merchants/helpers";

export class Repository extends DomainRepository<
    MerchantAccountSettingsRepositoryState,
    MerchantAccountSettingsActionType
> implements MerchantAccountSettingsRepository {
    public constructor(
        public readonly state: MerchantAccountSettingsRepositoryState,
        protected readonly dispatch: Dispatcher<MerchantAccountSettingsActionType>,
        private readonly merchantRepository: MerchantRepository & MerchantSettingsRepository,
    ) {
        super(state, dispatch);
    }

    public get merchantSettings() {
        return this.merchantRepository.merchantSettings;
    }

    public get accountApiSettings() {
        const {
            apiId,
            apiGuid,
            cpKey,
            cpSecret,
            sharedKey
        } = this.merchantSettings ?? {};

        return {
            apiId,
            apiGuid,
            cpKey,
            cpSecret,
            sharedKey
        };
    }

    public get isMerchantSettingsLoading() {
        return (
            this.isMerchantSettingsUninitialized ||
            this.merchantRepository.isMerchantSettingsLoading
        );
    }

    public get isMerchantSettingsUninitialized() {
        return this.merchantRepository.isMerchantSettingsUninitialized;
    }

    public get isMerchantSettingsSucceeded() {
        return this.merchantRepository.isMerchantSettingsSucceeded;
    }

    public async fetchMerchantSettings(): Promise<void> {
        return this.merchantRepository.fetchMerchantSettings();
    }

    public updateMerchantSettings(
        merchantSettings: MerchantSettingsActionPayload,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ) {
        this.syncActiveStatus(merchantSettings);

        return this.merchantRepository.updateMerchantSettings(
            merchantSettings,
            requestSettings
        );
    }

    public updateMerchant(merchant: Partial<Merchant>) {
        return this.merchantRepository.updateMerchant(merchant);
    }

    public reset(): void {
        this.dispatch({
            type: MerchantAccountSettingsActionType.Reset,
            payload: undefined
        });
    }

    private syncActiveStatus({ merchantId, activeStatus }: MerchantSettingsActionPayload) {
        if (typeof activeStatus === 'undefined') {
            return Promise.resolve();
        }

        return this.merchantRepository.updateMerchant({
            coreId: merchantId,
            isActive: toBooleanActive(activeStatus)
        });
    }
}
