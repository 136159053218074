import { memo, type ReactNode } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCountdown, type UseCountdownArg } from "hooks";
import type { WithActionProps } from "./types";

type Props = WithActionProps<UseCountdownArg & {
    readonly renderChildren: (currentTimeout?: number) => ReactNode;
}>;

const Timeoutable = ({
    renderChildren,
    ActionProps,
    ...restProps
}: Props) => (
    <LoadingButton
        {...ActionProps}
    >
        {renderChildren(useCountdown(restProps))}
    </LoadingButton>
);

export default memo(Timeoutable);
