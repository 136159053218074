import { useForm as useFormikForm } from 'hooks';
import { FormField, type UserFormType, type UserFormWithRelations } from "./types";
import useValidationSchema from "./useValidationSchema";
import { BooleanSwitchValue } from 'consts/general';

export const getInitialValues = (initialValues?: Partial<UserFormWithRelations>): UserFormWithRelations => ({
    [FormField.FirstName]: '',
    [FormField.LastName]: '',
    [FormField.Email]: '',
    [FormField.Password]: '',
    [FormField.PasswordConfirmation]: '',
    [FormField.RoleId]: null,
    [FormField.Role]: null,
    [FormField.Merchants]: [],
    [FormField.Domains]: [],
    [FormField.TwoFactorEnabled]: BooleanSwitchValue.Off,
    ...initialValues,
});

export const useForm = (userForm: UserFormType) => {
    const {
        initialValues,
        ...restFormArgs
    } = userForm;

    return useFormikForm({
        ...restFormArgs,
        validationSchema: useValidationSchema({
            ...restFormArgs,
            isNew: typeof initialValues.coreId === 'undefined'
        }),
        initialValues: getInitialValues(initialValues)
    });
};
