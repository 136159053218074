import { memo } from "react";
import Taxable from "../Taxable";
import AddIncrementTaxesAction from "./AddIncrementTaxesAction";
import DeleteIncrementExchangeRatesDialogBody from "./DeleteIncrementTaxesDialogBody";
import useActions from "features/merchantPayouts/useActions";
import { postMerchantPayoutIncrementTaxesBulkThunk } from "features/merchantPayouts/thunks";
import { downloadIncremetTaxesCSVTemplate } from "features/merchantPayouts/api";
import type { MerchantPayoutsTableProps } from "../MerchantPayoutsTable";

const IncrementTax = (props: MerchantPayoutsTableProps) => {
    const { deleteMerchantPayoutIncrementTaxes } = useActions();

    return (
        <Taxable
            {...props}
            downloadTemplateHandler={downloadIncremetTaxesCSVTemplate}
            deleteActionRequestHandler={deleteMerchantPayoutIncrementTaxes}
            importBulkRequestThunk={postMerchantPayoutIncrementTaxesBulkThunk}
            createTaxableActionLabel="Add new tax"
            createTaxableEntitySlot={(
                <AddIncrementTaxesAction />
            )}
            label="Delete tax"
            dialogProps={{
                children: DeleteIncrementExchangeRatesDialogBody
            }}
        />
    );
};

export default memo(IncrementTax);