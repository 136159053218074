import { useForm as useFormHook } from "hooks";
import { FormAdapter } from "hooks/useForm";
import type { TwoFactorVerify } from "features/auth/types";
import { FormField, type TwoFactorCodeForm } from "./types";
import useValidationSchema from "./useValidationSchema";

export const useForm = ({ onSaveOrCreate }: Pick<TwoFactorCodeForm, 'onSaveOrCreate'>) =>
    useFormHook<TwoFactorVerify>({
        onSaveOrCreate: async (...args) => {
            const response = await onSaveOrCreate(...args);

            return FormAdapter.fromResponse(response);
        },
        validationSchema: useValidationSchema(),
        initialValues: {
            [FormField.TwoFactorCode]: '',
            [FormField.WithTrustToken]: false
        }
    });

export default useForm;
