import { memo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTypedSelector } from "hooks";
import type { ResourceResponse } from "types";
import { Filters } from "consts/merchantPayouts";
import { OptionableModel } from "util/option";
import { IncrementTaxes as IncrementTaxesType } from "features/merchantPayouts/types";
import useMerchantPayoutsActions from "features/merchantPayouts/useActions";
import { selectPaymentMethodsTags } from "features/paymentMethods/selectors";
import IncrementTaxes, { IncrementTaxesBootstrapData, useForm } from "ui/forms/IncrementTaxes";
import { useFiltersModel } from "ui/widgets/Filters";
import { BasicDialog } from "ui/molecules/Dialog";
import { useCreateTaxableEntityContext } from "../Taxable";

type Props = {
    readonly onResolve?: (payload: ResourceResponse<IncrementTaxesType>) => void;
};

const AddIncrementTaxesAction = ({ onResolve }: Props) => {
    const { open, initialValues, onClose } = useCreateTaxableEntityContext();

    const formId = "incrementTaxesForm";

    const paymentMethodsTags = useTypedSelector(selectPaymentMethodsTags);

    const { postMerchantPayoutIncrementTax } = useMerchantPayoutsActions();

    const { getFilterOptions, isLoading: isBootstrapDataLoading } = useFiltersModel();

    const getBootstrapData = () =>
        [
            Filters.merchantId,
            Filters.taxName,
            Filters.refColFrom
        ].reduce(
            (bootstrapData, bootstrapDataToken) => ({
                ...bootstrapData,
                [bootstrapDataToken]: getFilterOptions(bootstrapDataToken).options ?? [],
            }),
            {
                tag: paymentMethodsTags,
            } as IncrementTaxesBootstrapData
        );

    const getInitialValues = () => {
        if (initialValues instanceof OptionableModel) {
            return initialValues.withOptionable(getBootstrapData());
        }
    };

    const incrementTaxesForm = useForm({
        isInitialValid: false,
        enableReinitialize: true,
        initialValues: getInitialValues(),
        onResolve: payload => {
            incrementTaxesForm.resetForm();
            onClose();

            onResolve?.(payload);
        },
        onSaveOrCreate: (values) =>
            postMerchantPayoutIncrementTax({
                merchantId: values.merchantId?.id,
                taxName: values.taxName?.name,
                tag: values.tag?.id as string,
                refColFrom: values.refColFrom?.name,
                refColFromMinSum: values.refColFromMinSum,
                currentIncMultiplierForEachRefColumn: values.currentIncMultiplierForEachRefColumn
            }),
    });

    const handleClose = () => {
        onClose();
        incrementTaxesForm.resetForm();
    };

    return (
        <BasicDialog
            isOpen={open}
            isCanClose={!incrementTaxesForm.isSubmitting}
            onClose={handleClose}
            titleSlot='Create new increment tax'
            actionsSlot={(
                <LoadingButton
                    type="submit"
                    variant="outlined"
                    form={formId}
                    loading={incrementTaxesForm.isSubmitting}
                    disabled={!incrementTaxesForm.isValid}
                >
                    Create
                </LoadingButton>
            )}
        >
            <IncrementTaxes
                {...incrementTaxesForm}
                id={formId}
                bootstrapData={getBootstrapData()}
                isBootstrapDataLoading={isBootstrapDataLoading}
            />
        </BasicDialog>
    );
};

export default memo(AddIncrementTaxesAction);
