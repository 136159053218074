import { useEffect, useRef } from "react";
import { publish, subscribe, unsubscribe } from "util/custom-event";
import { useUnsavedChangesContext } from "ui/widgets/UnsavedChanges";
import { PaymentMethodProviderProps, usePaymentMethodContext } from "../PaymentMethodProvider";

const enum Events {
    ResetUnsavedCahnges = 'Events.ResetUnsavedCahnges'
};

export default function usePaymentMethodUnsavedChanges({ onChange }: Pick<PaymentMethodProviderProps, 'onChange'>) {
    const paymentMethodHandlers = usePaymentMethodContext();
    const paymentMethodHandlersRef = useRef(paymentMethodHandlers);
    paymentMethodHandlersRef.current = paymentMethodHandlers;

    const { checkUnsavedChanges, ...restUnsavedChangesHandlers } = useUnsavedChangesContext();
    const unsavedChangesHandlersRef = useRef(restUnsavedChangesHandlers);
    unsavedChangesHandlersRef.current = restUnsavedChangesHandlers;

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval>;

        const handleResetUnsavedChanges = () => {
            clearInterval(intervalId);

            resetUnsavedChanges();

            intervalId = setInterval(resetUnsavedChanges);
        };

        function resetUnsavedChanges() {
            if (unsavedChangesHandlersRef.current.hasUnsavedChanges()) {
                paymentMethodHandlersRef.current.reset();
                return;
            }

            clearInterval(intervalId);
        }

        subscribe(Events.ResetUnsavedCahnges, handleResetUnsavedChanges);

        return () => unsubscribe(Events.ResetUnsavedCahnges, handleResetUnsavedChanges);
    }, []);

    const handleChange: PaymentMethodProviderProps['onChange'] = (event, expanded) =>
        checkUnsavedChanges({
            onCancel: () => Promise.reject(),
            onConfirm: () => publish(Events.ResetUnsavedCahnges, null)
        })
        .then(() => onChange!(event, expanded));

    return {
        onChange: handleChange
    };
};
