import { memo } from "react";
import type { PaletteMode, Theme } from "@mui/material";
import LoadingButton, { type LoadingButtonProps } from "@mui/lab/LoadingButton";
import { ThemePaletteMode } from "consts/ui";

const SubmitButton = (props: LoadingButtonProps) => {
    const getThemeColor = (theme: Theme) => new Map<PaletteMode, string>([
        [ThemePaletteMode.Dark, 'grey.800'],
        [ThemePaletteMode.Light, 'common.white']
    ]).get(theme.palette.mode);

    return (
        <LoadingButton
            fullWidth
            color='inherit'
            size='large'
            type='submit'
            variant='contained'
            {...props}
            sx={{
                my: 3,
                bgcolor: 'text.primary',
                color: getThemeColor,
                '&:hover': {
                    bgcolor: 'text.primary',
                    color: getThemeColor,
                },
            }}
       />
    );
};

export default memo(SubmitButton);
