import { Fragment, memo } from "react";
import Divider from "@mui/material/Divider";
import { isLast } from "util/support";
import { SpecInParams as SetupPaymentMethodSpecInParams } from 'ui/forms/Setup/PaymentMethod';
import { Collection } from "ui/molecules/Resource";
import { TabLayout } from "ui/widgets/StatefulTabs";
import { usePaymentMethodContext } from "../../PaymentMethodProvider";
import SpecInParamsRow from "./SpecInParamsRow";

const SpecInParams = () => {
    const {
        isProcessing,
        selectSpecificInputParameters
    } = usePaymentMethodContext();

    const {
        specInParameters,
        deleteSpecInParamsByIndex,
        ...rest
    } = selectSpecificInputParameters();

    return (
        <TabLayout>
            <SetupPaymentMethodSpecInParams.SpecInParams
                {...rest}
            />
            <Collection
                collection={specInParameters}
                renderResource={(value, i) => {
                    const index = Number(i);

                    return (
                        <Fragment
                            key={String(index)}
                        >
                            <SpecInParamsRow
                                isDeleting={isProcessing}
                                specInParameters={value}
                                onDelete={() => deleteSpecInParamsByIndex(index)}
                            />
                            {!isLast(index, specInParameters) && (
                                <Divider
                                    sx={{
                                        borderWidth: 2
                                    }}
                                />
                            )}
                        </Fragment>
                    );
                }}
            />
        </TabLayout>
    );
};

export default memo(SpecInParams);
