import { memo } from "react";
import Box from "@mui/material/Box";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import PaymentMethodName from "./PaymentMethodName";
import RiskLevel from "./RiskLevel";
import PaymentMethodSwitch from "./PaymentMethodSwitch";
import LimitationsInfo from "./LimitationsInfo";
import PaymentMethodIsAssignable from "./PaymentMethodIsAssignable";

const MerchantPaymentMethodPSPSummary = (props: MerchantsMethodsPivot) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto auto 2fr 1fr',
            alignItems: 'center',
            width: '100%',
            gap: 4,
            minHeight: 80
        }}
    >
        <PaymentMethodName
            {...props}
        />
        <PaymentMethodIsAssignable
            {...props}
        />
        <RiskLevel
            {...props}
        />
        <LimitationsInfo
            {...props}
        />
        <PaymentMethodSwitch
            {...props}
        />
    </Box>
);

export default memo(MerchantPaymentMethodPSPSummary);
