import {
    useFilters,
    type AutocompleteProps,
    type Filter,
    type UseFiltersPropsWithConfig
} from "ui/widgets/Filters";
import { filterStrategyRegistry } from "./renderTypeRegistry";

export default function useFeatureFilters<T extends string>(props: Pick<
    UseFiltersPropsWithConfig<T>,
    | 'filterTypes'
    | 'filtersConfig'
>) {
    const filtersInstance = useFilters({
        ...props,
        fetchData: _ => { }
    });

    const { filters } = filtersInstance;

    const withFilters = <T extends Record<string, unknown>>(entityCollection: Array<T>) => (
        entityCollection.filter(entity =>
            filters.every(filter => {
                const strategy = filterStrategyRegistry.get(filter.renderAs);

                if (strategy) {
                    return strategy(
                        filter as Filter<AutocompleteProps>,
                        entity
                    );
                }

                return true;
            })
        )
    );

    return {
        ...filtersInstance,
        withFilters
    };
}