import { memo } from "react";
import { AddMerchantBusiness } from "ui/forms/Merchant/Domains";
import { TabLayout } from "ui/widgets/StatefulTabs";
import useMerchantDomains from "./useMerchantDomains";
import { useAccordion } from "ui/atoms/Accordion";
import MerchantBusiness from "./MerchantBusiness";
import { compareIds } from "util/support";
import { NoDataOverlay } from "ui/molecules/TableGrids";
import { Collection } from "ui/molecules/Resource";
import MerchantProvider from "../MerchantProvider";

const MerchantDomains = () => {
    const {
        repository,
        createMerchantBusiness,
        getMerchantBusinesses
    } = useMerchantDomains();

    const { expanded, handleChange } = useAccordion();

    const merchantBusinesses = getMerchantBusinesses();

    return (
        <TabLayout
            title='Proof of Domain'
            auxSlot={(
                <AddMerchantBusiness
                    onSaveOrCreate={createMerchantBusiness}
                />
            )}
            emptySlot={(
                <NoDataOverlay
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 300px)'
                    }}
                />
            )}
            isLoading={repository.isMerchantDomainsLoading}
            isEmpty={repository.isMerchantBusinessesEmpty}
            isError={repository.isMerchantDomainsError}
        >
            <Collection
                isEmpty={!merchantBusinesses.length}
                collection={merchantBusinesses}
                renderResource={merchantBusiness => (
                    <MerchantBusiness
                        key={merchantBusiness.coreId}
                        expanded={compareIds(String(expanded), merchantBusiness.coreId)}
                        onChange={handleChange(merchantBusiness.coreId)}
                        merchantBusiness={merchantBusiness}
                    />
                )}
            />
        </TabLayout>
    );
};

export default memo(() => (
    <MerchantProvider>
        <MerchantDomains />
    </MerchantProvider>
));
