import { memo } from "react";
import Box from "@mui/material/Box";
import { Filters, useFeatureFiltersContext } from "ui/widgets/Filters";
import useAddMerchantPaymentMethodModel from "./useAddMerchantPaymentMethodModel";

const AddMerchantPaymentMethodFilters = () => {
    const { filters, onChange } = useFeatureFiltersContext();
    const { getFilterOptions } = useAddMerchantPaymentMethodModel();

    return (
        <Box
            sx={{
                mx: 2,
                display: 'grid',
                placeContent: 'center',
                gap: 2
            }}
        >
            {filters.map(filter => (
                <Filters
                    key={filter.props.id}
                    {...filter}
                    props={{
                        ...filter.props,
                        ...getFilterOptions(filter.props.id)
                    }}
                    onChange={onChange}
                />
            ))}
        </Box>
    );
};

export default memo(AddMerchantPaymentMethodFilters);
