import { memo, type SyntheticEvent } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getFormInputProps } from "util/forms";
import Autocomplete, { CheckboxAutocomplete } from "ui/atoms/Autocomplete";
import { Checkbox } from "ui/forms/formik-elements";
import { FormField, type SetupPaymentMethodBasicInfoProps } from "./types";
import CountryIso2Adapter from "./CoutryIso2Adapter";

const BasicInfo = ({
    isBootstrapDataLoading,
    bootstrapData,
    isSubmitting,
    isValid,
    values,
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    ...restProps
}: SetupPaymentMethodBasicInfoProps) => {
    const isEditing = 'coreId' in restProps.initialValues;

    const onAutocompleteChange = (formField: FormField) =>
        (_: SyntheticEvent, values: Array<string>) => {
            setFieldValue(formField, values, true);
            setTimeout(() => setFieldTouched(formField, true));
        };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'flex-end'
            }}
            component='form'
            onSubmit={handleSubmit}
            autoComplete='off'
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1
                }}
            >
                <TextField
                    {...getFormInputProps(FormField.Name, restProps)}
                    size="small"
                    name={FormField.Name}
                    value={values[FormField.Name]}
                    label='Method Name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    required
                />
                <CountryIso2Adapter
                    {...restProps}
                    value={values[FormField.ForCountries]}
                    onChange={onAutocompleteChange(FormField.ForCountries)}
                />
                <TextField
                    {...getFormInputProps(FormField.DayToHoldOfflineFunds, restProps)}
                    size="small"
                    name={FormField.DayToHoldOfflineFunds}
                    value={values[FormField.DayToHoldOfflineFunds]}
                    label='Days To Hold Offline Funds'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 1
                }}
            >
                <TextField
                    {...getFormInputProps(FormField.Tag, restProps)}
                    size="small"
                    name={FormField.Tag}
                    value={values[FormField.Tag]}
                    label='Method Tag'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isEditing}
                    fullWidth
                    required
                />
                <CheckboxAutocomplete
                    fullWidth
                    multiple
                    size='small'
                    value={values[FormField.ForCurrencies]}
                    loading={isBootstrapDataLoading}
                    TextFieldProps={{
                        ...getFormInputProps(FormField.ForCurrencies, restProps),
                        required: true,
                        name: FormField.ForCurrencies,
                        label: 'Currencies Available'
                    }}
                    onChange={onAutocompleteChange(FormField.ForCurrencies)}
                    FormControlProps={{
                        sx: {
                            width: '100%'
                        }
                    }}
                    options={bootstrapData[FormField.ForCurrencies]}
                />
                <Autocomplete
                    fullWidth
                    disableClearable
                    size='small'
                    layout={null}
                    loading={isBootstrapDataLoading}
                    disabled={isBootstrapDataLoading || isEditing}
                    options={bootstrapData.PSP}
                    value={values[FormField.Provider]}
                    onChange={onAutocompleteChange(FormField.Provider)}
                    TextFieldProps={{
                        ...getFormInputProps(FormField.Provider, restProps),
                        required: true,
                        name: FormField.Provider,
                        label: 'Provider'
                    }}
                />
                <Checkbox
                    label='Method can be available for merchant'
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    CheckboxProps={{
                        inputProps: {
                            'aria-label': 'Method can be available for merchant'
                        },
                        name: FormField.IsAssignable,
                        checked: values[FormField.IsAssignable]
                    }}
                />
                <Checkbox
                    label='Activate send payment request email'
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    CheckboxProps={{
                        inputProps: {
                            'aria-label': 'Activate send payment request email'
                        },
                        name: FormField.SendPaymentRequestMail,
                        checked: values[FormField.SendPaymentRequestMail]
                    }}
                />
                <Checkbox
                    label='Activate send payment receipt email'
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    CheckboxProps={{
                        inputProps: {
                            'aria-label': 'Activate send payment receipt email'
                        },
                        name: FormField.SendPaymentReceiptMail,
                        checked: values[FormField.SendPaymentReceiptMail]
                    }}
                />
                <Checkbox
                    label='Method requires Spec-In Parameters'
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    CheckboxProps={{
                        inputProps: {
                            'aria-label': 'Method requires Spec-In Parameters'
                        },
                        name: FormField.RequireSpecIn,
                        checked: values[FormField.RequireSpecIn]
                    }}
                />
            </Box>
        </Box>
    );
};

export default memo(BasicInfo);
