import { FilterValueObject } from "../types";

export default class RangeValue extends FilterValueObject<Array<number | string>> {
    public toArray() {
        return this.value;
    }

    public serialize(value: number, index: number): string {
        return String(
            !index
                ? value || 0
                : value);
    }

    public isEmpty(): boolean {
        return false;
    }

    public isValueEmpty(): boolean {
        return this.value.every(val => !val);
    }
}
