import { memo } from "react";
import Typography from "@mui/material/Typography";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import Account from "ui/molecules/Account";

const PaymentMethodName = ({
    paymentMethodPSP
}: MerchantsMethodsPivot) => (
    <Account
        sx={{
            gridTemplateColumns: 'repeat(2, min-content)',
            gap: 2
        }}
        CustomAvatarProps={{
            color: 'default',
            name: paymentMethodPSP,
            alt: paymentMethodPSP
        }}
    >
        <Typography
            variant='button'
        >
            {paymentMethodPSP}
        </Typography>
    </Account>
);

export default memo(PaymentMethodName);
