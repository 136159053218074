import { useState } from "react";
import type { ConfirmatableDialogProps, DialogContent } from "ui/molecules/Dialog";

type ConfirmationDialog = Omit<
    ConfirmatableDialogProps,
    | 'open'
    | 'onCancel'
    | 'onConfirm'
>;

export default function useConfirmationDialog() {
    const [confirmationDialogProps, setConfirmationDialogProps] = useState<Partial<ConfirmationDialog>>({});

    const customizeConfirmationDialog = (propsOverride: Partial<ConfirmationDialog>) =>
        setConfirmationDialogProps(confirmationDialogProps => ({
            ...confirmationDialogProps,
            ...propsOverride
        }));

    const setConfirmationDialogContent = (content: DialogContent) =>
        customizeConfirmationDialog({
            content: [content, content]
        });

    const resetConfirmationDialog = () => setConfirmationDialogProps({});

    return {
        confirmationDialogProps,
        customizeConfirmationDialog,
        setConfirmationDialogContent,
        resetConfirmationDialog
    };
};
