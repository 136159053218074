import type { BootstrapableForm } from "types";
import type { TwoFactorVerify } from "features/auth/types";
import type { BoxProps } from "@mui/material";
import { ReactNode } from "react";

export const enum FormField {
    TwoFactorCode = 'twoFactorCode',
    WithTrustToken = 'withTrustToken'
};

export type TwoFactorCodeForm =
    & BootstrapableForm<TwoFactorVerify, {}>;

export type TwoFactorCodeFormProps = Omit<BoxProps<'form'>, 'onSubmit'> & {
    readonly resendCodeSlot: ReactNode;
    readonly trustDeviceSlot: ReactNode;
    readonly onSubmit: TwoFactorCodeForm['onSaveOrCreate'];
};
