import { memo, useState, type ChangeEvent, type FC } from "react";
import { useDebounceCallback } from 'usehooks-ts';
import { TextFieldLoadable } from "ui/atoms/TextField";
import { usePaymentMethodsContext } from "../../PaymentMethodsProvider";

type Props = {
    readonly delay?: number;
};

const SearchPaymentMethod: FC<Props> = ({ delay }) => {
    const [search, setSearch] = useState('');

    const { setSearchCriteria } = usePaymentMethodsContext();

    const debounceSearch = useDebounceCallback(setSearchCriteria, delay);

    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value } = target;

        setSearch(value);
        debounceSearch(value);
    };

    return (
        <TextFieldLoadable
            type='search'
            size='small'
            label='Search payment method'
            placeholder='Search'
            value={search}
            onChange={onChange}
        />
    );
};

SearchPaymentMethod.defaultProps = {
    delay: 300
};

export default memo(SearchPaymentMethod);
