import { memo } from "react";
import PaymentMethodProvider, { type PaymentMethodProviderProps } from "./PaymentMethodProvider";
import PaymentMethodAccordion from "./PaymentMethodAccordion";

const PaymentMethod = (props: PaymentMethodProviderProps) => (
    <PaymentMethodProvider
        {...props}
    >
        <PaymentMethodAccordion
            {...props}
        />
    </PaymentMethodProvider>
);

export default memo(PaymentMethod);
