import type { User } from "features/users/types";
import type { BooleanSwitch, ResourceResponse } from "types";

export type UserCredentials = {
  readonly email: string;
  readonly password: string;
};

export enum AuthStatus {
  Authenticated = 'authenticated',
  Guest = 'guest',
  Pending = 'pending',
  Uninitialized = 'uninitialized'
};

export type AuthState<T = User> = {
  status: AuthStatus;
  user: T | null;
};

export type AuthResponse = ResourceResponse<{
  readonly access_token: string;
  readonly token_type: string;
  readonly expires_in: number;
  readonly twoFactorAuth: boolean;
}>;

export type AuthData = AuthResponse['data'] & {
  readonly timestamp: number;
};

export type TwoFactorCode = number | string;

export type TwoFactorSetup = {
  readonly twoFactorEnabled: BooleanSwitch;
};

export type TwoFactorVerify = {
  readonly twoFactorCode: TwoFactorCode;
  readonly withTrustToken?: boolean;
};

export type TwoFactorVerifyResponse = ResourceResponse<{
  readonly trustToken: string | null;
}>;

export type WithTwoFactor<T extends object> = TwoFactorSetup & T;
