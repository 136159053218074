import { memo } from "react";
import Screen from "ui/layouts/Screen";
import { PaymentMethods as PaymentMethodsComponent } from "ui/organizms/Setup";

const PaymentMethods = () => (
    <Screen
        title='Setup | Payment methods'
    >
        <PaymentMethodsComponent />
    </Screen>

);

export default memo(PaymentMethods);
