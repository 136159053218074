import { memo, type FC } from "react";
import Box from "@mui/material/Box";
import Tabs, { type Tab } from "ui/atoms/Tabs";
import type { PaymentMethod } from "features/paymentMethods/types";
import {
    PaymentMethodDetailTab,
    usePaymentMethodContext
} from "../PaymentMethodProvider";
import BasicInfo from "./BasicInfo";
import SpecInParams from "./SpecInParams";

type Props = {
    readonly withTabs?: (
        tabs: Array<Tab>,
        paymentMethod: Pick<PaymentMethod, 'requireSpecIn'>
    ) => Array<Tab>;
};

const PaymentMethodDetail: FC<Props> = ({ withTabs }) => {
    const { onChange, selectBasicInfo } = usePaymentMethodContext();

    return (
        <Box>
            <Tabs
                model={withTabs!([
                    {
                        key: PaymentMethodDetailTab.BasicInfo,
                        label: 'Basic Info',
                        component: (
                            <BasicInfo />
                        ),
                        sxProps: {
                            py: 2
                        }
                    },
                    {
                        key: PaymentMethodDetailTab.SpecificInputParameters,
                        label: 'Specific Input Parameters',
                        component: (
                            <SpecInParams />
                        )
                    }
                ], selectBasicInfo().values)}
                TabListContainerProps={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}
                TabListProps={{
                    'aria-label': 'payment method setups',
                    variant: 'scrollable',
                    scrollButtons: 'auto'
                }}
                onChange={onChange}
            />
        </Box>
    );
};

PaymentMethodDetail.defaultProps = {
    withTabs: (tabs: Array<Tab>, { requireSpecIn }) => {
        if (!requireSpecIn) {
            return tabs.filter(({ key }) => (
                !Object.is(key, PaymentMethodDetailTab.SpecificInputParameters)
            ));
        }

        return tabs;
    }
};

export default memo(PaymentMethodDetail);
