import { createContext, memo, type PropsWithChildren } from 'react';
import useCreateTaxableEntity from './useCreateTaxableEntity';
import type {
    CreateTaxableEntityProviderProps,
    CreateTaxableEntityContext as CreateTaxableEntityContextType
} from './types';

export const CreateTaxableEntityContext = createContext<CreateTaxableEntityContextType>({} as CreateTaxableEntityContextType);

const CreateTaxableEntityProvider = ({
    children,
    withInitialValues,
    ...restProps
}: PropsWithChildren<CreateTaxableEntityProviderProps>) => (
    <CreateTaxableEntityContext.Provider
        value={{
            ...restProps,
            ...useCreateTaxableEntity({ withInitialValues }),
        }}
    >
        {children}
    </CreateTaxableEntityContext.Provider>
);

export default memo(CreateTaxableEntityProvider);
