import { memo, type PropsWithChildren } from "react";
import type { CoreBankingEntity } from "types";
import type { BoxProps } from "@mui/material/Box";
import useForm, { FormAdapter, type UseFormArg } from "hooks/useForm";
import { getFormInputProps } from "util/forms";
import type { TextFieldLoadableProps } from "ui/atoms/TextField";
import { EditableTextField } from "ui/molecules/Input";
import { useValidationSchema } from "./useValidationSchema";

type Props = {
    readonly onSave: <T extends Partial<CoreBankingEntity>>(values: T) => Promise<unknown>;
    readonly TextFieldProps: TextFieldLoadableProps;
    readonly validationSchema?: UseFormArg<Record<PropertyKey, unknown>>['validationSchema'];
    readonly sx?: BoxProps['sx'];
    readonly forbiddenDecimals?: boolean;
};

const EditableFee = ({
    sx,
    children,
    onSave,
    TextFieldProps,
    forbiddenDecimals,
    validationSchema = useValidationSchema
}: PropsWithChildren<Props>) => {
    const {
        values,
        handleBlur,
        handleChange,
        submitForm,
        ...form
    } = useForm({
        onSaveOrCreate: async values => {
            const response = await onSave(values)

            return FormAdapter.fromResponse(response);
        },
        validateOnChange: true,
        enableReinitialize: true,
        validationSchema: validationSchema(TextFieldProps.name!),
        initialValues: {
            [TextFieldProps.name!]: TextFieldProps.value
        }
    });

    const handleChangeWithoutDecimals = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (forbiddenDecimals) {
            event.target.value = value.replace(/\./g, '');
        }
        handleChange(event);
    };

    return (
        <EditableTextField
            sx={sx}
            variant='edit'
            onSave={submitForm}
            TextFieldProps={{
                size: 'small',
                ...TextFieldProps,
                ...getFormInputProps(TextFieldProps.name, form),
                onChange: handleChangeWithoutDecimals,
                onBlur: handleBlur,
                value: TextFieldProps.loading
                    ? ''
                    : values[TextFieldProps.name!]
            }}
        >
            {children}
        </EditableTextField>
    );
};

export default memo(EditableFee);
