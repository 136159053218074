import { memo } from "react";
import type { Scalar } from "types";
import Icon from "ui/atoms/Icon";
import When from "../When";
import type { StatusProps } from "./types";

export default memo(({ label }: StatusProps<Scalar<boolean>>) => (
    <When
        if={label}
        then={(
            <Icon
                name='Check'
                color='success'
            />
        )}
        else={(
            <Icon
                name='Close'
                color='error'
            />
        )}
    />
));
