import { memo, useState } from "react";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import Iconify from "lib/iconify";

const PasswordTextField = (props: TextFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const changePasswordVisibility = () => setShowPassword(state => !state);

    return (
        <TextField
            margin='normal'
            required
            fullWidth
            label="Password"
            type={showPassword ? 'text' : 'password'}
            autoComplete='current-password'
            {...props}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <InputAdornment
                        position="end"
                    >
                        <IconButton
                            edge="end"
                            onClick={changePasswordVisibility}
                        >
                            <Iconify
                                icon={showPassword
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default memo(PasswordTextField);
