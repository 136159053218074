import {  memo, type PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { WebRoutes } from "consts/enpoints/web";
import useAuth from "features/auth/useAuth";

const RedirectIfAuthenticated = ({ children }: PropsWithChildren) => {
    const location = useLocation();
    const { isAuthenticated, isPending } = useAuth();

    if (isPending()) {
        return null;
    }

    const from = (location.state as any)?.from?.pathname || WebRoutes.Root;

    if (isAuthenticated()) {
        return <Navigate to={from} state={{ from: undefined }} replace />;
    }

    return (
        <>
            {children}
        </>
    );
};

export default memo(RedirectIfAuthenticated);
