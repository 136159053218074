import type { Transaction } from "features/transactions/types";
import { FundStatus, Status } from "types";

type StatusesHistory =
    | 'succeededAt'
    | 'failedAt'
    | 'rejectedAt'
    | 'refundedAt'
    | 'chargedBackAt'
    | 'fundsWaitingAt'
    | 'fundsReceivedAt'
    | 'fundsMissingAt'
    | 'fundsNotExpectedAt'
    | 'fundsRefundedAt'
    | 'fundsPartiallyRefundedAt'
    | 'fundsPartiallySentAt'
    | 'fundsPartiallySentRefundedAt'
    | 'fundsPartiallyRefundedSentAt'
    | 'fundsSentAt'
    | 'fundsFullySentRefundedAt'
    | 'fundsFullyRefundedAt'
    | 'fundsFullyRefundedSentAt'
    | 'fundsFullySentAt';

type Timestamp = string;

type UseStatusesHistoryArgs = {
    readonly transactionHistoryCollection: Array<Transaction>;
    readonly transaction: Transaction;
};

export default function useStatusesHistory({
    transaction,
    transactionHistoryCollection
}: UseStatusesHistoryArgs) {
    const statusesHistory: Partial<Record<StatusesHistory, Timestamp | null>> = {
        succeededAt: null,
        failedAt: null,
        rejectedAt: null,
        refundedAt: null,
        chargedBackAt: null,
        fundsWaitingAt: null,
        fundsReceivedAt: null,
        fundsMissingAt: null,
        fundsNotExpectedAt: null,
        fundsPartiallyRefundedAt: null,
        fundsRefundedAt: null,
        fundsPartiallySentAt: null,
        fundsPartiallySentRefundedAt: null,
        fundsPartiallyRefundedSentAt: null,
        fundsSentAt: null,
        fundsFullySentRefundedAt: null,
        fundsFullyRefundedAt: null,
        fundsFullyRefundedSentAt: null,
        fundsFullySentAt: null
    };

    if (!transactionHistoryCollection) {
        return statusesHistory;
    }

    for (const [statusHolderProperty, mapings] of Array.from(new Map<
        | 'status'
        | 'fundStatus',
        Map<StatusesHistory, Status | FundStatus>
    >([
        ['status', new Map<StatusesHistory, Status>()
            .set('succeededAt', 'SUCCEEDED')
            .set('failedAt', 'FAILED')
            .set('rejectedAt', 'REJECT')
            .set('refundedAt', 'REFUNDED')
            .set('chargedBackAt', 'CHARGE_BACK')],
        ['fundStatus', new Map<StatusesHistory, FundStatus>()
            .set('fundsWaitingAt', 'WAITING')
            .set('fundsReceivedAt', 'RECEIVED')
            .set('fundsMissingAt', 'MISSING')
            .set('fundsNotExpectedAt', 'NOT_EXPECTED')
            .set('fundsPartiallyRefundedAt', 'PARTIALLY_REFUNDED')
            .set('fundsRefundedAt', 'REFUNDED')
            .set('fundsPartiallySentAt', 'PARTIALLY_SENT')
            .set('fundsPartiallySentRefundedAt', 'PARTIALLY_SENT_REFUNDED')
            .set('fundsPartiallyRefundedSentAt', 'PARTIALLY_REFUNDED_SENT')
            .set('fundsFullySentRefundedAt', 'FULLY_SENT_REFUNDED')
            .set('fundsFullyRefundedAt', 'FULLY_REFUNDED')
            .set('fundsFullyRefundedSentAt', 'FULLY_REFUNDED_SENT')
            .set('fundsFullySentAt', 'FULLY_SENT')
            .set('fundsSentAt', 'SENT')]
    ]))) {
        mapings.forEach((status, statusHistoryProperty) => {
            const transactionHistoryRecord = transactionHistoryCollection.findLast(historyRecord =>
                Object.is(historyRecord[statusHolderProperty], status));

            if (transactionHistoryRecord) {
                statusesHistory[statusHistoryProperty] = transactionHistoryRecord.updatedAt;
                return;
            }

            if (Object.is(transaction[statusHolderProperty], status)) {
                if (status === 'REJECT') {
                    statusesHistory[statusHistoryProperty] = transaction.createdAt;
                    return;
                }

                statusesHistory[statusHistoryProperty] = transaction.updatedAt;
            }
        });
    }

    return statusesHistory;
};
