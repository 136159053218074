import { memo } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Label = ({ title, children }: BoxProps) => (
    <Box
        sx={{
            display: 'grid'
        }}
    >
        <Box>{title}</Box>
        <Typography
            variant='subtitle2'
        >
            {children}
        </Typography>
    </Box>
);

export default memo(Label);
