import { useEffect, useRef } from "react";
import { compose } from "@reduxjs/toolkit";
import type { MerchantsMethodsPivot } from "features/pivots/types";
import { useFeatureFiltersContext } from "ui/widgets/Filters";
import useMerchantDetailsTabContext from "../useMerchantDetailsTabContext";
import type { MerchantPaymentMethodsRepository } from "./repository";

export type WithDecorators = {
    readonly decorators?: Array<(entityCollection: MerchantsMethodsPivot[]) => MerchantsMethodsPivot[]>;
};

export default function useMerchantPaymentMethods() {
    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();
    const repositoryRef = useRef(repository);
    repositoryRef.current = repository;

    const { withFilters } = useFeatureFiltersContext();

    useEffect(() => {
        repositoryRef.current
            .fetchMerchantsMethods();
    }, []);

    const getMerchantPaymentMethodsEntries = ({
        decorators = [withFilters]
    }: WithDecorators = {}) => Object.entries<Array<MerchantsMethodsPivot>>(
        (Object as any).groupBy(
            compose(...decorators)(repository.merchantsMethods),
            ({ paymentMethodName, paymentMethodTag }: MerchantsMethodsPivot) => paymentMethodTag || paymentMethodName
        )
    );

    const getMerchantPaymentMethods = (withDecorators?: WithDecorators) =>
        getMerchantPaymentMethodsEntries(withDecorators)
            .flatMap(([_, methods]) => methods);

    return {
        repository,
        getMerchantPaymentMethodsEntries,
        getMerchantPaymentMethods
    };
};
