import { memo, useEffect, useState, type MouseEvent } from "react";
import type { SimplePaginator } from "types";
import Box, { type BoxProps } from "@mui/material/Box";
import IconButton, { type IconButtonProps } from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type PaginationHandler = (event: MouseEvent<HTMLButtonElement>, page?: number) => void;

type Props = BoxProps
    & Partial<
        Pick<
            SimplePaginator<unknown>,
            | 'current_page'
            | 'has_more_pages'
        >
    >
    & {
        readonly onPrevClick: PaginationHandler;
        readonly onNextClick: PaginationHandler;
        readonly loading?: boolean;
        readonly IconButtonProps?: IconButtonProps;
        readonly CurrentPageLabelProps?: BoxProps;
    };

const Simple = ({
    IconButtonProps,
    CurrentPageLabelProps,
    onPrevClick,
    onNextClick,
    current_page: currentPage = 1,
    has_more_pages: hasMorePages = false,
    loading = false,
    ...restProps
}: Props) => {
    const [page, setPage] = useState(currentPage);

    const handlePrevClick = (event: MouseEvent<HTMLButtonElement>) => {
        setPage(prevPage => prevPage - 1);
        onPrevClick(event, page - 1);
    };

    const handleNextClick = (event: MouseEvent<HTMLButtonElement>) => {
        setPage(prevPage => prevPage + 1);
        onNextClick(event, page + 1);
    };

    useEffect(() => {
        setPage(currentPage);
    }, [currentPage]);

    return (
        <Box
            {...restProps}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                ...restProps.sx
            }}
        >
            <IconButton
                size='small'
                {...IconButtonProps}
                disabled={loading || (currentPage <= 1)}
                onClick={handlePrevClick}
            >
                <ArrowBackIcon />
            </IconButton>
            <Box
                component='span'
                {...CurrentPageLabelProps}
                sx={{
                    fontSize: 14,
                    fontWeight: 'bold',
                    color: 'common.white',
                    bgcolor: 'primary.main',
                    borderRadius: '50%',
                    width: 32,
                    height: 32,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...CurrentPageLabelProps?.sx
                }}
            >
                {currentPage}
            </Box>
            <IconButton
                size='small'
                {...IconButtonProps}
                disabled={loading || !hasMorePages}
                onClick={handleNextClick}
            >
                <ArrowForwardIcon />
            </IconButton>
        </Box>
    );
};

export default memo(Simple);
