import { memo } from "react";
import CodeSnippet from "ui/molecules/CodeSnippet";
import type { SpecInParamsValueProps } from "./types";
import SpecInParamsValue from "./SpecInParamsValue";

const SpecSpecInParamsValueJson = ({
    specInKey,
    specInValue
}: SpecInParamsValueProps) => {
    if (!specInValue) {
        return null;
    }

    return (
        <SpecInParamsValue
            title={specInKey}
        >
            <CodeSnippet
                sx={{
                    '& pre': {
                        my: 0
                    }
                }}
                data={specInValue}
                fallback={[]}
            />
        </SpecInParamsValue>
    );
};

export default memo(SpecSpecInParamsValueJson);
