import { compareIds } from "util/support";
import type { MerchantSettings } from "features/merchants/types";
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import useMerchantDetailsTabContext from "../useMerchantDetailsTabContext";
import { MerchantFeesAndCommisionsRepository } from "./repository";
import { useMerchantContext } from "../MerchantProvider";

export default function useMerchantFeesAndCommisions() {
    const merchant = useMerchantContext();

    const repository = useMerchantDetailsTabContext<MerchantFeesAndCommisionsRepository>();

    const getMerchantPayoutIncrementTaxes = () => (
        repository
            .merchantPayoutIncrementTaxes
            .filter(({ merchantId }) => compareIds(merchantId, merchant.coreId))
    );

    const getGeneralFees = () => getMerchantPayoutIncrementTaxes()
        .filter(({ tag }) => !tag);

    const getMethodFees = () => getMerchantPayoutIncrementTaxes()
        .filter(({ tag }) => tag);

    const updateGeneralFee = (values: Partial<MerchantSettings>) =>
        repository.updateMerchantSettings({
            ...values,
            coreId: repository.merchantSettings?.coreId
        });

    const addMerchantFeesAndCommisions = (merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxes) =>
        repository.addMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes);

    const updateMerchantFeesAndCommisions = (merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxes) =>
        repository.updateMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes);

    const deleteMerchantFeesAndCommisions = (merchantPayoutIncrementTaxes: MerchantPayoutIncrementTaxes) =>
        repository.deleteMerchantPayoutIncrementTaxes(merchantPayoutIncrementTaxes);

    const groupIncrementTaxesBy = ({
        groupByKey: propKey,
        incrementTaxes = getMerchantPayoutIncrementTaxes()
    }: {
        readonly groupByKey: keyof MerchantPayoutIncrementTaxes,
        readonly incrementTaxes?: Array<MerchantPayoutIncrementTaxes>;
    }) => (
        (Object as any).groupBy(
            incrementTaxes,
            ({ [propKey]: groupKey }: MerchantPayoutIncrementTaxes) => groupKey
        )
    );

    return {
        repository,
        groupIncrementTaxesBy,
        getGeneralFees,
        getMethodFees,
        updateGeneralFee,
        addMerchantFeesAndCommisions,
        updateMerchantFeesAndCommisions,
        deleteMerchantFeesAndCommisions
    };
};
