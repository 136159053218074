import { memo } from "react";
import { range } from "util/support";
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import Fee, { type FeeProps } from "./Fee";

export type FeesProps = FeeProps<{
    readonly incrementTaxes: Array<MerchantPayoutIncrementTaxes>;
}>;

const Fees = ({
    start,
    end,
    currency,
    incrementTaxes
}: FeesProps) => (
    <>
        {incrementTaxes
            .filter(({ refColFromMinSum }) => range.isBetween(refColFromMinSum, { start, end }))
            .map((merchantPayoutIncrementTaxes, index) => (
                <Fee
                    key={String(index)}
                    start={start}
                    end={end}
                    currency={currency}
                    merchantPayoutIncrementTaxes={merchantPayoutIncrementTaxes}
                />
            ))}
    </>
);

export default memo(Fees);
