import { memo } from "react";
import jsonFormatHighlight from 'json-format-highlight';
import Box, { type BoxProps } from "@mui/material/Box";
import { parse } from "util/parsers";
import Loader from "ui/molecules/Loader";

export type CodeSnippetProps = BoxProps & {
    readonly data: any;
    readonly fallback?: unknown;
    readonly isLoading?: boolean;
};

const CodeSnippet = ({
    sx,
    data,
    fallback = null,
    isLoading = !data
}: CodeSnippetProps) =>{
    
    const formattedData = typeof data === "string" 
    ? data 
    : JSON.stringify(data, null, 2)
    
    return  (
    <Loader
        isLoading={isLoading}
    >
        <Box
            sx={{
                backgroundColor: '#f5f5f5',
                borderRadius: 2,
                border: '1px solid #ccc',
                overflow: 'auto',
                p: 1,
                '& pre span': {
                    whiteSpace: 'nowrap !important'
                },
                ...sx
            }}
        >
            <pre
                dangerouslySetInnerHTML={{
                    __html: jsonFormatHighlight(
                        parse(formattedData, fallback)
                    )
                }}
            />
        </Box>
    </Loader>
)};

export default memo(CodeSnippet);
