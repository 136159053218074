import { ResponseCode } from "consts/api";
import { trimObjectValues } from "util/support";
import { NotificationSeverity } from "features/general/types";
import useActions from "features/general/useActions";
import {
    useFormik,
    type FormikConfig,
    type FormikHelpers,
    type FormikValues
} from "formik";
import type { Form, ResourceResponse } from "types";
import { transformErrorResponseIntoMessage } from "util/transformers";

type FormRequestHandler<T> = (payload: ResourceResponse<T>) => void;

export type UseFormArg<TFormValues extends FormikValues, TFormRequestHandlerPayload = TFormValues> =
    & Omit<FormikConfig<TFormValues>, 'onSubmit'>
    & Pick<Form<TFormValues>, 'onSaveOrCreate'>
    & {
        readonly onResolve?: FormRequestHandler<TFormRequestHandlerPayload>;
        readonly onReject?: FormRequestHandler<TFormRequestHandlerPayload>;
    };

export default function useForm<TFormValues extends FormikValues, TFormRequestHandlerPayload = TFormValues>({
    onSaveOrCreate,
    onResolve,
    onReject,
    validationSchema,
    ...formikSettings
}: UseFormArg<TFormValues, TFormRequestHandlerPayload>) {
    const { showNotication } = useActions();

    return useFormik({
        validateOnMount: true,
        ...formikSettings,
        initialValues: trimObjectValues<TFormValues, TFormValues>(formikSettings.initialValues),
        validationSchema,
        onSubmit: async (values, formikHelpers) => {
            const [, requestPromise] = await requestAdapter(
                values => onSaveOrCreate(values, formikHelpers as FormikHelpers<typeof values>),
                trimObjectValues(values)
            );

            const { payload } = await requestPromise;

            if (payload.success) {
                showNotication({
                    message: payload.data?.message ?? payload.message,
                    severity: Boolean(payload.data?.message)
                        ? NotificationSeverity.Info
                        : NotificationSeverity.Success
                });

                onResolve?.(payload);
                return;
            }

            if (payload.code === ResponseCode.UnprocessibleEntity) {
                showNotication({
                    message: transformErrorResponseIntoMessage(payload.data),
                    severity: NotificationSeverity.Error
                });
            }

            onReject?.(payload);
        },
    });
};

export class FormAdapter {
    public static fromResponse<T>(response: T) {
        return {
            payload: response
        };
    }
}

// We need to adapt thunk requests with regular fetch requests
async function requestAdapter<T>(request: (fields: Partial<T>) => any, fields: Partial<T>) {
    const req = request(fields);
    if (req instanceof Promise) {
        return [
            req,
            await req.then((res: Response | unknown) => {
                if (res instanceof Response) {
                    return res.json();
                }

                return res;
            })
        ];
    }

    return req;
}
