import { memo } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import DashboardOutletWrapper from "./DashboardOutletWrapper";

const DashboardOutletTarget = () => (
    <DashboardOutletWrapper>
        <Outlet />
        <ScrollRestoration />
    </DashboardOutletWrapper>
);

export default memo(DashboardOutletTarget);
