import { createContext, type PropsWithChildren } from "react";
import type { UnsavedChangesContextType } from "./types";
import useUnsavedChanges from "./useUnsavedChanges";
import ConfirmationDialog from "./ConfirmationDialog";

export const UnsavedChangesContext = createContext<UnsavedChangesContextType>({} as UnsavedChangesContextType);

const UnsavedChanges = ({ children }: PropsWithChildren) => (
    <UnsavedChangesContext.Provider
        value={useUnsavedChanges()}
    >
        {children}
        <ConfirmationDialog />
    </UnsavedChangesContext.Provider>
);

export default UnsavedChanges;
