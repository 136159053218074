import { MULTI_COLUMN_SEPARATOR } from "consts/filters";
import { Option } from "util/option";
import { concat } from "./support";

export function splitWithModifier(value: string, separator = ':') {
    return value.split(separator);
}

export function booleanFilterFactory([falsyLabel, truthyLabel]: [string, string]) {
    return [
        Option.make(0, falsyLabel),
        Option.make(1, truthyLabel)
    ];
}

export const multiColumnFilterFactory = <T extends string>(columnCollection: Array<T>) =>
    concat(columnCollection, { token: MULTI_COLUMN_SEPARATOR });
