import {
    memo,
    forwardRef,
    useImperativeHandle,
    PropsWithChildren
} from "react";
import { useModal, type DialogCloseHandler } from "ui/atoms/Modal";
import Basic from "./Basic";
import type { TriggerableDialogProps } from "./Props";

const Triggerable = forwardRef(({
    children,
    TriggerComponent,
    DialogActionsComponent,
    ModalProps = {}
}: PropsWithChildren<TriggerableDialogProps>, ref) => {
    const { open, onOpen, onClose } = useModal();

    useImperativeHandle(ref, () => ({
        onOpen,
        onClose
    }), [onOpen, onClose]);

    const handleOpen = () => {
        onOpen();
    };

    const handleClose: DialogCloseHandler = (...args) => {
        try {
            ModalProps.onClose?.(...args);
            onClose();
        } catch { }
    };

    return (
        <>
            <TriggerComponent
                onClick={handleOpen}
                isOpen={open}
            />
            <Basic
                {...ModalProps}
                isOpen={open}
                onClose={handleClose}
                actionsSlot={(
                    <DialogActionsComponent
                        onClose={handleClose}
                    />
                )}
            >
                {children}
            </Basic>
        </>
    );
});

export default memo(Triggerable);
