import { memo } from "react";
import Box from "@mui/material/Box";
import { TaxableFrontPanelWidget } from "ui/organizms/MerchantPayouts";

const MerchantFeesAndCommisionsActions = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
        }}
    >
        <TaxableFrontPanelWidget />
    </Box>
);

export default memo(MerchantFeesAndCommisionsActions);
