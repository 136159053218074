import { compareIds } from "util/support";
import { ManagementApiResponseFactory } from "util/api";
import { stateFactory } from "./state";
import {
    MerchantFeesAndCommisionsActionType,
    type MerchantFeesAndCommisionsRepositoryAction,
    type MerchantFeesAndCommisionsRepositoryState
} from "./types";

export function reducer(state: MerchantFeesAndCommisionsRepositoryState, action: MerchantFeesAndCommisionsRepositoryAction) {
    switch (action.type) {
        case MerchantFeesAndCommisionsActionType.FetchMerchantPayoutIncrementTaxes: {
            return {
                ...state,
                ...action.payload
            };
        }

        case MerchantFeesAndCommisionsActionType.AddMerchantPayoutIncrementTaxes: {
            return {
                ...state,
                data: ManagementApiResponseFactory.makePaginator({
                    ...state.data,
                    data: [...(state.data?.data ?? []), action.payload]
                })
            };
        }

        case MerchantFeesAndCommisionsActionType.UpdateMerchantPayoutIncrementTaxes: {
            return {
                ...state,
                data:  ManagementApiResponseFactory.makePaginator({
                    ...state.data,
                    data: state.data?.data.map(merchantPayoutIncrementTaxes => {
                        if (compareIds(merchantPayoutIncrementTaxes.coreId, action.payload.coreId)) {
                            return action.payload;
                        }

                        return merchantPayoutIncrementTaxes;
                    })
                })
            };
        }

        case MerchantFeesAndCommisionsActionType.DeleteMerchantPayoutIncrementTaxes: {
            return {
                ...state,
                data: ManagementApiResponseFactory.makePaginator({
                    ...state.data,
                    data: state.data?.data.filter(merchantPayoutIncrementTaxes =>
                        !compareIds(merchantPayoutIncrementTaxes.coreId, action.payload.coreId)
                    )
                })
            };
        }

        case MerchantFeesAndCommisionsActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
};
