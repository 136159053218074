import { memo, useCallback } from "react";
import type { Moment } from 'moment';
import Badge from '@mui/material/Badge';
import type { DateRangePickerToolbarProps } from '@mui/x-date-pickers-pro-v.6/DateRangePicker';
import { StaticDateRangePicker, type StaticDateRangePickerProps } from '@mui/x-date-pickers-pro-v.6/StaticDateRangePicker';
import { MultiInputDateRangeField, type MultiInputDateRangeFieldProps } from '@mui/x-date-pickers-pro-v.6/MultiInputDateRangeField';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from '@mui/material/Divider';
import { FilterRenderTypes, type AbstractActionableFilterProps } from "ui/widgets/Filters/types";
import type { PopoverableFilterProps } from "../types";
import { PopoverableAction } from "ui/molecules/Action";
import useDateFilter from "./useDateFilter";
import { PredefinedCalendarInterval } from "util/interval";
import { UiDateTimeFormatter } from "util/formaters";
import { CustomToolbar } from "./slots";

export interface PopoverPeriodFilterProps<TDate = Moment> extends
    Omit<StaticDateRangePickerProps<TDate>, 'onChange'>,
    AbstractActionableFilterProps,
    PopoverableFilterProps {
    readonly InputDateRangeFieldProps?: Omit<MultiInputDateRangeFieldProps<TDate>, 'ref'>;
};

const PopoverPeriodFilter = ({
    id,
    onChange,
    PopoverTriggerProps,
    InputDateRangeFieldProps,
    value = [null, null],
    ...restDateRangePickerProps
}: PopoverPeriodFilterProps) => {
    const {
        isOpen,
        openPopup,
        closePopup,
        onApply,
        onClear,
        handleChange
    } = useDateFilter({
        ...restDateRangePickerProps,
        onChange,
        id,
        value
    });

    const defaultDateRangePickerProps = {
        disableFuture: true,
        localeText: {
            start: 'From',
            end: 'To'
        }
    };

    const ToolbarSlot = useCallback((props: DateRangePickerToolbarProps<Moment>) => (
        <CustomToolbar
            {...props}
            onCancel={closePopup}
        />
    ), [closePopup]);

    return (
        <Badge
            color='secondary'
            variant='dot'
            invisible={!value.some(Boolean)}
        >
            <PopoverableAction
                label='Period'
                {...PopoverTriggerProps}
                id={id}
                onClick={openPopup}
                PopoverProps={{
                    open: isOpen,
                    onClose: onApply
                }}
            >
                <StaticDateRangePicker
                    slots={{
                        toolbar: ToolbarSlot
                    }}
                    slotProps={{
                        shortcuts: {
                            items: PredefinedCalendarInterval.toArray(),
                        },
                        actionBar: {
                            actions: []
                        }
                    }}
                    calendars={2}
                    {...defaultDateRangePickerProps}
                    {...restDateRangePickerProps}
                    value={value}
                    onChange={handleChange}
                />
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2
                    }}
                >
                    <MultiInputDateRangeField
                        slotProps={{
                            textField: ({ position }) => ({
                                label: defaultDateRangePickerProps.localeText[position]
                            })
                        }}
                        disableFuture={defaultDateRangePickerProps.disableFuture}
                        format={UiDateTimeFormatter.Ui}
                        {...InputDateRangeFieldProps}
                        value={value}
                        onChange={handleChange}
                    />
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, auto)',
                            gap: 1
                        }}
                    >
                        <Button
                            variant='contained'
                            size='small'
                            color='secondary'
                            onClick={onClear}
                        >
                            Clear
                        </Button>
                        <Button
                            className={`js-apply-${FilterRenderTypes.PopoverDropdown}`}
                            variant='contained'
                            size='small'
                            color='primary'
                            onClick={onApply}
                        >
                            Apply
                        </Button>
                    </Box>
                </Box>
            </PopoverableAction>
        </Badge>
    );
};

export default memo(PopoverPeriodFilter);
