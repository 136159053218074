import { ApiState } from "infrastructure/api";
import type { Draft, WritableDraft } from "immer/dist/internal";
import type {
    AbstractFileEntity,
    IncrementExchangeRates,
    IncrementTaxes,
    MerchantPayoutsFilable,
    MerchantPayoutsState
} from "./types";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
    CoreBankingEntity,
    ID,
    ResourceResponse,
    ThunkReturnType
} from "types";
import { MerchantPayoutsTabs } from "consts/merchantPayouts";
import type { UseImportResultArg } from "ui/organizms/MerchantPayouts/Taxable/FrontPanelWidget/ImportCSV";
import { ResponseCode } from "consts/api";
import { compareIds } from "util/support";
import thunkReducerFactory from "infrastructure/store/reducers/thunkReducerFactory";
import type { ResourceSlice } from "infrastructure/store";

export const changeMultiFileMerge = (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<Draft<boolean>>
) => {
    (state[state.activeTab] as ResourceSlice<
        AbstractFileEntity,
        MerchantPayoutsFilable
    >).isMultiFileMergeEnabled = payload;
};

export const changeSelectionModel = (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<Draft<Array<ID>>>
) => {
    (state[state.activeTab] as ResourceSlice<
        AbstractFileEntity,
        MerchantPayoutsFilable
    >).selectionModel = payload;
};

export const {
    requestLoading: merchantPayoutsRequestLoading,
    requestFulfilled: merchantPayoutsRequestFulfilled,
    requestRejected: merchantPayoutsRequestRejected
} = thunkReducerFactory();

// Summary Files
// export const {
//     requestLoading: summaryFilesRequestLoading,
//     requestFulfilled: summaryFilesRequestFulfilled,
//     requestRejected: summaryFilesRequestRejected
// } = reducerFactory<SummaryFilesResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.SummaryFiles
// });

// Files
// export const {
//     requestLoading: filesRequestLoading,
//     requestFulfilled: filesRequestFulfilled,
//     requestRejected: filesRequestRejected
// } = reducerFactory<FilesResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.Files
// });

export const updateMerchantPayoutEntityById = <T extends CoreBankingEntity>(
    state: WritableDraft<MerchantPayoutsState>,
    payload: T
) => {
    const activeTab = state.activeTab;
    const tableSlice = state[activeTab].tableSlice;

    tableSlice!.data = tableSlice!.data.map(resourceEntity => {
        if (compareIds(resourceEntity.coreId, payload.coreId)) {
            return payload;
        }

        return resourceEntity;
    });
};

export const updateMerchantPayoutIncrementTaxesRequestFulfilled = (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<ThunkReturnType<ResourceResponse<IncrementTaxes>>>
) => {
    const activeTab = state.activeTab;

    if (!state[activeTab].tableSlice) {
        state[activeTab].tableLoadingState = ApiState.Idle;
        return;
    }

    updateMerchantPayoutEntityById<IncrementTaxes>(state, payload!.data);
};

export const updateMerchantPayoutResourceRequestFulfilled = <T extends CoreBankingEntity>() => (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<ThunkReturnType<ResourceResponse<T>>>
) => {
    const activeTab = state.activeTab;
    state[activeTab].tableLoadingState = ApiState.Succeeded;

    updateMerchantPayoutEntityById(state, payload!.data);
};

/*export const mergeMerchantPayoutFilableRequestFulfilled = (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<ThunkReturnType<ResourceResponse<AbstractFileEntity>>>
) => {
    const activeTab = state.activeTab;
    const tableSlice = state[activeTab].tableSlice;

    state[activeTab].tableLoadingState = ApiState.Succeeded;
    tableSlice!.data = tableSlice!.data.map(filableEntity => {
        if (filableEntity.coreId === payload!.data.coreId) {
            return payload!.data as AbstractFileEntity;
        }

        return filableEntity;
    });
};*/

// Calculations
// export const {
//     requestLoading: calculationsRequestLoading,
//     requestFulfilled: calculationsRequestFulfilled,
//     requestRejected: calculationsRequestRejected
// } = reducerFactory<CalculationsResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.Calculations
// });

// FileTaxCalculations
// export const {
//     requestLoading: fileTaxCalculationsRequestLoading,
//     requestFulfilled: fileTaxCalculationsRequestFulfilled,
//     requestRejected: fileTaxCalculationsRequestRejected
// } = reducerFactory<FileTaxCalculationsResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.FileTaxCalculations
// });

// IncrementsPivot
// export const {
//     requestLoading: incrementsPivotRequestLoading,
//     requestFulfilled: incrementsPivotRequestFulfilled,
//     requestRejected: incrementsPivotRequestRejected
// } = reducerFactory<IncrementsPivotResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.IncrementsPivot
// });

// IncrementsTaxCalculations
// export const {
//     requestLoading: incrementsTaxCalculationsRequestLoading,
//     requestFulfilled: incrementsTaxCalculationsRequestFulfilled,
//     requestRejected: incrementsTaxCalculationsRequestRejected
// } = reducerFactory<IncrementTaxesResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.IncrementsTaxCalculations
// });

// Increments
// export const {
//     requestLoading: incrementsRequestLoading,
//     requestFulfilled: incrementsRequestFulfilled,
//     requestRejected: incrementsRequestRejected
// } = reducerFactory<IncrementsResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.Increments
// });

/*export const putMerchantPayoutFilesRequestFulfilled = (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<ThunkReturnType<ResourceResponse<Increments>>>
) => {
    const tableSlice = state[MerchantPayoutsTabs.Increments].tableSlice;
    tableSlice!.data = tableSlice!.data.map(incrementsEntity => {
        if (incrementsEntity.coreId === payload!.data.coreId) {
            return payload!.data;
        }

        return incrementsEntity;
    });
}*/

// Increment Exchange Rates
// export const {
//     requestLoading: incrementExchangeRatesRequestLoading,
//     requestFulfilled: incrementExchangeRatesRequestFulfilled,
//     requestRejected: incrementExchangeRatesRequestRejected
// } = reducerFactory<IncrementExchangeRatesResponse>({
//     merchantPayoutTab: MerchantPayoutsTabs.IncrementExchangeRates
// });

export const createIncrementExchangeRatesRequestFulfilled = (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<ThunkReturnType<ResourceResponse<IncrementExchangeRates>>>
) => {
    state[MerchantPayoutsTabs.IncrementExchangeRates].tableLoadingState = ApiState.Succeeded;
    state[MerchantPayoutsTabs.IncrementExchangeRates].tableSlice!.data.unshift(payload!.data);
    state[MerchantPayoutsTabs.IncrementExchangeRates].tableSlice!.to += 1;
    state[MerchantPayoutsTabs.IncrementExchangeRates].tableSlice!.total += 1;
};

export const deleteRowRequestFulfilled = (
    state: WritableDraft<MerchantPayoutsState>,
    { meta }: PayloadAction<ThunkReturnType<null>, string, {
        readonly arg: ID;
        readonly requestId: string;
        readonly requestStatus: "fulfilled";
    }, never>
) => {
    const activeTab = state.activeTab;

    if (!state[activeTab].tableSlice) {
        state[activeTab].tableLoadingState = ApiState.Idle;
        return;
    }

    state[activeTab].tableLoadingState = ApiState.Succeeded;
    state[activeTab].tableSlice!.data = state[activeTab]
        .tableSlice!
        .data
        .filter(({ coreId }) => !compareIds(coreId, meta.arg));

    state[activeTab].tableSlice!.to -= 1;
    state[activeTab].tableSlice!.total -= 1;
};

export const createIncrementExchangeRatesBulkRequestFulfilled = (
    state: WritableDraft<MerchantPayoutsState>,
    { payload }: PayloadAction<ThunkReturnType<UseImportResultArg>>
) => {
    const successfulRows = payload!
        .data
        .filter(({ statusCode }) => statusCode === ResponseCode.Created)
        .map(({ content }) => content);

    state[MerchantPayoutsTabs.IncrementExchangeRates].tableLoadingState = ApiState.Succeeded;
    state[MerchantPayoutsTabs.IncrementExchangeRates].tableSlice!.data.unshift(...successfulRows as IncrementExchangeRates[]);
    state[MerchantPayoutsTabs.IncrementExchangeRates].tableSlice!.to += successfulRows.length;
    state[MerchantPayoutsTabs.IncrementExchangeRates].tableSlice!.total += successfulRows.length;
};

// function reducerFactory<TResponse extends PaginateResourceResponse<Array<CoreBankingEntity>>>() {
//     return {
//         requestLoading: (state: WritableDraft<MerchantPayoutsState>) => {
//             const activeTab = state.activeTab;
//             state[activeTab].tableLoadingState = ApiState.Pending;
//         },
//         requestFulfilled: (
//             state: WritableDraft<MerchantPayoutsState>,
//             { payload }: PayloadAction<ThunkReturnType<TResponse>>
//         ) => {
//             const activeTab = state.activeTab;
//             state[activeTab].tableLoadingState = ApiState.Succeeded;
//             state[activeTab].tableSlice = payload!.data;
//         },
//         requestRejected: (
//             state: WritableDraft<MerchantPayoutsState>,
//             { payload }: PayloadAction<unknown>
//         ) => {
//             const activeTab = state.activeTab;
//             state[activeTab].tableLoadingState = setApiState(
//                 payload as ResourceResponse<null>,
//                 ApiState.Failed
//             );
//         }
//     };
// }
