import type { ID, Option as OptionType, Optionable, Scalar } from "types";
import { compareIds } from "./support";

export class Option {
    public static make(id: ID, name?: Scalar<string>): OptionType {
        return {
            id,
            name: String(name ?? id)
        };
    }
}

export interface IOptionableModel<TModel extends object> {
    initMultiOptionValues(initialValues: Optionable<TModel, Scalar<ID>>): this;

    initSingleOptionValues(initialValues: Optionable<TModel, Scalar<ID>>): this;

    withOptionable(optionable: Optionable<TModel>): TModel;
}

export abstract class OptionableModel<TModel extends object> {
    public initMultiOptionValues(this: Optionable<TModel, OptionType[]>, initialValues: Optionable<TModel, Scalar<ID>>) {
        for (const key in initialValues) {
            this[key] = [Option.make(initialValues[key] as ID)];
        }

        return this as IOptionableModel<TModel>;
    }

    public initSingleOptionValues(this: Optionable<TModel, OptionType>, initialValues: Optionable<TModel, Scalar<ID>>) {
        for (const key in initialValues) {
            this[key] = Option.make(initialValues[key] as ID);
        }

        return this as IOptionableModel<TModel>;
    }

    public withOptionable(this: Optionable<TModel, OptionType | OptionType[]>, optionable: Optionable<TModel>) {
        for (const key in optionable) {
            this[key] = Array.isArray(this[key])
                ? getOptions(optionable[key], toIds(this[key]))
                : getOptionById(optionable[key], getOptionId(this[key])) ?? this[key];
        }

        return this as IOptionableModel<TModel>;
    }
}

export const isOption = (option: unknown): option is OptionType => (
    option instanceof Object &&
    ('id' in option) &&
    ('name' in option)
);

export const getOptionName = (option: OptionType | undefined, defaultValue: string = '') =>
    option?.name ?? defaultValue;

export const getOptionId = <TReturn extends unknown>(option?: unknown) => (
    isOption(option)
        ? option.id
        : option
) as TReturn;

export const isOptionEqualToValue = (option: OptionType, value: OptionType) =>
    compareIds(option.id, value.id);

export const getOptionById = (options: Array<OptionType>, id: OptionType['id'] | undefined | null) =>
    options.find(option => compareIds(option.id, `${id}`));

export const getOptions = (options: Array<OptionType>, ids: Array<ID>) =>
    options.reduce((acc: OptionType[], option) => {
        if (ids.some(id => compareIds(id, option.id))) {
            return [
                ...acc,
                option
            ];
        }
        return acc;
    }, []);

export const toIds = <TArray>(collection?: TArray) =>
    [collection]
        .flat()
        .filter((item: any) => ![null, undefined].includes(item))
        .map(getOptionId) as ID[];
