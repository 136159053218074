import { memo } from "react";
import type { ResourceResponse } from "types";
import type { IncrementTaxes as MerchantPayoutsIncrementTaxes } from "features/merchantPayouts/types";
import { AddIncrementTaxesAction } from "ui/organizms/MerchantPayouts";
import useMerchantFeesAndCommisions from "../useMerchantFeesAndCommisions";

const AddMerchantFeeDialog = () => {
    const { addMerchantFeesAndCommisions } = useMerchantFeesAndCommisions();

    const onResolve = ({ data }: ResourceResponse<MerchantPayoutsIncrementTaxes>) =>
        addMerchantFeesAndCommisions(data);

    return (
        <AddIncrementTaxesAction
            onResolve={onResolve}
        />
    );
};

export default memo(AddMerchantFeeDialog);
