import { Filters } from "consts/merchants";
import { fromCoreBankingCollectionToOptions } from "util/transformers";
import { useFiltersModel } from "ui/widgets/Filters";
import useMerchantPaymentMethods from "../useMerchantPaymentMethods";

export default function useMerchantPaymentMethodsFiltersModel() {
    const { getMerchantPaymentMethods } = useMerchantPaymentMethods();

    const fromMerchantsMethods = fromCoreBankingCollectionToOptions(getMerchantPaymentMethods());

    const selectTags = () => fromMerchantsMethods('paymentMethodTag');

    const selectPSPs = () => fromMerchantsMethods('paymentMethodPSP');

    const selectMORs = () => fromMerchantsMethods('MOR');

    return useFiltersModel({
        [Filters.paymentMethodTag]: selectTags(),
        [Filters.paymentMethodPSP]: selectPSPs(),
        Domains: selectMORs()
    });
};
