import { memo, useState, type MouseEvent } from "react";
import { SimpleTablePagination } from "ui/widgets/Table";
import type { MerchantPaymentMethodsRepository } from "../../repository";
import useMerchantDetailsTabContext from "../../../useMerchantDetailsTabContext";
import { useMerchantPaymentMethodContext } from "./MerchantPaymentMethodProvider";

type Props = {
    readonly initialPage?: number;
};

const MerchantPaymentMethodHistoryPaginator = ({ initialPage = 1 }: Props) => {
    const { methodId } = useMerchantPaymentMethodContext();
    const repository = useMerchantDetailsTabContext<MerchantPaymentMethodsRepository>();

    const [loading, setLoading] = useState(false);

    const onPaginate = (_: MouseEvent<HTMLButtonElement>, page?: number) => {
        setLoading(true);

        repository.fetchMerchantsMethodsHistory({ methodId, page })
            .finally(() => setLoading(false));
    };

    const {
        current_page: currentPage = initialPage,
        has_more_pages: hasMorePages = false
    } = repository.merchantsMethodsHistoryPaginator || {};

    if (Object.is(currentPage, initialPage) && !hasMorePages) {
        return null;
    }

    return (
        <SimpleTablePagination
            loading={loading}
            current_page={currentPage}
            has_more_pages={hasMorePages}
            onNextClick={onPaginate}
            onPrevClick={onPaginate}
        />
    );
};

export default memo(MerchantPaymentMethodHistoryPaginator);
