import type { FC } from "react";
import type { Moment } from "moment";
import type { BoxProps } from "@mui/material/Box";
import type { DateRange } from '@mui/x-date-pickers-pro';
import type { ArrayableValue, Indexed, Option } from 'types';
import type { TransactionsFilterModelWithCustomFields } from "features/transactions/types";
import type { MetaConfigSettings } from "ui/widgets/Filters/hooks/useFiltersConfigParser";
import type { Adapters } from "../Table";

export enum FilterRenderTypes {
    Dropdown = 'dropdown',
    PopoverDropdown = 'popoverDropdown',
    PopoverPeriodDropdown = 'popoverPeriodDropdown',
    DateRange = 'daterange',
    DatePicker = 'datepicker',
    TextField = 'textField',
    PopoverTextField = 'popoverTextField',
    NumericRange = 'numericRange'
};

export type FilterType<T> = T;// keyof typeof Filters;

export type Filter<T = AbstractFilterProps> = {
    readonly renderAs: FilterRenderTypes;
    readonly props: T;
    readonly meta?: MetaConfigSettings;
    readonly defaultValue?: any;
};

export type FilterParams<V = string> = {
    readonly [key: keyof TransactionsFilterModelWithCustomFields]: V;
};

export interface AbstractFilterProps {
    readonly id: string; // keyof typeof Filters;
    readonly name: string;
}

export type FilterChangePayload =
    | string
    | number
    | Option
    | Array<Option>
    | Moment
    | DateRange<Moment>
    | null
    | Array<number | string>;

export type FilterSingleValue =
    | string
    | number
    | Option
    | Moment
    | null;

export enum Environment {
    Browser,
    Server
}

// export type FilterMeta = {
//     readonly modifiers: 'contains'
// };

export type ChangePayload<
    TFilter = string,
    TPayload = unknown
> = {
    readonly type: TFilter;
    readonly payload: TPayload;
    readonly meta?: DOMStringMap;
};

export interface SerializableValue<T> {
    serialize(value: T, environment?: Environment): string;
}

export abstract class FilterValueObject<
    T extends FilterChangePayload = FilterChangePayload,
    TSingleValue extends FilterSingleValue = FilterSingleValue
> implements SerializableValue<TSingleValue>, ArrayableValue<T> {

    public constructor(
        public value: T,
        public meta?: DOMStringMap
    ) { }

    public abstract toArray(): T extends Array<unknown> ? T : T[];

    public abstract serialize(value: TSingleValue, environment?: Environment, index?: number): string;

    public abstract isEmpty(value: T, index?: number): boolean;

    public abstract isValueEmpty(): boolean;
}

// export type FiltersChangeHandler = (filterName: keyof typeof Filters, payload: FilterChangePayload) => void;
export type FiltersChangeHandler = (
    changeEventPayload: ChangePayload<
        FilterType<string>,
        FilterChangePayload
    >
) => void;
export interface AbstractActionableFilterProps extends AbstractFilterProps {
    readonly onChange: FiltersChangeHandler;
    // (filterId: string, option: Object) => void;
}

/*export enum FilterTypes {
    DateRange = 'createdAt',
    Merchant = 'merchantId',
    PaymentMethod = 'methodId',
    CsvSettlementStatus = 'settlementStatus',
    TransactionFundStatus = 'fundStatus',
    TransactionPayoutStatus = 'isPaid',
    TransactionStatus = 'status',
    TransactionId = 'transactionId',
    RefId = 'refId',
    ConsumerId = 'consumerId',
    TrackingMemberCode = 'trackingMemberCode',
    TransactionGuid = 'transactionGuid'
}*/

export enum ValueObjectStrategy {
    SearchQuery = 'searchQuery',
    Change = 'change'
}

export type FiltersWidgetComponentProps = {
    readonly filters: Filter<AbstractFilterProps>[];
    readonly isFiltersOpen: boolean;
};

export type SummableCardProps = {
    readonly amount: number | string;
    readonly currency: string;
    readonly rootProps?: BoxProps;
    readonly currencySlotProps?: BoxProps;
    readonly amountSlotProps?: BoxProps;
};

export type WidgetProps = {
    readonly Component?: FC<FiltersWidgetComponentProps>;
    readonly filters: Array<Filter>;
    readonly isFiltersOpen: boolean;
};

export type FiltersProps = {
    readonly filters: Array<Filter<AbstractFilterProps>>;
    readonly onChange: FiltersChangeHandler;
    readonly onReset: () => void;
    readonly onApply: () => void;
    readonly sx?: BoxProps['sx'];
    readonly useVisibilityRules?: (filters: Filter<AbstractFilterProps>[]) =>
        (filter: Filter<AbstractFilterProps>) => boolean;
    readonly useFilterPropsOverride?: (filters: Filter<AbstractFilterProps>[]) =>
        (filter: Filter<AbstractFilterProps>) => Filter<AbstractFilterProps>;
    readonly SummableWidget?: WidgetProps['Component'];
    readonly FrontPanelWidget?: WidgetProps['Component'];
    readonly filtersModelOverrides?: Indexed<Record<string, unknown>>;
    readonly adapters?: Adapters;
};
