import { memo } from "react";
import type { PaymentMethod } from "features/paymentMethods/types";
import Label from "./Label";
import { Box, Tooltip } from "@mui/material";

const PaymentMethodCurrencies = ({ forCurrencies }: PaymentMethod) => (
    <Label
        title='Currencies'
    > <Tooltip title={forCurrencies}>
            <Box
                sx={{
                    maxWidth: '100px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}
            >
                {forCurrencies}
            </Box>
        </Tooltip>
    </Label>
);

export default memo(PaymentMethodCurrencies);