import { memo, type PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Account from "../Account";

type Props = {
    readonly tag: string;
};

const PaymentMethod = ({ tag, children }: PropsWithChildren<Props>) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, min-content)',
            alignItems: 'center',
            gap: 2
        }}
    >
        <Account
            CustomAvatarProps={{
                name: tag,
                alt: tag
            }}
        >
            <Typography
                variant='h5'
                sx={{
                    textDecoration: 'capitalize'
                }}
            >
                {tag}
            </Typography>
        </Account>
        {children}
    </Box>
);

export default memo(PaymentMethod);
