import { memo } from "react";
import { useFormik } from "formik";
import type { BooleanSwitch } from "types";
import MuiCheckbox, { type CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, { type FormControlLabelProps } from "@mui/material/FormControlLabel";

type Props =
    & Omit<FormControlLabelProps, 'control'>
    & Pick<
        ReturnType<typeof useFormik>,
        | 'handleBlur'
        | 'setFieldTouched'
        | 'setFieldValue'
    >
    & {
        readonly CheckboxProps: Omit<CheckboxProps, 'name' | 'checked'> & {
            readonly name: string;
            readonly checked: boolean | BooleanSwitch;
        };
    };

const Checkbox = ({
    handleBlur,
    setFieldTouched,
    setFieldValue,
    CheckboxProps,
    ...restProps
}: Props) => {
    const name = `${CheckboxProps.name}`;
    const castValue = (value: boolean) =>
        (typeof CheckboxProps.checked === 'number')
            ? Number(value)
            : value;

    return (
        <FormControlLabel
            {...restProps}
            sx={{
                whiteSpace: 'nowrap',
                ...restProps.sx
            }}
            control={(
                <MuiCheckbox
                    {...CheckboxProps}
                    checked={Boolean(CheckboxProps.checked)}
                    onBlur={handleBlur}
                    onChange={(_, value) => {
                        setFieldTouched(name, true);
                        setFieldValue(
                            name,
                            castValue(value),
                            true
                        );
                    }}
                />
            )}
        />
    );
};

export default memo(Checkbox);
