import { useState } from "react";
import Cookie, { type CookieAttributes } from "js-cookie";

type UseCookieStateArg<TValue = unknown> = {
    readonly key: string;
    readonly defaultValue?: TValue;
};

export default function useCookieState<TValue = unknown>({
    key,
    defaultValue
}: UseCookieStateArg<TValue>) {
    const [state, setState] = useState(() => Cookie.get(key) ?? defaultValue);

    const updateState = (
        value: TValue,
        attributes?: CookieAttributes & {
            readonly strategy?: 'remove' | 'set';
        }
    ) => {
        switch (attributes?.strategy) {
            case 'remove': {
                Cookie.remove(key, attributes);
                break;
            }

            default: {
                Cookie.set(key, `${value}`, attributes);
            }
        }

        setState(value);
    };

     return [state, updateState] as const;
};
