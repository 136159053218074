import Autocomplete from "./Autocomplete";
import { getOptionName, isOptionEqualToValue } from "util/option";
import type { AutocompleteProps, OptionableAutocompleteProps } from "./types";

const OptionableAutocomplete = ({
    component = Autocomplete,
    ...props
}: OptionableAutocompleteProps) => {
    const handleChange: AutocompleteProps['onChange'] = (_, value) =>
        props.onChange(value);

    return (
        <Autocomplete
            {...props}
            onChange={handleChange}
            getOptionLabel={getOptionName}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
};

export default OptionableAutocomplete;
