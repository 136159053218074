import { memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { BasicDialog } from "ui/molecules/Dialog";
import ActionPanel from "ui/molecules/ActionPanel";
import type { PaymentMethodSwitchComponentProps } from "./types";
import MerchantPaymentMethodHistory from "../../MerchantPaymentMethodHistory";

const PaymentMethodHistoryConfirmationDialog = ({
    paymentMethodSwitch,
    merchantMethod
}: PaymentMethodSwitchComponentProps) => {
    const {
        isLoading,
        isOpen,
        onCancel,
        onConfirm,
        onClick
    } = paymentMethodSwitch;

    return (
        <BasicDialog
            isOpen={isOpen}
            titleSlot='Method History'
            onClose={onCancel}
            DialogProps={{
                onClick
            }}
            DialogContentProps={{
                sx: {
                    pb: '0 !important'
                }
            }}
            DialogActionsProps={{
                sx: {
                    pt: '0 !important',
                    '& > div': {
                        width: '100%'
                    }
                }
            }}
            actionsSlot={(
                <Box
                    sx={{
                        borderTop: 1,
                        borderColor: 'divider',
                        borderTopStyle: 'solid',
                        width: '100%'
                    }}
                >
                    <Typography
                        variant='h6'
                        sx={{
                            textAlign: 'center',
                            my: 3
                        }}
                    >
                        Do you want to save changes?
                    </Typography>
                    <ActionPanel
                        sx={{
                            borderTop: 0,
                            mt: 0,
                            pt: 0,
                            justifyContent: 'center'
                        }}
                        CancelActionProps={{
                            size: 'large'
                        }}
                        SaveActionProps={{
                            size: 'large'
                        }}
                        isLoading={isLoading}
                        onCancel={onCancel}
                        onSave={onConfirm}
                    />
                </Box>
            )}
        >
            <MerchantPaymentMethodHistory
                forceRefetch={isOpen}
                merchantMethod={merchantMethod}
                DataGridPremiumProps={{
                    sx: {
                        height: 'calc(100vh - 300px)'
                    }
                }}
            />
        </BasicDialog>
    );
};

export default memo(PaymentMethodHistoryConfirmationDialog);