import { useMemo } from "react";
import { object, string } from "yup";

export const enum FormField {
  Email = 'email',
  Password = 'password'
}

const useValidationSchema = () =>
  useMemo(
    () =>
      object().shape({
        [FormField.Email]: string()
          .email("Invalid email")
          .required("Email required"),
        [FormField.Password]: string()
          .min(6, "Password length not enough")
          .max(50, "You are crazy?")
          .required("Password required"),
      }),
    []
  );

export default useValidationSchema;
