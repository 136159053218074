import { memo, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import type { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { StatusResourceTableCell } from "ui/organizms/Merchants/Merchants/shared/TableCells";
import useMerchantActions from "features/merchants/useMerchantActions";
import { useTypedSelector } from "hooks";
import { selectIsMerchantLoading } from "features/merchants/selectors";
import { BooleanSwitchValue } from "consts/general";
import { createOrUpdateMerchantSettings } from "features/merchants/api";
import type { MerchantResponse } from "features/merchants/types";
import { compareIds } from "util/support";
import { convertToBooleanSwitch } from "util/transformers";
import { toMerchantActiveStatus } from "features/merchants/helpers";

const MerchantStatus = (props: GridRenderCellParams) => {
    const [keyId, setKeyId] = useState(() => uuidv4());
    const { putMerchant } = useMerchantActions();

    const isProcessing = useTypedSelector(selectIsMerchantLoading);

    const handleProcess = async (isChecked: boolean, { row }: GridRenderCellParams) => {
        const [response] = putMerchant({
            coreId: row.coreId,
            isActive: convertToBooleanSwitch(isChecked)
        });

        Promise.allSettled([
            response,
            createOrUpdateMerchantSettings({
                coreId: row.merchantSettingCoreId,
                merchantId: row.coreId,
                activeStatus: toMerchantActiveStatus(isChecked)
            })
        ]);

        return response as Promise<MerchantResponse>;
    };

    const getChecked = ({ value }: GridRenderCellParams) => compareIds(value, BooleanSwitchValue.On);

    useEffect(() => {
        setKeyId(uuidv4());
    }, [props.value]);

    return (
        <StatusResourceTableCell
            {...props}
            isProcessing={isProcessing}
            getChecked={getChecked}
            handleProcess={handleProcess}
            key={keyId}
        />
    );
};

export default memo(MerchantStatus);
