import { memo } from "react";
import Box from "@mui/material/Box";
import type { IncrementTaxes as MerchantPayoutIncrementTaxes } from "features/merchantPayouts/types";
import DeleteFee from "./DeleteFee";

const Actions = (props: MerchantPayoutIncrementTaxes) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
        }}
    >
        <DeleteFee
            {...props}
        />
    </Box>
);

export default memo(Actions);
