import { memo } from "react";
import type { SettlementStatus } from "types";
import withChipStatus from "./withChipStatus";
import type { ChipStatusProps } from "./types";

export default memo(withChipStatus<SettlementStatus>(
    new Map<SettlementStatus, ChipStatusProps<SettlementStatus>>()
        .set('SUCCEEDED', {
            icon: 'CheckCircleOutline',
            color: 'success'
        })
        .set('FUNDSMISSING', {
            icon: 'SmsFailed',
            color: 'error'
        })
        .set('FUNDSRECEIVED', {
            icon: 'ThumbUp',
            color: 'secondary'
        })
        .set('FUNDSREFUNDED', {
            icon: 'LocalAtm',
            color: 'warning'
        })
        .set('FUNDSSENT', {
            icon: 'Outbox',
            color: 'info'
        })
));
