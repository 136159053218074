import { memo } from 'react';
import { SignIn as SignInLayout } from 'ui/layouts/SignIn';
import { SignIn } from "ui/forms/Auth";
import useLogin from './useLogin';

const Login = () => (
    <SignInLayout
        title='Sign in to Macropay'
    >
        <SignIn.Form
            onSubmit={useLogin()}
        />
    </SignInLayout>
);

export default memo(Login);
