import { PaymentMethod } from "features/paymentMethods/types";
import { useState } from "react";

class PaymentMethodFilterAdapter {
    public static of(paymentMethod: PaymentMethod) {
        return new this(paymentMethod);
    }

    private constructor(
        private readonly paymentMethod: PaymentMethod
    ) { }

    public matches(query: string) {
        if (!query) {
            return true;
        }

        return [
            this.enabledOnMerchants,
            this.name,
            this.PSP,
            this.tag,
            this.coreId
        ].some(criteria => [criteria]
            .flat()
            .map(criteria => String(criteria).toLocaleLowerCase())
            .some(criteria => criteria.includes(query.toLocaleLowerCase()))
        );
    }

    private get enabledOnMerchants() {
        return Object.values(this.paymentMethod.enabledOnMerchants);
    }

    private get PSP() {
        return this.paymentMethod.PSP;
    }

    private get name() {
        return this.paymentMethod.name;
    }

    private get tag() {
        return this.paymentMethod.tag;
    }

    private get coreId() {
        return this.paymentMethod.coreId;
    }
}

export default function useSearchCriteriaFilter() {
    const [searchCriteria, setSearchCriteria] = useState('');

    const filter = (paymentMethods: Array<PaymentMethod>) => (
        paymentMethods.filter(paymentMethod => (
            PaymentMethodFilterAdapter
                .of(paymentMethod)
                .matches(searchCriteria)
        ))
    );

    return {
        searchCriteria,
        setSearchCriteria,
        filter
    };
};
