import { memo } from "react";
import Box from '@mui/material/Box';
import { useFormik } from "formik";
import type { BootstrapableForm } from "types";
import type { ChangePayload, FilterChangePayload, FiltersChangeHandler } from "ui/widgets/Filters";
import { Autocomplete, TextField } from "ui/widgets/Filters";
import { Filters } from "consts/merchantPayouts";
import { getFormInputProps } from "util/forms";
import type { IncrementTaxesFormValues, IncrementTaxesBootstrapData } from "./types";


type Props = ReturnType<typeof useFormik<IncrementTaxesFormValues>> & Pick<
    BootstrapableForm<
        IncrementTaxesFormValues,
        IncrementTaxesBootstrapData
    >,
    | 'id'
    | 'bootstrapData'
    | 'isBootstrapDataLoading'
>;

const IncrementTax = ({
    id,
    handleSubmit,
    handleBlur,
    setFieldValue,
    values,
    bootstrapData,
    isBootstrapDataLoading,
    ...restFormProps
}: Props) => {
    const onChange: FiltersChangeHandler = ({ type, payload }: ChangePayload<string, FilterChangePayload>) => {
        setFieldValue(type, payload);
    };

    return (
        <Box
            component="form"
            autoComplete="off"
            id={id}
            onSubmit={handleSubmit}
            noValidate
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                flexWrap: 'wrap'
            }}
        >
            <Autocomplete
                options={bootstrapData.merchantId}
                loading={isBootstrapDataLoading}
                id={Filters.merchantId}
                name={Filters.merchantId}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.merchantId}
                label="Merchant *"
                TextFieldProps={{
                    ...getFormInputProps(Filters.merchantId, restFormProps),
                    required: true,
                    placeholder: 'Merchant'
                }}
            />
            <Autocomplete
                options={bootstrapData.taxName}
                loading={isBootstrapDataLoading}
                id={Filters.taxName}
                name={Filters.taxName}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.taxName}
                label="Tax Name *"
                TextFieldProps={{
                    ...getFormInputProps(Filters.taxName, restFormProps),
                    required: true,
                    placeholder: 'Tax Name'
                }}
            />
            <Autocomplete
                options={bootstrapData.tag}
                loading={isBootstrapDataLoading}
                id={Filters.tag}
                name={Filters.tag}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.tag}
                label='Payment Method TAG'
                TextFieldProps={{
                    ...getFormInputProps(Filters.tag, restFormProps),
                    required: true,
                    placeholder: 'Tag'
                }}
            />
            <Autocomplete
                options={bootstrapData.refColFrom}
                loading={isBootstrapDataLoading}
                id={Filters.refColFrom}
                name={Filters.refColFrom}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.refColFrom}
                label='Ref Column From *'
                TextFieldProps={{
                    ...getFormInputProps(Filters.refColFrom, restFormProps),
                    required: true,
                    placeholder: 'Ref Column From'
                }}
            />
            <TextField
                {...getFormInputProps(Filters.refColFromMinSum, restFormProps)}
                id={Filters.refColFromMinSum}
                name={Filters.refColFromMinSum}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.refColFromMinSum}
                label='Ref Column From Min Sum *'
                // placeholder='Ref Column From Min Sum'
                type='number'
                required
            />
            <TextField
                {...getFormInputProps(Filters.currentIncMultiplierForEachRefColumn, restFormProps)}
                id={Filters.currentIncMultiplierForEachRefColumn}
                name={Filters.currentIncMultiplierForEachRefColumn}
                onChange={onChange}
                onBlur={handleBlur}
                value={values.currentIncMultiplierForEachRefColumn}
                label='Multiplier *'
                // placeholder='Multiplier'
                type='number'
                required
            />
        </Box>
    );
};

export default memo(IncrementTax);
