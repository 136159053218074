import type { Option } from "types";
import { OptionableModel } from "util/option";
import type {
    IncrementTaxesFormValues,
    IncrementTaxesOptionableFormValues
} from "./types";
import { getInitialValues } from "./useForm";

export class IncrementTaxesModel extends OptionableModel<IncrementTaxesOptionableFormValues> implements IncrementTaxesFormValues {
    public static init(initialValues?: IncrementTaxesFormValues) {
        return new this(
            getInitialValues(initialValues)
        );
    }

    public merchantId!: Option;

    public taxName!: Option;

    public tag!: Option;

    public refColFrom!: Option;

    public constructor(initialValues?: IncrementTaxesFormValues) {
        super();

        Object.assign(this, initialValues);
    }
}

export default IncrementTaxesModel;
