import type { ImportCSVContextType, ImportCSVProviderProps } from "./Props";
import { memo, createContext, PropsWithChildren } from "react";
import ImportCSV from "./ImportCSV";
import useImportCSV from "./useImportCSV";

export const ImportCSVContext = createContext<ImportCSVContextType>({} as ImportCSVContextType);

const ImportCSVProvider = ({
    children,
    onImportBulkResult,
    importBulkRequestThunk
}: PropsWithChildren<ImportCSVProviderProps>) => {
    const { onImportCSV, ...restProps } = useImportCSV({
        onResult: onImportBulkResult,
        thunk: importBulkRequestThunk
    });

    return (
        <ImportCSVContext.Provider
            value={onImportCSV}
        >
            {children}
            <ImportCSV
                {...restProps}
            />
        </ImportCSVContext.Provider>
    );
};

export default memo(ImportCSVProvider);
