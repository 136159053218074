import { memo } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import BusinessLogicException from "exceptions/BusinessLogicException";
import { TriggerableDialog } from "ui/molecules/Dialog";
import {
    PaymentMethodDetail,
    PaymentMethodDetailProvider,
    usePaymentMethodContext
} from "../../PaymentMethod";

const AddPaymentMethod = () => {
    const {
        isProcessing,
        hasActiveChanges,
        updateOrCreate,
        reset
    } = usePaymentMethodContext();

    return (
        <TriggerableDialog
            TriggerComponent={({ onClick }) => (
                <LoadingButton
                    variant='contained'
                    color='secondary'
                    loading={isProcessing}
                    onClick={onClick}
                >
                    Add New Method
                </LoadingButton>
            )}
            DialogActionsComponent={({ onClose }) => (
                <>
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        onClick={async () => {
                            const result = await updateOrCreate();
                            if (result) {
                                onClose();
                            }
                        }}
                        loading={isProcessing}
                        disabled={!hasActiveChanges()}
                    >
                        Create Method
                    </LoadingButton>
                </>
            )}
            ModalProps={{
                onClose: (_, reason) => {
                    if (reason) {
                        throw new BusinessLogicException('Backdrop click is disabled', {});
                    }

                    reset();
                },
                DialogProps: {
                    sx: {
                        '& .MuiDialog-container .MuiPaper-root': {
                            width: 'auto'
                        }
                    }
                },
                titleSlot: 'Add New Method'
            }}
        >
            <PaymentMethodDetail />
        </TriggerableDialog>
    );
};

export default memo(() => (
    <PaymentMethodDetailProvider
        useCaseDiscriminationKey='create'
    >
        <AddPaymentMethod />
    </PaymentMethodDetailProvider>
));
