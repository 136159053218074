import type { MerchantsMethodsPivot } from "features/pivots/types";
import { ManagementApiResponseFactory } from "util/api";
import { compareIds } from "util/support";
import { stateFactory } from "./state";
import {
    MerchantPaymentMethodsActionType,
    type MerchantPaymentMethodsRepositoryState,
    type MerchantPaymentMethodsRepositoryAction
} from "./types";

export function reducer(state: MerchantPaymentMethodsRepositoryState, action: MerchantPaymentMethodsRepositoryAction): MerchantPaymentMethodsRepositoryState {
    switch (action.type) {
        case MerchantPaymentMethodsActionType.FetchMerchantsMethods: {
            return {
                ...state,
                merchantsMethods: {
                    ...state.merchantsMethods,
                    ...action.payload
                }
            };
        }

        case MerchantPaymentMethodsActionType.CreateMerchantMethod: {
            const total = state.merchantsMethods.data?.total || 0;
            const data = state.merchantsMethods.data?.data || Array.of<MerchantsMethodsPivot>();

            return {
                ...state,
                merchantsMethods: {
                    ...state.merchantsMethods,
                    data: ManagementApiResponseFactory.makePaginator({
                        ...state.merchantsMethods.data,
                        total: total + 1,
                        data: [
                            ...data,
                            action.payload
                        ]
                    })
                }
            };
        }

        case MerchantPaymentMethodsActionType.UpdateMerchantsMethod: {
            return {
                ...state,
                merchantsMethods: {
                    ...state.merchantsMethods,
                    data: ManagementApiResponseFactory.makePaginator({
                        ...state.merchantsMethods.data,
                        data: state.merchantsMethods.data!.data.map(merchantsMethod => {
                            if (compareIds(merchantsMethod.coreId, action.payload.coreId)) {
                                return action.payload;
                            }

                            return merchantsMethod;
                        })
                    })
                }
            };
        }

        case MerchantPaymentMethodsActionType.FetchMerchantsMethodsHistory: {
            return {
                ...state,
                merchantsMethodsHistory: {
                    ...state.merchantsMethodsHistory,
                    ...action.payload
                }
            };
        }

        case MerchantPaymentMethodsActionType.Reset: {
            return stateFactory();
        }

        default:
            return state;
    }
};
