import type { PropsWithChildren, ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type Props = {
    readonly title?: ReactNode;
};

const GroupLayout = ({ children, title }: PropsWithChildren<Props>) => (
    <Box
        sx={{
            display: 'grid',
            gap: 2
        }}
    >
        {Boolean(title) && (
            <Typography
                sx={{
                    fontWeight: 600
                }}
            >
                {title}
            </Typography>
        )}
        <Box
            sx={{
                display: 'grid',
                gap: 2
            }}
        >
            {children}
        </Box>
    </Box>
);

export default GroupLayout;
