import { useState } from "react";
import usePaymentMethodActions from "features/paymentMethods/useActions";
import { useFormableTab } from "ui/widgets/StatefulTabs";
import usePaymentMethodBasicInfo from "./usePaymentMethodBasicInfo";
import usePaymentMethodSpecInParams from "./usePaymentMethodSpecInParams";
import { PaymentMethodDetailTab, type PaymentMethodProviderProps } from "./types";
import useActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";

export default function usePaymentMethod({
    paymentMethod = {} as PaymentMethodProviderProps['paymentMethod'],
    ...restProps
}: PaymentMethodProviderProps) {
    const [isProcessing, setProcessingState] = useState(false);

    const { updateOrCreatePaymentMethod } = usePaymentMethodActions();
    const { showNotication } = useActions();

    const props = {
        ...restProps,
        paymentMethod
    };

    const state = {
        [PaymentMethodDetailTab.BasicInfo]: usePaymentMethodBasicInfo(props),
        [PaymentMethodDetailTab.SpecificInputParameters]: usePaymentMethodSpecInParams(props)
    };

    const formableTab = useFormableTab<PaymentMethodDetailTab>({
        state,
        entries: [
            ['1', PaymentMethodDetailTab.BasicInfo],
            ['2', PaymentMethodDetailTab.SpecificInputParameters]
        ]
    });

    const getPaymentMethod = () => paymentMethod!;

    const getAccordionProps = () => restProps;

    const selectBasicInfo = () =>
        state[PaymentMethodDetailTab.BasicInfo];

    const selectSpecificInputParameters = () =>
        state[PaymentMethodDetailTab.SpecificInputParameters];

    const hasActiveChanges = () => {
        const tabs = [
            selectBasicInfo,
            selectSpecificInputParameters
        ];

        if (tabs.every(tab => tab().isValid)) {
            return tabs.some(tab => tab().hasChanges());
        }

        return false;
    };

    const getChangedValues = () => ({
        ...selectBasicInfo()
            .getChangedValues(),
        ...selectSpecificInputParameters()
            .getChangedValues(),
        coreId: paymentMethod!.coreId
    });

    const updateOrCreate = async () => {
        try {
            setProcessingState(true);

            const [request] = updateOrCreatePaymentMethod(getChangedValues());
            const response = await request as PaymentMethodProviderProps['paymentMethod'];

            showNotication({
                severity: NotificationSeverity.Success,
                message: 'Payment method has been successfully updated'
            });

            return response;
        } finally {
            setProcessingState(false);
        }
    };

    const reset = () => [
        selectBasicInfo,
        selectSpecificInputParameters
    ].forEach(select => select().resetForm());

    return {
        ...formableTab,
        isProcessing,
        hasActiveChanges,
        getPaymentMethod,
        getAccordionProps,
        selectBasicInfo,
        getChangedValues,
        selectSpecificInputParameters,
        updateOrCreate,
        reset
    };
};
