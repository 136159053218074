import { memo } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { renderElement } from "lib/country-flag";
import type { CountrySelectorOptionProps } from "./types";

const CountrySelectorOption = ({ props, option, mode = 'iso3' }: CountrySelectorOptionProps) => {
    const { iso2, shortName } = option;

    return (
        <Stack
            {...props}
            component='li'
            divider={<Divider orientation='vertical' flexItem />}
            direction='row'
            spacing={1}
        >
            {renderElement(iso2, { ext: 'svg', src: '' })}
            <Box>{option[mode]}</Box>
            <Box>{shortName}</Box>
        </Stack>
    );
};

export default memo(CountrySelectorOption);
