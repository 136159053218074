import { createContext, useEffect, useState, type PropsWithChildren } from "react";
import type { Scalar } from "types";
import { useAccordion } from "ui/atoms/Accordion";
import type { AccordionContext as AccordionContextType } from "./types";

export const AccordionContext = createContext<AccordionContextType>({} as AccordionContextType);

const AccordionProvider = ({ children }: PropsWithChildren) => {
    const {
        expanded,
        reset: resetAccordion,
        handleChange: handleAccordionChange
    } = useAccordion();

    const [expandedPanel, setExpandedPanel] = useState<Scalar<string> | null>(null);

    const handleChange = (panel: Scalar<string>) => {
        const changeHandler = handleAccordionChange(panel);

        const onChange: typeof changeHandler = (...args) => {
            changeHandler(...args);

            const [, isExpanded] = args;

            setExpandedPanel(isExpanded ? panel : null);
        };

        return onChange;
    };

    const reset = () => {
        resetAccordion();
        setExpandedPanel(null);
    };

    useEffect(() => {
        if (!expanded) {
            setExpandedPanel(null);
        }
    }, [expanded]);

    return (
        <AccordionContext.Provider
            value={{
                expanded,
                expandedPanel,
                handleChange,
                reset
            }}
        >
            {children}
        </AccordionContext.Provider>
    );
};

export default AccordionProvider;
